var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inventoryIsLoading
    ? _c("div", { staticClass: "loader" }, [_c("i")])
    : _c(
        "div",
        { staticClass: "ad-zone-form" },
        [
          _c("v-select", {
            attrs: { options: _vm.sites, label: "name", placeholder: "Oldal" },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [_vm._v(" Nincsenek kiválasztható elemek. ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.model.site,
              callback: function ($$v) {
                _vm.$set(_vm.model, "site", $$v)
              },
              expression: "model.site",
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.name,
                expression: "model.name",
              },
            ],
            attrs: { type: "text", placeholder: "Zónanév" },
            domProps: { value: _vm.model.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "name", $event.target.value)
              },
            },
          }),
          _c("v-select", {
            attrs: {
              options: _vm.selectableZones,
              disabled: !_vm.model.isNew,
              placeholder: "Ad Manager zóna",
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [_vm._v(" Nincsenek elérhető új zónák ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.model.ad_manager_code,
              callback: function ($$v) {
                _vm.$set(_vm.model, "ad_manager_code", $$v)
              },
              expression: "model.ad_manager_code",
            },
          }),
          _vm.showSimilarSelect
            ? _c("v-select", {
                attrs: {
                  options: _vm.zonesWithForecast,
                  placeholder: "Hasonló zóna",
                },
                model: {
                  value: _vm.model.similar,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "similar", $$v)
                  },
                  expression: "model.similar",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "color-picker-and-checkboxes" },
            [
              _c("color-picker", {
                on: { input: _vm.onColorSelected },
                model: {
                  value: _vm.model.color,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "color", $$v)
                  },
                  expression: "model.color",
                },
              }),
              _c("div", { staticClass: "checkboxes" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.mobile,
                        expression: "model.mobile",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.model.mobile)
                        ? _vm._i(_vm.model.mobile, null) > -1
                        : _vm.model.mobile,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.model.mobile,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.model, "mobile", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "mobile",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "mobile", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" Mobil "),
                ]),
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.desktop,
                        expression: "model.desktop",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.model.desktop)
                        ? _vm._i(_vm.model.desktop, null) > -1
                        : _vm.model.desktop,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.model.desktop,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.model, "desktop", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "desktop",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "desktop", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" Desktop "),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "actions" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.isSubmitDisabled },
                on: { click: _vm.submit },
              },
              [
                _vm.isSaving
                  ? _c("pulse-loader", {
                      attrs: { color: "#ffffff", size: "6px" },
                    })
                  : [_vm._v(" " + _vm._s(_vm.submitButtonLabel) + " ")],
              ],
              2
            ),
            !_vm.model.isNew
              ? _c(
                  "button",
                  {
                    staticClass: "button clean",
                    on: { click: _vm.showDeleteConfirmAdZone },
                  },
                  [_vm._v(" Zóna törlése ")]
                )
              : _vm._e(),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }