<template>
  <div class="date-flag-form-component">
    <div class="form-input">
      <input type="text" placeholder="Megnevezés" v-model="model.name" />

      <v-select
        v-model="model.flag"
        :options="$dateFlags"
        :reduce="(item) => item.machine_name"
        :searchable="false"
        placeholder="Kiemelés típusa"
      />

      <color-picker v-model="model.color" class="color-picker" @input="onColorSelected" />

      <div class="calendar-title">
        <label>Időszak kiválasztása</label>
        <i class="icon calendar" />
      </div>
      <functional-calendar
        ref="calendar"
        v-model="calendarData"
        :configs="calendarConfigs"
        class="calendar"
      />
    </div>
    <div class="actions">
      <button
        class="button"
        :disabled="isSubmitDisabled"
        @click="submit"
      >
        <pulse-loader v-if="isSaving" color="#ffffff" size="6px" />
        <template v-else>
          {{ submitButtonLabel }}
        </template>
      </button>
      <button
        v-if="showDeleteButton"
        @click="showDeleteConfirm"
        class="button clean"
      >
        Időszak törlése
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { formatForCalendar } from '@/utils/dateFormatter';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import DateFlag from '@/entities/DateFlag';

import DateFlagService from '@/services/DateFlag';
import { Compact as ColorPicker } from 'vue-color';

export default {
  name: 'DateFlagForm',
  components: {
    ColorPicker,
    PulseLoader,
  },
  props: {
    entity: {
      type: DateFlag,
      required: true,
    },
  },
  data() {
    return {
      model: null,
      calendarData: {},
      calendarConfigs: {
        sundayStart: false,
        isDateRange: true,
        isDatePicker: false,
        markedDateRange: {
          start: null,
          end: null,
        },
        monthNames: this.$months,
        dayNames: this.$shortDays,
      },
      isSaving: false,
    };
  },
  watch: {
    'calendarData.dateRange.start'(val) {
      if (val) {
        const parsedVal = moment(val, 'DD/MM/YYYY');
        this.model.date_from = parsedVal.add(parsedVal.toDate().getTimezoneOffset() * -1, 'minutes').toDate();
      }
    },
    'calendarData.dateRange.end'(val) {
      if (val) {
        const parsedVal = moment(val, 'DD/MM/YYYY');
        this.model.date_to = parsedVal
          .add(parsedVal.toDate().getTimezoneOffset() * -1, 'minutes')
          .add(86400 - 1, 'seconds')
          .toDate();
      }
    },
  },
  created() {
    this.model = new DateFlag(this.entity);
  },
  mounted() {
    this.setCalendarInitDates();
  },
  computed: {
    isSubmitDisabled() {
      if (this.model.name.length == 0) {
        return true;
      }
      if (!this.model.date_from || !this.model.date_to) {
        return true;
      }
      if (!this.model.flag) {
        return true;
      }

      return false;
    },
    showDeleteButton() {
      return !this.model.isNew;
    },
    submitButtonLabel() {
      return this.model.isNew ? 'Időszak hozzáadása' : 'Időszak módosítása';
    },
  },
  methods: {
    setCalendarInitDates() {
      if (this.model.date_from) {
        this.$refs.calendar.ChooseDate(formatForCalendar(this.model.date_from));

        this.$nextTick(() => {
          this.calendarData.dateRange = this.calendarData.dateRange || {};
          this.calendarData.dateRange.start = formatForCalendar(
            this.model.date_from
          );

          if (this.model.date_to) {
            this.calendarData.dateRange.end = formatForCalendar(
              this.model.date_to
            );
          }
        });
      }
    },
    onColorSelected(value) {
      this.model.color = value.hex;
    },
    save(model) {
      let promise;

      if (model.isNew) {
        promise = DateFlagService.create(model);
      } else {
        promise = DateFlagService.update(model);
      }

      return promise.then((resp) => {
        const responsedEntity = new DateFlag(resp);
        return responsedEntity;
      });
    },
    submit() {
      if (this.isSaving) {
        return;
      }

      this.isSaving = true;
      this.save(this.model)
        .then(() => {
          if (this.model.isNew) {
            this.$router.replace('/flagged-dates');
          }
          else {
            this.$router.push('/flagged-dates');
          }
          this.$toast.success('Időszak mentése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
    showDeleteConfirm() {
      let content = 'Valóban törölni szeretnéd a felvitt időszakmegjelölést?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteEntity,
      });
    },
    deleteEntity() {
      return DateFlagService.delete(this.entity)
        .then(() => {
          this.$toast.success('Időszak törlése sikeresen megtörtént');
          this.$router.replace('/flagged-dates');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
