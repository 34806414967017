var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "campaign-form-view" },
    [
      _c("back-link", {
        attrs: { url: _vm.backLinkUrl, label: _vm.backLinkLabel },
      }),
      _c(
        "div",
        { staticClass: "page-title" },
        [
          _vm._v(" " + _vm._s(_vm.titleLabel) + " "),
          _c(
            "dropdown-menu",
            {
              attrs: {
                direction: "right",
                overlay: false,
                "is-open": _vm.dropdownIsOpen,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "button icon-only clean",
                  attrs: { slot: "trigger" },
                  on: {
                    click: function ($event) {
                      _vm.dropdownIsOpen = true
                    },
                  },
                  slot: "trigger",
                },
                [_c("i", { staticClass: "icon kebab-menu" })]
              ),
              _c(
                "ul",
                {
                  attrs: { slot: "body" },
                  on: { click: () => (_vm.dropdownIsOpen = false) },
                  slot: "body",
                },
                [
                  _c("li", { on: { click: _vm.createCopy } }, [
                    _vm._v("Másolat létrehozása"),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [_c("i")])
        : _vm._e(),
      _vm.entity != null
        ? _c("campaign-form", { attrs: { entity: _vm.entity } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }