<template>
  <div class="site-form-view">
    <back-link url="/sites" label="Vissza az oldalakhoz"></back-link>
    <div class="page-title">{{ pageTitle }}</div>
    <site-form v-if="site != null" :entity="site" />
  </div>
</template>
<script>
import Site from '@/entities/Site';
import SiteForm from '@/components/SiteForm';
import BackLink from '@/components/BackLink.vue';
export default {
  name: 'SiteFormView',
  components: {
    SiteForm,
    BackLink,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    site() {
      if (this.id === 'new') {
        return new Site();
      }
      return this.$store.getters['site/get'](this.id);
    },
    pageTitle() {
      if (this.id === 'new') {
        return 'Oldal hozzáadása';
      } else {
        return 'Oldal szerkesztése';
      }
    },
  },
  watch: {
    site: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.$router.replace('/sites');
        }
      },
    },
  },
};
</script>
