<template>
  <div class="date-flag-filter-component">
    <div class="label">Dátum</div>
    <input
      ref="dateInput"
      v-model="dateInputValue"
      type="text"
      class="date-input"
      :disabled="disabled"
      @focus="onFocusDateInput"
      @blur="onBlurDateInput"
    />

    <functional-calendar
      v-show="calendarIsVisible && !disabled"
      ref="calendar"
      v-model="calendarData"
      :configs="calendarConfigs"
      class="calendar"
      @click.native="onClickCalendar"
    >
      <template #footer>
        <button class="button button-ok">
          Rendben
        </button>
      </template>
    </functional-calendar>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

import { range as dateRangeFormatter, formatForCalendar } from '@/utils/dateFormatter';

export default {
  name: 'DateFlagFilter',
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.setCalendarInitDates();
  },
  data() {
    return {
      dateInputValue: '',
      calendarIsVisible: false,
      calendarHiddingTimer: null,
      calendarData: {},
      calendarConfigs: {
        sundayStart: false,
        isDateRange: true,
        isDatePicker: false,
        markedDateRange: {
          start: null,
          end: null,
        },
        monthNames: this.$months,
        dayNames: this.$shortDays,
      },
    };
  },
  watch: {
    calendarData: {
      deep: true,
      handler() {
        let from, to;
        if (this.calendarData.dateRange.start) {
          from = moment(this.calendarData.dateRange.start, 'DD/MM/YYYY').toDate();
        }
        if (this.calendarData.dateRange.end) {
          to = moment(this.calendarData.dateRange.end, 'DD/MM/YYYY').toDate();
        }

        this.$store.commit('dateFlag/setDateFrom', from);
        this.$store.commit('dateFlag/setDateTo', to);

        this.dateInputValue = dateRangeFormatter(from, to);
      },
    },
  },
  methods: {
    onChangeFilter: _.debounce(function () {
      this.onChangeFilterMethod();
    }, 600),
    onChangeFilterMethod() {
      let dateFrom = this.$store.getters['dateFlag/dateFrom'];
      let dateTo = this.$store.getters['dateFlag/dateTo'];

      if (this.calendarData.dateRange.start) {
        dateFrom = moment(this.calendarData.dateRange.start, 'DD/MM/YYYY').toDate();
      }
      if (this.calendarData.dateRange.end) {
        dateTo = moment(this.calendarData.dateRange.end, 'DD/MM/YYYY').toDate();
      }

      this.$emit('change', { dateFrom, dateTo });
    },
    setCalendarInitDates() {
      let from, to;

      if (!from && !to) {
        const now = new Date();
        const plusThreeMonth = new Date(now.getTime() + 86400 * 1000 * 365);

        from = now;
        to = plusThreeMonth;
      }

      this.calendarData.dateRange = this.calendarData.dateRange || {};
      this.calendarData.dateRange.start = formatForCalendar(from);
      this.calendarData.dateRange.end = formatForCalendar(to);
      this.calendarData = Object.assign({}, this.calendarData);

      this.onChangeFilterMethod();
    },
    onFocusDateInput() {
      if (this.calendarHiddingTimer !== null) {
        clearTimeout(this.calendarHiddingTimer);
        this.calendarHiddingTimer = null;
      }

      this.calendarIsVisible = true;
    },
    onBlurDateInput() {
      this.calendarHiddingTimer = setTimeout(() => {
        this.calendarIsVisible = false;
        this.onChangeFilter();
      }, 200);
    },
    onClickCalendar(e) {
      if (e.target.classList.contains('button-ok')) {
        this.calendarIsVisible = false;
        this.onChangeFilter();
        return;
      }

      this.$refs.dateInput.focus();
    },
  }
}
</script>
