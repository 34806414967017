<template>
  <div class="customer-form">
    <div class="customer-form-input">
      <div>
        <input type="text" placeholder="Cégnév" v-model="model.name" />
      </div>
      <div>
        <input
          type="text"
          placeholder="Kapcsolattartó név"
          v-model="model.contact_name"
        />
      </div>
      <div>
        <input
          type="email"
          placeholder="Kapcsolattartó email"
          v-model="model.contact_email"
        />
      </div>
      <div>
        <input
          type="text"
          placeholder="Kapcsolattartó telefonszám"
          v-model="model.contact_phone"
        />
      </div>
      <div>
        <v-select
          v-model="model.salesman"
          :options="users"
          label="name"
          placeholder="Értékesítő"
        />
      </div>
      <div>
        <v-select
          v-model="model.manager"
          :options="users"
          label="name"
          placeholder="Kampánymenedzser"
        />
      </div>
    </div>
    <div class="actions">
      <button
        class="button"
        :disabled="isSubmitDisabled"
        @click="submit"
      >
        <pulse-loader v-if="isSaving" color="#ffffff" size="6px" />
        <template v-else>
          {{ submitButtonLabel }}
        </template>
      </button>
      <button
        v-if="showDeleteButton"
        @click="showDeleteConfirmCustomer"
        class="button clean"
      >
        Ügyfél törlése
      </button>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Customer from '@/entities/Customer';

import { isValidEmail } from '@/utils/validators';

export default {
  name: 'CustomerForm',
  components: {
    PulseLoader,
  },
  props: {
    entity: {
      type: Customer,
      required: true,
    },
  },
  data: () => ({
    model: null,
    isSaving: false,
  }),
  created() {
    this.model = new Customer(this.entity);
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/user'];
    },
    users() {
      return this.$store.getters['auth/companyMembers'];
    },
    isSubmitDisabled() {
      if (this.model.name.length == 0) {
        return true;
      }
      return false;
    },
    showDeleteButton() {
      return this.model.id;
    },
    editSaveCustomer() {
      if (this.model.id == 0) {
        return false;
      }
      return true;
    },
    submitButtonLabel() {
      return this.model.isNew ? 'Ügyfél hozzáadása' : 'Ügyfél módosítása';
    },
  },
  methods: {
    showDeleteConfirmCustomer() {
      let content = 'Valóban törölni szeretnéd a felvitt partnert?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteCustomer,
      });
    },
    submit() {
      if (this.isSaving || this.isSubmitDisabledCustomer) {
        return;
      }

      if (!this.model.name) {
        return this.$toast.error('Név megadása kötelező');
      }
      if (this.model.contact_email && !isValidEmail(this.model.contact_email)) {
        return this.$toast.error('Helytelen email cím');
      }

      this.isSaving = true;

      this.$store
        .dispatch('customer/save', this.model)
        .then(() => {
          this.$router.replace('/customers');
          this.$toast.success('Ügyfél mentése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
    deleteCustomer() {
      return this.$store
        .dispatch('customer/delete', this.entity)
        .then(() => {
          this.$toast.success('Ügyfél törlése sikeresen megtörtént');
          this.$router.replace('/customers');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
