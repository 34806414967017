var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "customer-card" }, [
    _c("div", { staticClass: "customer-data" }, [
      _c("div", [_vm._v("Cégnév: " + _vm._s(_vm.entity.name))]),
      _c("div", [_vm._v("Név: " + _vm._s(_vm.entity.contact_name))]),
      _c("div", [_vm._v("E-mail: " + _vm._s(_vm.entity.contact_email))]),
      _c("div", [_vm._v("Telefonszám: " + _vm._s(_vm.entity.contact_phone))]),
    ]),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "router-link",
          {
            staticClass: "button",
            attrs: { to: "/customer/" + _vm.entity.id },
          },
          [_c("i", { staticClass: "icon edit white" }), _vm._v(" Módosít ")]
        ),
        _vm.isSuperUser
          ? _c(
              "button",
              {
                staticClass: "button bordered",
                on: { click: _vm.showDeleteConfirmCustomer },
              },
              [_c("i", { staticClass: "icon trash" }), _vm._v(" Törlés ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }