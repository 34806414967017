<template>
  <div class="campaign-form">
    <input
      type="text"
      placeholder="Kampány neve"
      v-model="model.name"
    />

    <div class="select-with-button">
      <v-select
        v-model="model.customer"
        :options="customers"
        label="name"
        placeholder="Ügyfél"
      >
        <template #no-options>
          Előbb fel kell venned legalább egy ügyfelet.
        </template>
      </v-select>
      <router-link class="button add-new-customer-button" to="/customer/new">
        <i class="icon add" />
        Új ügyfél
      </router-link>
    </div>

    <div class="select-with-button">
      <v-select
        multiple
        :options="adZones"
        label="name"
        v-model="model.ad_zones"
        placeholder="Felület"
      >
        <template #no-options>
          Nincsenek kiválasztható elemek.
        </template>
      </v-select>
      <button class="button" @click="showZoneGroupModal">
        <i class="icon add" />
        Zónaszett
      </button>
    </div>

    <v-select
      :options="campaignTypes"
      v-model="model.type"
      label="label"
      :reduce="(item) => item.value"
      placeholder="Értékesítés típusa"
    />

    <div class="lower">
      <div class="left">
        <div class="av-inputs">
          <!--desktop-->
          <div class="av-input-row">
            <label class="checkbox-wrapper">
              <input
                class="checkbox"
                type="checkbox"
                v-model="model.desktop"
              />
              Desktop
            </label>

            <input
              class="avs"
              type="number"
              placeholder="Desktop AV"
              v-model="model.desktop_av"
              :disabled="!model.desktop || disabledAvInputs"
            />
          </div>

          <!--mobil-->
          <div class="av-input-row">
            <label class="checkbox-wrapper">
              <input
                class="checkbox"
                type="checkbox"
                v-model="model.mobile"
              />
              Mobil
            </label>

            <input
              class="avs"
              type="number"
              placeholder="Mobil AV"
              v-model="model.mobile_av"
              :disabled="!model.mobile || disabledAvInputs"
            />
          </div>
        </div>

        <campaign-form-occupancy-block :original-entity="entity" :edited-entity="model" />

        <div class="checkboxes">
          <label class="checkbox-wrapper">
            <input type="checkbox" v-model="model.unfinalized" />Ceruzás
          </label>

          <label class="checkbox-wrapper">
            <input type="checkbox" v-model="model.flexible" />
            Rugalmas
          </label>
        </div>
        <textarea v-model="model.note" placeholder="Megjegyzés"></textarea>

        <color-picker v-model="model.color" @input="onColorSelected" />

        <label>Értékesítő</label>
        <v-select
          v-model="model.salesman"
          :options="users"
          label="name"
          placeholder="Értékesítő"
        />
        <label>Kampánymenedzser</label>
        <v-select
          v-model="model.manager"
          :options="users"
          label="name"
          placeholder="Kampánymenedzser"
        />
      </div>
      <div class="right">
        <div class="calendar-title">
          <label>Időszak kiválasztása</label>
          <i class="icon calendar" />
        </div>
        <functional-calendar
          ref="calendar"
          v-model="calendarData"
          :configs="calendarConfigs"
          class="calendar"
        ></functional-calendar>

        <div v-if="model.owner" class="owner">
          Kampányt felvitte: {{ model.owner.name }}
        </div>
        <label v-if="model.approval_needed && isSuperUser" class="checkbox-wrapper approve">
          <input v-model="model.approved" type="checkbox" /> Jóváhagyva
        </label>
      </div>
    </div>
    <div class="actions">
      <button class="button" @click="submit">
        <pulse-loader
          v-if="isSaving"
          :loading="isSaving"
          color="#ffffff"
          size="6px"
        />
        <template v-else> {{ submitButtonLabel }} </template>
      </button>

      <button
        v-if="showDeleteButton"
        @click="showDeleteConfirmCampaign"
        class="button clean delete-button"
      >
        Kampány törlése
      </button>
    </div>

    <modal name="select-zone-group-modal" class="without-background">
      <h2>Zónaszett hozzáadása kapányhoz</h2>
      <v-select
        v-model="adZoneGroup"
        :options="adZoneGroups"
        label="name"
        placeholder="Zónaszett"
      />

      <button
        class="button"
        :disabled="!adZoneGroup"
        @click="addAdZoneGroup"
      >
        Szett zónáinak hozzáadása
      </button>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import { formatForCalendar } from '@/utils/dateFormatter';

import CampaignFormOccupancyBlock from '@/components/CampaignFormOccupancyBlock';
import { Compact as ColorPicker } from 'vue-color';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import Campaign from '@/entities/Campaign.js';
import { CampaignType } from '@/entities/Campaign';

import CampaignService from '@/services/Campaign';

export default {
  name: 'CampaignForm',
  components: {
    CampaignFormOccupancyBlock,
    ColorPicker,
    PulseLoader,
  },
  props: {
    entity: {
      type: Campaign,
      required: true,
    },
  },
  created() {
    this.model = new Campaign(this.entity);
  },
  mounted() {
    this.setCalendarInitDates();
  },
  data() {
    return {
      model: null,
      calendarData: {},
      calendarConfigs: {
        sundayStart: false,
        isDateRange: true,
        isDatePicker: false,
        markedDateRange: {
          start: null,
          end: null,
        },
        monthNames: this.$months,
        dayNames: this.$shortDays,
      },
      isSaving: false,
      adZoneGroup: null,
    };
  },
  computed: {
    users() {
      return this.$store.getters['auth/companyMembers'];
    },
    customers() {
      return this.$store.getters['customer/list'];
    },
    adZones() {
      return this.$store.getters['adZone/list'];
    },
    adZoneGroups() {
      return this.$store.getters['adZoneGroup/list'];
    },
    showDeleteButton() {
      return this.model.id;
    },
    campaignTypes() {
      return [
        { value: CampaignType.TIME_BASED, label: 'Idő alapú' },
        { value: CampaignType.AV_BASED, label: 'AV alapú' },
      ];
    },
    submitButtonLabel() {
      return this.model.isNew ? 'Kampány hozzáadása' : 'Kampány módosítása';
    },
    disabledAvInputs() {
      return this.model.type === CampaignType.TIME_BASED;
    },
  },
  watch: {
    'calendarData.dateRange.start'(val) {
      if (val) {
        const parsedVal = moment(val, 'DD/MM/YYYY');
        this.model.date_from = parsedVal.add(parsedVal.toDate().getTimezoneOffset() * -1, 'minutes').toDate();
      }
    },
    'calendarData.dateRange.end'(val) {
      if (val) {
        const parsedVal = moment(val, 'DD/MM/YYYY');
        this.model.date_to = parsedVal
          .add(parsedVal.toDate().getTimezoneOffset() * -1, 'minutes')
          .add(86400 - 1, 'seconds')
          .toDate();
      }
    },
    'model.customer'(val, oldVal) {
      if (!val || typeof oldVal === 'undefined') {
        return;
      }

      if (
        !oldVal
        || !this.model.salesman
        || (oldVal.salesman && oldVal.salesman.id === this.model.salesman.id)
      ) {
        this.model.salesman = val.salesman;
      }

      if (
        !oldVal
        || !this.model.manager
        || (oldVal.manager && oldVal.manager.id === this.model.manager.id)
      ) {
        this.model.manager = val.manager;
      }
    },
    'model.type'(val) {
      if (val === CampaignType.TIME_BASED) {
        this.model.mobile_av = '';
        this.model.desktop_av = '';
      }
    },
    'model.mobile'(val) {
      if (!val) {
        this.model.mobile_av = '';
      }
    },
    'model.desktop'(val) {
      if (!val) {
        this.model.desktop_av = '';
      }
    },
  },
  methods: {
    showDeleteConfirmCampaign() {
      let content = 'Valóban törölni szeretnéd a felvitt kampányt?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteCampaign,
      });
    },
    onColorSelected(value) {
      this.model.color = value.hex;
    },
    submit() {
      if (this.isSaving) {
        return;
      }

      let errorMessage = null;

      this.$toast.clear();
      if (!this.model.name || this.model.name.trim().length === 0) {
        errorMessage = 'Kampány név mező kitöltése kötelező';
      }
      else if (!this.model.customer) {
        errorMessage = 'Ügyfél mező kitöltése kötelező';
      }
      else if (this.model.ad_zones.length === 0) {
        errorMessage = 'Felület mező kitöltése kötelező!';
      }
      else if (!this.model.type) {
        errorMessage = 'Értékesítés típusa mező kitöltése kötelező!';
      }
      else if (!this.model.mobile && !this.model.desktop) {
        errorMessage = 'Legalább az egyiket ki kell választani Desktop és Mobil közül!';
      }

      if (errorMessage !== null) {
        this.$toast.error(errorMessage);
        return;
      }

      this.isSaving = true;
      this.save(this.model)
        .then(() => {
          let redirectTo = '/campaigns';
          if (this.isSuperUser && !this.model.approved) {
            redirectTo = '/campaigns/pending';
          }

          if (this.model.isNew) {
            this.$router.replace(redirectTo);
          }
          else {
            this.$router.push(redirectTo);
          }
          this.$toast.success('Kampány mentése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
    setCalendarInitDates() {
      if (this.model.date_from) {
        this.$refs.calendar.ChooseDate(formatForCalendar(this.model.date_from));

        this.$nextTick(() => {
          this.calendarData.dateRange = this.calendarData.dateRange || {};
          this.calendarData.dateRange.start = formatForCalendar(
            this.model.date_from
          );

          if (this.model.date_to) {
            this.calendarData.dateRange.end = formatForCalendar(
              this.model.date_to
            );
          }
        });
      }
    },
    save(model) {
      let promise;

      if (model.isNew) {
        promise = CampaignService.create(model);
      } else {
        promise = CampaignService.update(model);
      }

      return promise.then((resp) => {
        const responsedEntity = new Campaign(resp);
        return responsedEntity;
      });
    },
    deleteCampaign() {
      return CampaignService.delete(this.entity)
        .then(() => {
          this.$toast.success('Kampány törlése sikeresen megtörtént');
          let redirectTo = '/campaigns';
          if (this.isSuperUser && !this.entity.approved) {
            redirectTo = '/campaigns/pending';
          }
          this.$router.replace(redirectTo);
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    showZoneGroupModal() {
      this.$modal.show('select-zone-group-modal');
    },
    hineZoneGroupModal() {
      this.$modal.hide('select-zone-group-modal');
    },
    addAdZoneGroup() {
      this.hineZoneGroupModal();

      this.adZoneGroup.ad_zones.forEach(adZone => {
        for (let item of this.model.ad_zones) {
          if (item.id === adZone.id) {
            return;
          }
        }
        this.model.ad_zones.push(adZone);
      });

      this.adZoneGroup = null;
    },
  },
};
</script>
