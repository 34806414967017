import moment from 'moment';
import Api from '@/services/Api';

export default {
  getList({ dateFrom, dateTo }) {

    const params = {};
    if (dateFrom) {
      params['date_from'] = moment(dateFrom).format('YYYY-MM-DD');
    }

    if (dateTo) {
      params['date_to'] = moment(dateTo).format('YYYY-MM-DD');
    }

    const path = '/date-flags';
    const query = new URLSearchParams(params).toString();

    return Api()
      .get(path + '?' + query)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  get(id) {
    return Api()
      .get('/date-flag/' + id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  create(entity) {
    const data = {
      name: entity.name,
      date_from: entity.date_from,
      date_to: entity.date_to,
      flag: entity.flag,
      color: entity.color,
    };

    return Api()
      .post('date-flag/new', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  update(entity) {
    const data = {
      name: entity.name,
      date_from: entity.date_from,
      date_to: entity.date_to,
      flag: entity.flag,
      color: entity.color,
    };

    return Api()
      .put('/date-flag/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  delete(entity) {
    return Api()
      .delete('/date-flag/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
