<template>
  <div class="sidebar">
    <transition name="fade">
      <div
        class="sidebar-backdrop"
        @click="closeSidebarPanel"
        v-if="isPanelOpen"
      />
    </transition>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <div class="top">
          <div class="header">
            <div class="logo">
              <i class="icon logo"></i>
            </div>
            <div class="close">
              <i class="icon close" @click="closeSidebarPanel"></i>
            </div>
          </div>
          <div class="options" @click="closeSidebarPanel">
            <router-link v-if="!isOutsiderUser" to="/"> Áttekintés </router-link>

            <router-link to="/ad-zone-occupancies"> Foglaltsági naptár </router-link>

            <router-link to="/campaigns"> Kampányok </router-link>

            <router-link v-if="!isOutsiderUser" to="/campaigns/past"> Múltbéli kampányok </router-link>

            <router-link v-if="isSuperUser" to="/campaigns/pending"> Elfogadásra váró kampányok </router-link>

            <router-link to="/customers"> Ügyfelek </router-link>

            <router-link to="/sites" v-if="isSuperUser"> Oldalak </router-link>

            <router-link to="/ad-zones" v-if="isSuperUser"> Zónák </router-link>

            <router-link to="/ad-zone-groups" v-if="isSuperUser"> Zónaszettek </router-link>

            <router-link to="/users" v-if="isSuperUser"> Csapattagok </router-link>

            <router-link to="/flagged-dates" v-if="isSuperUser"> Kiemelt időszakok </router-link>
          </div>
        </div>
        <div class="footer">
          <div class="sk-logo"><i class="icon sk-logo" /></div>
          <div class="text">
            HKR | Hirdetés kelező rendszer {{ year }} I<br />
            All right reserved |
            <a href="" target="”_blank”"> Adatvédelmi szabályzat</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    isPanelOpen() {
      return this.$store.getters['app/isNavOpen'];
    },
  },
  methods: {
    closeSidebarPanel() {
      this.$store.commit('app/toggleNav');
    },
  },
};
</script>
