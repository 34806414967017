var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("modal", { attrs: { name: _vm.name }, on: { closed: _vm.onClosed } }, [
        _c("div", [_c("i", { staticClass: "icon warning-message" })]),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            { staticClass: "button bordered", on: { click: _vm.actionCancel } },
            [_vm._v(" " + _vm._s(_vm.labelCancel) + " ")]
          ),
          _c(
            "button",
            { staticClass: "button", on: { click: _vm.actionConfirm } },
            [
              _vm.progress
                ? _c("pulse-loader", {
                    attrs: { color: "#ffffff", size: "6px" },
                  })
                : [_vm._v(" " + _vm._s(_vm.labelConfirm) + " ")],
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }