import User from '@/entities/User';

export default class Customer {

  id = null;
  salesman = null;
  manager = null;
  name = '';
  contact_name = '';
  contact_email = '';
  contact_phone = '';
  created_at = new Date();

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if ((key === 'salesman' || key === 'manager') && !!value) {
        this[key] = new User(value);
      }
      if (key === 'created_at' && !!value) {
        this[key] = new Date(value);
      }
      else {
        this[key] = value;
      }
    }
  }

}
