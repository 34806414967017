import Api from '@/services/Api';

export default {
  getList(params) {
    let path = '/sites';
    if (params) {
      path += '?' + new URLSearchParams(params).toString();
    }

    return Api()
      .get(path)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  create(entity) {
    const data = {
      name: entity.name,
      url: entity.url,
    };

    return Api()
      .post('/site/new', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    const data = {
      name: entity.name,
      url: entity.url,
    };

    return Api()
      .put('/site/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/site/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
