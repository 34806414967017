<template>
  <div>
    <modal :name="name" @closed="onClosed">
      <div>
        <i class="icon warning-message"></i>
      </div>
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
      <div class="buttons">
        <button class="button bordered" @click="actionCancel">
          {{ labelCancel }}
        </button>
        <button class="button" @click="actionConfirm">
          <pulse-loader v-if="progress" color="#ffffff" size="6px" />
          <template v-else>
            {{ labelConfirm }}
          </template>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'ConfirmationModal',
  components: { PulseLoader },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Figyelem!'
    },
    content: {
      type: String,
      required: true
    },
    labelCancel: {
      type: String,
      required: false,
      default: 'Mégsem'
    },
    labelConfirm: {
      type: String,
      required: false,
      default: 'Megerősít'
    },
    confirmOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    confirm: {
      type: [Function, null],
      required: false,
      default: null
    },
    cancel: {
      type: [Function, null],
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default() {
        return (Math.random() + 1).toString(36).substring(7);
      },
    },
  },
  data() {
    return {
      parentTop: null,
      progress: false
    };
  },
  beforeMount() {
    this.setupContainer();
  },
  mounted() {
    this.parentTop.insertAdjacentElement('afterbegin', this.$el);
    this.$modal.show(this.name);
  },
  methods: {
    setupContainer() {
      this.parentTop = document.querySelector('.confirmation-modal');
      if (this.parentTop) {
        return;
      }

      this.parentTop = document.createElement('div');
      this.parentTop.className = 'confirmation-modal';

      const container = document.body;
      container.appendChild(this.parentTop);
    },
    close() {
      this.$modal.hide(this.name);
    },
    onClosed() {
      this.$destroy();
      this.parentTop.innerHTML = '';
    },
    actionCancel() {
      if (this.progress) {
        return;
      }

      if (typeof this.cancel === 'function') {
        this.cancel();
      }
      this.close();
    },
    actionConfirm() {
      if (this.progress) {
        return;
      }

      if (typeof this.confirm !== 'function') {
        this.close();
        return;
      }

      const resp = this.confirm();
      if (resp instanceof Promise) {
        this.progress = true;
        resp
          .then(() => {
            this.close();
          })
          .finally(() => this.progress = false);
      }
      else {
        this.close();
      }
    }
  }
}
</script>
