<template>
  <card class="site-card">
    <div class="details">
      {{ entity.name }}<br/>
      <a v-if="entity.url" :href="entity.url" target="_blank">
        {{ entity.url }}
      </a>
    </div>
    <div class="actions">
      <router-link class="button" :to="'/site/' + entity.id">
        <i class="icon edit white"></i>
        Módosít
      </router-link>
      <button class="button bordered" @click="showDeleteConfirmSite">
        <i class="icon trash"></i>
        Törlés
      </button>
    </div>
  </card>
</template>
<script>
import Card from '@/components/Card';

import Site from '@/entities/Site';

export default {
  name: 'SiteCard',
  components: {
    Card,
  },
  props: {
    entity: {
      type: Site,
      required: true,
    },
  },
  methods: {
    showDeleteConfirmSite() {
      let content = 'Valóban törölni szeretnéd a felvitt oldalt?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteSite,
      });
    },
    deleteSite() {
      return this.$store
        .dispatch('site/delete', this.entity)
        .then(() => {
          this.$toast.success('Oldal törlése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
