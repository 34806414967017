var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "date", on: { click: _vm.toggle } }, [
          _c("div", {
            staticClass: "color-overlay",
            style: "background: " + _vm.color,
          }),
          _c("div", { staticClass: "content" }, [
            _c("i", { staticClass: "icon dropdown-arrow" }),
            _c("div", { staticClass: "label" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.dateString)),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.dayName) + " "),
              ]),
              _vm.hasOverfilledZone
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.overfilledTooltipContent,
                        expression: "overfilledTooltipContent",
                      },
                    ],
                    staticClass: "icon warning",
                  })
                : _vm._e(),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info-items" }, [
          _c("div", { staticClass: "info" }, [
            _vm._v(" " + _vm._s(_vm.formattedOccupancedAvNumber) + " AV "),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("i", { staticClass: "icon fire" }),
            _vm._v(
              " Foglalt felületek: " + _vm._s(_vm.occupancedRateString) + " "
            ),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("i", { staticClass: "icon info" }),
            _vm._v(" Szabad felületek: " + _vm._s(_vm.freeRateString) + " "),
          ]),
        ]),
      ]),
      _c("transition-expand", [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "zone-rows" },
              [
                _vm.isLoading
                  ? _c("div", { staticClass: "loader" }, [_c("i")])
                  : _vm.filteredZones.length !== 0
                  ? [
                      !_vm.isOutsiderUser
                        ? _c("span", {
                            staticClass: "toggle-all",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.isAllZoneOpen
                                  ? "Összes becsukása"
                                  : "Összes kinyitása"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.isAllZoneOpen = !_vm.isAllZoneOpen
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.filteredZones, function (item, key) {
                        return _c("ad-zone-occupancy-zone-row", {
                          key: "zone-row-" + key,
                          attrs: {
                            zone: item.zone,
                            "forecasted-mobile": item.forecast.mobile,
                            "forecasted-desktop": item.forecast.desktop,
                            "occupied-mobile": item.occupied.mobile,
                            "occupied-desktop": item.occupied.desktop,
                            flexible: item.flexible,
                            unfinalized: item.unfinalized,
                            campaigns: item.campaigns,
                            open: _vm.isAllZoneOpen,
                          },
                        })
                      }),
                    ]
                  : _c("div", { staticClass: "empty-text" }, [
                      _vm._v(
                        " Erre a napra nem találtunk a beállított feltételeknek megfelelő foglalást. "
                      ),
                    ]),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }