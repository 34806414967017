var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { staticClass: "ad-zone-card", attrs: { color: _vm.entity.color } },
    [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.entity.name))]),
      _c("div", [
        _vm._v(" Oldal: " + _vm._s(_vm.entity.site.name)),
        _c("br"),
        _vm._v(" Eszköz: " + _vm._s(_vm.devices) + " "),
      ]),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "router-link",
            {
              staticClass: "button",
              attrs: { to: "/ad-zone/" + _vm.entity.id },
            },
            [_c("i", { staticClass: "icon edit white" }), _vm._v(" Módosít ")]
          ),
          _c(
            "button",
            {
              staticClass: "button bordered",
              on: { click: _vm.showDeleteConfirmAdZone },
            },
            [_c("i", { staticClass: "icon trash" }), _vm._v(" Törlés ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }