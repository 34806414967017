var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-page forgotten-password-view" },
    [
      _c("auth-logo"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Jelszó helyreállítása")]),
        _c("div", { staticClass: "credentials" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            attrs: { type: "email", placeholder: "E-mail cím megadása" },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _c(
            "button",
            { staticClass: "button", on: { click: _vm.submit } },
            [
              _vm.isLoading
                ? _c("pulse-loader", {
                    attrs: {
                      loading: _vm.isLoading,
                      color: "#ffffff",
                      size: "6px",
                    },
                  })
                : [_vm._v(" Jelszó helyreállítása ")],
            ],
            2
          ),
        ]),
      ]),
      _c("div", { staticClass: "footer" }, [
        _vm._m(0),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " AdbookR | Hirdetés kelező rendszer " +
              _vm._s(_vm.year) +
              " | All right reserved | "
          ),
          _c("a", { attrs: { href: "", target: "_blank" } }, [
            _vm._v("Adatvédelmi szabályzat"),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("i", { staticClass: "icon sk-logo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }