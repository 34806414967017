var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ad-zone-group-form" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.name,
            expression: "model.name",
          },
        ],
        attrs: { type: "text", placeholder: "Zónaszett neve" },
        domProps: { value: _vm.model.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.model, "name", $event.target.value)
          },
        },
      }),
      _c("v-select", {
        attrs: {
          multiple: "",
          options: _vm.adZones,
          label: "name",
          placeholder: "Zónák",
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function () {
              return [_vm._v(" Nincsenek kiválasztható elemek. ")]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.model.ad_zones,
          callback: function ($$v) {
            _vm.$set(_vm.model, "ad_zones", $$v)
          },
          expression: "model.ad_zones",
        },
      }),
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.submit } },
          [
            _vm.isSaving
              ? _c("pulse-loader", {
                  attrs: {
                    loading: _vm.isSaving,
                    color: "#ffffff",
                    size: "6px",
                  },
                })
              : [_vm._v(" " + _vm._s(_vm.submitButtonLabel) + " ")],
          ],
          2
        ),
        _vm.showDeleteButton
          ? _c(
              "button",
              {
                staticClass: "button clean delete-button",
                on: { click: _vm.showDeleteConfirmAdZoneGroup },
              },
              [_vm._v(" Zónaszett törlése ")]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }