var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-flag-filter-component" },
    [
      _c("div", { staticClass: "label" }, [_vm._v("Dátum")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.dateInputValue,
            expression: "dateInputValue",
          },
        ],
        ref: "dateInput",
        staticClass: "date-input",
        attrs: { type: "text", disabled: _vm.disabled },
        domProps: { value: _vm.dateInputValue },
        on: {
          focus: _vm.onFocusDateInput,
          blur: _vm.onBlurDateInput,
          input: function ($event) {
            if ($event.target.composing) return
            _vm.dateInputValue = $event.target.value
          },
        },
      }),
      _c("functional-calendar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.calendarIsVisible && !_vm.disabled,
            expression: "calendarIsVisible && !disabled",
          },
        ],
        ref: "calendar",
        staticClass: "calendar",
        attrs: { configs: _vm.calendarConfigs },
        nativeOn: {
          click: function ($event) {
            return _vm.onClickCalendar.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c("button", { staticClass: "button button-ok" }, [
                  _vm._v(" Rendben "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.calendarData,
          callback: function ($$v) {
            _vm.calendarData = $$v
          },
          expression: "calendarData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }