import AdZone from '@/entities/AdZone';
import Customer from '@/entities/Customer';
import User from '@/entities/User';

export const CampaignType = { TIME_BASED: 'time_based', AV_BASED: 'av_based' };

export default class Campaign {
  id = null;
  owner = null;
  salesman = null;
  manager = null;
  type = '';
  name = '';
  color = '#000000';
  customer = null;
  ad_zones = [];
  mobile = false;
  desktop = false;
  mobile_av = null;
  desktop_av = null;
  flexible = false;
  unfinalized = false;
  note = '';
  price = null;
  currency = null;
  date_from = null;
  date_to = null;
  approval_needed = false;
  approved = false;
  created_at = new Date();

  constructor(rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if ((key === 'salesman' || key === 'manager' || key === 'owner') && !!value) {
        this[key] = new User(value);
      }
      else if (key === 'customer' && !!value) {
        this[key] = new Customer(value);
      }
      else if (key === 'ad_zones') {
        this[key] = [];

        for (let item of value) {
          const entity = new AdZone(item);
          this[key].push(entity);
        }
      }
      else if (
        (key.indexOf('date') !== -1 || key === 'created_at') &&
        !!value
      ) {
        this[key] = new Date(value);
      }
      else {
        this[key] = value;
      }
    }
  }
}
