import AdZone from '@/entities/AdZone';
import Site from '@/entities/Site';
import User from '@/entities/User';

export default class Company {

  id = null;
  name = '';
  members = [];
  sites = [];
  ad_zones = [];
  created_at = new Date();

  constructor (rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }


      if (key === 'members') {
        for (let item of value) {
          const entity = new User(item);
          this[key].push(entity);
        }
      }
      else if (key === 'sites') {
        for (let item of value) {
          const entity = new Site(item);
          this[key].push(entity);
        }
      }
      else if (key === 'ad_zones') {
        this[key] = [];

        for (let item of value) {
          const entity = new AdZone(item);
          this[key].push(entity);
        }
      }
      else if (key === 'created_at' && !!value) {
        this[key] = new Date(value);
      }
      else {
        this[key] = value;
      }
    }
  }

}
