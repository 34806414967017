<template>
  <div class="user-profile-form">
    <div class="page-title">Adatlapod</div>
    <div>
      <input type="text" placeholder="Név" :value="entity.name" readonly />
      <input type="email" placeholder="Email" :value="entity.email" readonly />
      <input
        type="text"
        placeholder="Cégnév"
        :value="entity.company.name"
        readonly
      />
      <input
        type="text"
        placeholder="Felhasználói jogosultság"
        :value="entity.roles[0].name"
        readonly
      />
      <div class="title">Jelszó módosítás</div>
      <input type="password" placeholder="Régi jelszó" v-model="oldPassword" />
      <input type="password" placeholder="Új jelszó" v-model="newPassword" />
      <input
        type="password"
        placeholder="Új jelszó megerősítése"
        v-model="newPasswordAgain"
      />
    </div>
    <div class="actions">
      <button class="button" @click="validate">
        <pulse-loader v-if="isLoading" color="#ffffff" size="6px" />
        <template v-else> Módosít </template>
      </button>
    </div>
  </div>
</template>
<script>
import User from '@/entities/User';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'UserProfileForm',
  components: {
    PulseLoader,
  },

  props: {
    entity: {
      type: User,
      required: false,
    },
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordAgain: null,
      isLoading: false,
    };
  },
  methods: {
    validate(e) {
      this.$toast.clear();
      if (this.newPassword !== this.newPasswordAgain) {
        this.$toast.error('Az új jelszavak nem egyeznek!');
        return;
      } else if (
        (this.newPassword || this.newPasswordAgain) &&
        !this.oldPassword
      ) {
        this.$toast.error('Meg kell adni a régi jelszót!');
        return;
      } else if (
        !this.newPassword &&
        !this.newPassword &&
        !this.newPasswordAgain
      ) {
        return;
      } else if (
        this.oldPassword &&
        !this.newPassword &&
        !this.newPasswordAgain
      ) {
        return;
      }

      e.preventDefault();

      this.isLoading = true;
      const data = {
        id: this.entity.id,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      this.$store
        .dispatch('auth/updatePassword', data)
        .then(() => {
          this.$toast.success('Jelszó módosítása sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
