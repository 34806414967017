var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "burger",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.toggle.apply(null, arguments)
        },
      },
    },
    [
      _c("i", { staticClass: "icon burger" }),
      _c("div", { staticClass: "text" }, [_vm._v("Menü")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }