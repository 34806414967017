var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { class: _vm.wrapperClasses, attrs: { color: _vm.entity.color } },
    [
      _c("div", { staticClass: "texture" }),
      _c("div", { staticClass: "campaign-data" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v(
            " [" +
              _vm._s(_vm.entity.customer.name) +
              "] " +
              _vm._s(_vm.entity.name) +
              " "
          ),
        ]),
        _c("div", [
          _c("b", [_vm._v("Státusz:")]),
          _vm._v(" " + _vm._s(_vm.statusLabel) + " "),
        ]),
        _c("div", [
          _c("b", [_vm._v("Típus:")]),
          _vm._v(" " + _vm._s(_vm.camapignTypes) + " "),
          _vm.isAvBased
            ? _c("small", [_vm._v(" (" + _vm._s(_vm.avSum) + ") ")])
            : _vm._e(),
        ]),
        _c("div", [
          _c("b", [_vm._v("Dátum:")]),
          _vm._v(" " + _vm._s(_vm.campaignDate) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.infoTooltipContent,
                  expression: "infoTooltipContent",
                },
              ],
              staticClass: "button clean icon-only",
            },
            [_c("i", { staticClass: "icon info" })]
          ),
          _vm.entity.note
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.noteTooltipContent,
                      expression: "noteTooltipContent",
                    },
                  ],
                  staticClass: "button clean icon-only",
                },
                [_c("i", { staticClass: "icon comment" })]
              )
            : _vm._e(),
          _c("div", { staticClass: "spacer" }),
          _c(
            "router-link",
            {
              staticClass: "button clean icon-only",
              attrs: { to: "/campaign/" + _vm.entity.id },
            },
            [_c("i", { staticClass: "icon edit" })]
          ),
          _c(
            "button",
            {
              staticClass: "button clean icon-only",
              on: { click: _vm.showDeleteConfirmCampaign },
            },
            [_c("i", { staticClass: "icon trash" })]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }