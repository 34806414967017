var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sites-view" }, [
    _vm.entities.length == 0
      ? _c("div", { staticClass: "emptyList" }, [
          _vm._v(" Nincsenek megjeleníthető elemek "),
        ])
      : _c(
          "div",
          { staticClass: "list-grid" },
          _vm._l(_vm.entities, function (entity) {
            return _c("site-card", {
              key: entity.id,
              attrs: { entity: entity },
            })
          }),
          1
        ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "router-link",
          { staticClass: "button", attrs: { to: "/site/new" } },
          [_vm._v(" Oldal hozzáadása ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }