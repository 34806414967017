<template>
  <div class="entity-search">
    <input
      v-model="term"
      type="text"
      placeholder="Keresés..."
      @input="onChange"
    >
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'EntitySearch',
  data: () => ({
    term: null,
  }),
  methods: {
    onChange: _.debounce(function () {
      this.$emit('change', this.term);
    }, 500),
  }
};
</script>
