<template>
  <div :class="wrapperClasses">
    <div class="main">
      <div
        class="week"
        @click="toggle"
        :style="weekStyle"
        v-tooltip="weekTooltip"
      >
        <i class="icon dropdown-arrow" />
        <div class="label">
          <div class="week-number">
            {{ weekNumber }}. hét
          </div>
          <i
            v-if="hasOverfilledZone"
            v-tooltip="overfilledTooltipContent"
            class="icon warning"
          />
          <div class="date">{{ date }}</div>
        </div>
      </div>
      <div v-tooltip="tooltipContent" class="bar" @click="toggle">
        <div
          v-if="finalizedBarWidth > 0"
          class="finalized"
          :style="'width: ' + finalizedBarWidth + '%'"
        />
        <div
          v-if="flexibleBarWidth > 0"
          class="flexible"
          :style="'width: ' + flexibleBarWidth + '%'"
        />
        <div
          v-if="flexibleUnfinalizedBarWidth > 0"
          class="flexible-unfinalized"
          :style="'width: ' + flexibleUnfinalizedBarWidth + '%'"
        />
        <div
          v-if="unfinalizedBarWidth > 0"
          class="unfinalized"
          :style="'width: ' + unfinalizedBarWidth + '%'"
        />

        <div
          v-if="colorOverlayBarWidth > 0"
          class="color-overlay"
          :style="'width: ' + colorOverlayBarWidth + '%; background: ' + color + ';'"
        />
      </div>
    </div>

    <transition-expand>
      <div v-if="isOpen" :class="tableSubItemWrapperClasses">
        <template v-if="viewType === 'daily'">
          <ad-zone-occupancy-day-row
            v-for="(item, key) in days"
            :key="'day-row-' + key"
            :date="new Date(item.date)"
            :rates="item.rates"
            :total-forecasted="item.total_forecasted"
            :total-occupanced="item.total_occupied"
            :flexible="item.flexible"
            :unfinalized="item.unfinalized"
            :has-overfilled-mobile-zone="item.overfilled.mobile"
            :has-overfilled-desktop-zone="item.overfilled.desktop"
          />
        </template>
        <template v-if="viewType === 'weekly'">
          <div v-if="isLoading" class="loader">
            <i />
          </div>
          <template v-else-if="zones.length !== 0">
            <span
              v-if="!isOutsiderUser"
              class="toggle-all"
              @click="isAllZoneOpen = !isAllZoneOpen"
              v-html="isAllZoneOpen ? 'Összes becsukása' : 'Összes kinyitása'"
            />

            <ad-zone-occupancy-zone-row
              v-for="(item, key) in zones"
              :key="'zone-row-' + key"
              :zone="item.zone"
              :forecasted-mobile="item.forecast.mobile"
              :forecasted-desktop="item.forecast.desktop"
              :occupied-mobile="item.occupied.mobile"
              :occupied-desktop="item.occupied.desktop"
              :flexible="item.flexible"
              :unfinalized="item.unfinalized"
              :campaigns="item.campaigns"
              :open="isAllZoneOpen"
            />
          </template>
          <div v-else class="empty-text">
            Erre a hétre nem találtunk a beállított feltételeknek megfelelő foglalást.
          </div>
        </template>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import OccupancyColorMixin from '@/mixins/occupancyColor';
import StatMixin from '@/mixins/stat';

import AdZoneOccupancyDayRow from '@/components/AdZoneOccupancyDayRow';
import AdZoneOccupancyZoneRow from '@/components/AdZoneOccupancyZoneRow';
import TransitionExpand from '@/components/TransitionExpand';

import AdZone from '@/entities/AdZone';
import DateFlag from '@/entities/DateFlag';
import StatService from '@/services/Stat';

import { range as dateRangeFormatter } from '@/utils/dateFormatter';

export default {
  name: 'AdZoneOccupancyWeekRow',
  mixins: [ OccupancyColorMixin, StatMixin ],
  components: {
    AdZoneOccupancyDayRow,
    AdZoneOccupancyZoneRow,
    TransitionExpand,
  },
  props: {
    weekNumber: {
      type: Number,
      required: true,
    },
    rates: {
      type: Object,
      required: true
    },
    totalForecasted: {
      type: Number,
      required: false,
      default: 0,
    },
    totalOccupanced: {
      type: Number,
      required: false,
      default: 0,
    },
    flexible: {
      type: Number,
      required: false,
      default: 0,
    },
    flexibleUnfinalized: {
      type: Number,
      required: false,
      default: 0,
    },
    unfinalized: {
      type: Number,
      required: false,
      default: 0,
    },
    days: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasOverfilledMobileZone: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hasOverfilledDesktopZone: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dateFlag: {
      type: DateFlag,
      required: false,
    },
  },
  data: () => ({
    isOpen: false,
    isLoading: false,
    isAllZoneOpen: false,
    zones: [],
  }),
  computed: {
    wrapperClasses() {
      return {
        'ad-zone-occupancy-week-row-component': true,
        'is-open': this.isOpen,
      };
    },
    tableSubItemWrapperClasses() {
      return {
        'sub-rows': true,
        'zone-rows': this.viewType === 'weekly',
      };
    },
    colorOverlayBarWidth() {
      return this.totalOccupancedRate * 100;
    },
    finalizedBarWidth() {
      return this.rates.finalized * 100;
    },
    flexibleBarWidth() {
      return this.rates.flexible * 100;
    },
    flexibleUnfinalizedBarWidth() {
      return this.rates.flexible_unfinalized * 100;
    },
    unfinalizedBarWidth() {
      return this.rates.unfinalized * 100;
    },
    tooltipContent() {
      const numberFormatter = new Intl.NumberFormat('hu-HU');

      let lines = [];
      lines.push('Előrejelzett AV: ' + numberFormatter.format(this.totalForecasted));

      if (this.totalOccupanced) {
        lines.push('');
        lines.push('Lefoglalt mennyiség: ' + numberFormatter.format(this.totalOccupanced));
        if (this.flexibleUnfinalized > 0) {
          lines.push('Ebből rugalmas és ceruzás egyben: ' + numberFormatter.format(this.flexibleUnfinalized));
        }
        if (this.flexible > 0) {
          lines.push('Ebből csak rugalmas: ' + numberFormatter.format(this.flexible));
        }
        if (this.unfinalized > 0) {
          lines.push('Ebből csak ceruzás: ' + numberFormatter.format(this.unfinalized));
        }
      }

      return lines.join('<br/>');
    },
    hasOverfilledZone() {
      return this.hasOverfilledMobileZone || this.hasOverfilledDesktopZone;
    },
    overfilledTooltipContent() {
      let parts = [];
      if (this.hasOverfilledMobileZone) {
        parts.push('mobil');
      }
      if (this.hasOverfilledDesktopZone) {
        parts.push('desktop');
      }

      return 'Túlfoglalt ' + parts.join(' & ');
    },
    date() {
      const dateFrom = new Date(this.days[0].date);
      const dateTo = new Date(this.days[this.days.length - 1].date);

      return dateRangeFormatter(dateFrom, dateTo);
    },
    flagKeyValues() {
      let result = {};
      this.$dateFlags.forEach(item => {
        result[item.machine_name] = item.label;
      })

      return result;
    },
    weekTooltip() {
      if (!this.dateFlag) {
        return null;
      }

      return '<b>' + this.dateFlag.name + '</b><br/>'
        + this.flagKeyValues[this.dateFlag.flag] + '<br/>'
        + dateRangeFormatter(this.dateFlag.date_from, this.dateFlag.date_to);
    },
    weekStyle() {
      if (!this.dateFlag) {
        return null;
      }

      return {
        'background-color': this.dateFlag.color  + '70',
      };
    },
  },
  watch: {
    isOpen(val, oldVal) {
      if (this.viewType !== 'weekly') {
        return;
      }

      if (val && val !== oldVal && this.zones.length === 0) {
        this.loadZones();
      }
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    loadZones() {
      this.isLoading = true;

      StatService.getZones({
        dateFrom: this.days[0].date,
        dateTo: this.days[this.days.length - 1].date,
        term: this.termFilter,
        adZones: this.zoneFilter,
        sites: this.siteFilter,
        salesman: this.salesmanFilter,
        managers: this.managerFilter,
        customers: this.customerFilter,
        mobile: this.mobileFilter,
        desktop: this.desktopFilter,
        ignoreOnlyApprovedFilter: !this.onlyApprovedCampaigns,
      })
        .then(resp => {
          this.zones = resp.zones;

          for (let key in this.zones) {
            this.zones[key]['zone'] = new AdZone(this.zones[key]['zone']);
          }
        })
        .catch(e => {
          console.error(e);
          this.$toast.error('Hiba történt az adatok betöltése közben');
        })
        .finally(() => this.isLoading = false);
    },
  }
}
</script>
