import Vue from 'vue';
import Vuex from 'vuex';

import adZone from '@/store/modules/adZone';
import adZoneGroup from '@/store/modules/adZoneGroup';
import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import customer from '@/store/modules/customer';
import dateFlag from '@/store/modules/dateFlag';
import occupancyStat from '@/store/modules/occupancyStat';
import site from '@/store/modules/site';
import invites from '@/store/modules/invites';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    adZone,
    adZoneGroup,
    app,
    auth,
    customer,
    dateFlag,
    occupancyStat,
    site,
    invites,
  },
  plugins: [],
});
