var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "back-link" },
    [
      _c("router-link", { staticClass: "back", attrs: { to: this.url } }, [
        _c("i", { staticClass: "icon left-arrow" }),
        _c("div", { staticClass: "label" }, [_vm._v(_vm._s(this.label))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }