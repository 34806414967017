<template>
  <div class="ad-zone-occupancies-view">
    <occupancy-filter :disabled="isLoading" @change="loadNewWeeklyData" />

    <div class="table">
      <div class="view-type-switch">
        <button
          class="button"
          :class="{ bordered: viewType !== 'weekly' }"
          :disabled="isLoading"
          @click="() => switchViewType('weekly')"
        >
          Heti nézet
        </button>
        <button
          class="button"
          :class="{ bordered: viewType !== 'daily' }"
          :disabled="isLoading"
          @click="() => switchViewType('daily')"
        >
          Napi bontás
        </button>
      </div>
      <div class="info">
        <div class="item">
          <i class="icon fire" />
          Foglalt felületek
          <span class="value">{{ occupiedRate }}</span>
        </div>
        <div class="item">
          <i class="icon info" />
          Szabad felületek
          <span class="value">{{ freeRate }}</span>
        </div>
        <div class="spacer" />
        <button v-if="!isOutsiderUser" class="button" @click="exportStat">
          <pulse-loader
            v-if="isExporting"
            :loading="isExporting"
            color="#ffffff"
            size="4px"
          />
          <template v-else>
            Export
          </template>
        </button>
      </div>

      <div v-if="isLoading" class="loader">
        <i />
      </div>
      <template v-else>
        <ad-zone-occupancy-week-row
          v-for="(item, key) in weeklyData"
          :key="'occupancy-row-' + key"
          :week-number="item.week_number"
          :days="item.days"
          :rates="item.rates"
          :total-forecasted="item.total_forecasted"
          :total-occupanced="item.total_occupied"
          :flexible="item.flexible"
          :flexible-unfinalized="item.flexible_unfinalized"
          :unfinalized="item.unfinalized"
          :has-overfilled-mobile-zone="item.overfilled.mobile"
          :has-overfilled-desktop-zone="item.overfilled.desktop"
          :date-flag="dateFlagByWeek(item)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import StatMixin from '@/mixins/stat';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import AdZoneOccupancyWeekRow from '@/components/AdZoneOccupancyWeekRow';
import OccupancyFilter from '@/components/OccupancyFilter';

import DateFlag from '@/entities/DateFlag';

import DateFlagService from '@/services/DateFlag';
import StatService from '@/services/Stat';

export default {
  name: 'AdZoneOccupanciesView',
  components: {
    AdZoneOccupancyWeekRow,
    PulseLoader,
    OccupancyFilter,
  },
  mixins: [ StatMixin ],
  mounted() {
    if (this.weeklyData.length === 0) {
      this.$nextTick(() => this.loadNewWeeklyData());
    }
  },
  data: () => ({
    isLoading: false,
    isExporting: false,
    dateFlags: [],
    weeklyData: [],
    occupiedRateRawValue: 0,
    freeRateRawValue: 0,
  }),
  computed: {
    occupiedRate() {
      if (this.isLoading || this.occupiedRateRawValue === 0) {
        return '-';
      }

      return this.occupiedRateRawValue.toFixed(0) + '%';
    },
    freeRate() {
      if (this.isLoading || this.freeRateRawValue <= 0) {
        return '-';
      }

      return this.freeRateRawValue.toFixed(0) + '%';
    },
  },
  watch: {
    viewType(val, oldVal) {
      if (val !== oldVal) {
        this.loadNewWeeklyData();
      }
    },
  },
  methods: {
    loadNewWeeklyData() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.weeklyData = [];
      this.dateFlags = [];

      DateFlagService.getList({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
        .then(resp => {
          for (let dateFlag of resp) {
            if (dateFlag instanceof DateFlag) {
              this.dateFlags.push(dateFlag);
            } else {
              this.dateFlags.push(new DateFlag(dateFlag));
            }
          }
        })
        .catch(e => {
          console.error(e);
          this.$toast.error('Hiba történt az kiemelt időszakok betöltése közben');
        });

      StatService.getWeekly({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        term: this.termFilter,
        adZones: this.adZoneFilter,
        sites: this.siteFilter,
        salesman: this.salesmanFilter,
        manager: this.managerFilter,
        customers: this.customerFilter,
        mobile: this.mobileFilter,
        desktop: this.desktopFilter,
        ignoreOnlyApprovedFilter: !this.onlyApprovedCampaigns,
      })
        .then(resp => {
          this.occupiedRateRawValue = resp.occupied_rate;
          this.freeRateRawValue = resp.free_rate;
          this.weeklyData = resp.weeks;
        })
        .catch(e => {
          console.error(e);
          this.$toast.error('Hiba történt az adatok betöltése közben');
        })
        .finally(() => this.isLoading = false);
    },
    exportStat() {
      this.isExporting = true;
      StatService.export({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        term: this.termFilter,
        adZones: this.adZoneFilter,
        sites: this.siteFilter,
        salesman: this.salesmanFilter,
        manager: this.managerFilter,
        customers: this.customerFilter,
        mobile: this.mobileFilter,
        desktop: this.desktopFilter,
        ignoreOnlyApprovedFilter: !this.onlyApprovedCampaigns,
      })
        .then((resp) => {
          const downloadFileContent = 'data:text/csv;charset=utf-8,' + resp.csv_content;
          const encodedUri = encodeURI(downloadFileContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'adbookr_export_' + Date.now() + '.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error('Hiba történt az adatok exportálása során.')
        })
        .finally(() => this.isExporting = false);
    },
    switchViewType(type) {
      this.$store.dispatch('occupancyStat/switchViewType', type);
    },
    dateFlagByWeek(week) {
      for (let dateFlag of this.dateFlags) {
        for (let day of week.days) {
          const currentDayDate = new Date(day.date);
          if (currentDayDate >= dateFlag.date_from && currentDayDate <= dateFlag.date_to) {
            return dateFlag;
          }
        }
      }

      return null;
    },
  },
}
</script>
