var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-invite-form-view" },
    [
      _c("back-link", {
        attrs: { url: "/users", label: "Vissza a csapattagokhoz" },
      }),
      _c("div", { staticClass: "page-title" }, [
        _vm._v("Csapattag hozzáadása"),
      ]),
      _c("user-invite-form", { attrs: { entity: _vm.user } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }