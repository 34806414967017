import AuthService from '@/services/Auth';

const state = {
  isLoading: true,
  isNavOpen: false,
  userRoleList: [],
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isNavOpen(state) {
    return state.isNavOpen;
  },
  userRoles(state) {
    return state.userRoleList;
  },
};

const actions = {
  fetch() {
    AuthService.fetchRoles().then((result) => {
      for (let role of result) {
        state.userRoleList.push(role);
      }
    });
  },
};

const mutations = {
  isLoading(state, value) {
    state.isLoading = value;
  },
  toggleNav(state) {
    state.isNavOpen = !state.isNavOpen;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
