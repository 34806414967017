var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer-form-view" },
    [
      _c("back-link", {
        attrs: { url: "/customers", label: "Vissza az ügyfelekhez" },
      }),
      _c("div", { staticClass: "page-title" }, [_vm._v(_vm._s(_vm.pageTitle))]),
      _vm.customer != null
        ? _c("customer-form", { attrs: { entity: _vm.customer } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }