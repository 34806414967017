<template>
  <div class="user-invite-form-view">
    <back-link url="/users" label="Vissza a csapattagokhoz"></back-link>
    <div class="page-title">Csapattag hozzáadása</div>
    <user-invite-form :entity="user" />
  </div>
</template>
<script>
import UserInviteForm from '@/components/UserInviteForm.vue';
import User from '@/entities/User';
import BackLink from '@/components/BackLink.vue';

export default {
  name: 'UserInviteFormView',
  components: {
    UserInviteForm,
    BackLink,
  },
  created() {
    this.user = new User();
  },
};
</script>
