var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-profile-form" }, [
    _c("div", { staticClass: "page-title" }, [_vm._v("Adatlapod")]),
    _c("div", [
      _c("input", {
        attrs: { type: "text", placeholder: "Név", readonly: "" },
        domProps: { value: _vm.entity.name },
      }),
      _c("input", {
        attrs: { type: "email", placeholder: "Email", readonly: "" },
        domProps: { value: _vm.entity.email },
      }),
      _c("input", {
        attrs: { type: "text", placeholder: "Cégnév", readonly: "" },
        domProps: { value: _vm.entity.company.name },
      }),
      _c("input", {
        attrs: {
          type: "text",
          placeholder: "Felhasználói jogosultság",
          readonly: "",
        },
        domProps: { value: _vm.entity.roles[0].name },
      }),
      _c("div", { staticClass: "title" }, [_vm._v("Jelszó módosítás")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.oldPassword,
            expression: "oldPassword",
          },
        ],
        attrs: { type: "password", placeholder: "Régi jelszó" },
        domProps: { value: _vm.oldPassword },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.oldPassword = $event.target.value
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newPassword,
            expression: "newPassword",
          },
        ],
        attrs: { type: "password", placeholder: "Új jelszó" },
        domProps: { value: _vm.newPassword },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.newPassword = $event.target.value
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newPasswordAgain,
            expression: "newPasswordAgain",
          },
        ],
        attrs: { type: "password", placeholder: "Új jelszó megerősítése" },
        domProps: { value: _vm.newPasswordAgain },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.newPasswordAgain = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        { staticClass: "button", on: { click: _vm.validate } },
        [
          _vm.isLoading
            ? _c("pulse-loader", { attrs: { color: "#ffffff", size: "6px" } })
            : [_vm._v(" Módosít ")],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }