const state = {
  dateFrom: null,
  dateTo: null,
};

const getters = {
  dateFrom(state) {
    return state.dateFrom;
  },

  dateTo(state) {
    return state.dateTo;
  },
};

const actions = {
};

const mutations = {
  setDateFrom(state, value) {
    state.dateFrom = value;
  },

  setDateTo(state, value) {
    state.dateTo = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
