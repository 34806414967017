const state = {
  type: 'weekly',
  dateFrom: null,
  dateTo: null,
  termFilter: '',
  adZoneFilter: [],
  siteFilter: [],
  salesmanFilter: [],
  managerFilter: [],
  customerFilter: [],
  mobileFilter: true,
  desktopFilter: true,
  onlyApprovedCampaigns: true,
};

const getters = {
  type() {
    return state.type;
  },

  dateFrom(state) {
    return state.dateFrom;
  },

  dateTo(state) {
    return state.dateTo;
  },

  termFilter(state) {
    return state.termFilter;
  },

  adZoneFilter(state) {
    return state.adZoneFilter;
  },

  siteFilter(state) {
    return state.siteFilter;
  },

  salesmanFilter(state) {
    return state.salesmanFilter;
  },

  managerFilter(state) {
    return state.managerFilter;
  },

  customerFilter(state) {
    return state.customerFilter;
  },

  mobileFilter(state) {
    return state.mobileFilter;
  },

  desktopFilter(state) {
    return state.desktopFilter;
  },

  onlyApprovedCampaigns(state) {
    return state.onlyApprovedCampaigns;
  },
};

const actions = {
  switchViewType(store, type) {
    if (type === 'daily' || type === 'weekly') {
      store.commit('switchViewType', type);
    }
  }
};

const mutations = {
  switchViewType(state, value) {
    state.type = value;
  },

  setDateFrom(state, value) {
    state.dateFrom = value;
  },

  setDateTo(state, value) {
    state.dateTo = value;
  },

  setTermFilter(state, values) {
    state.termFilter = values;
  },

  setAdZoneFilter(state, values) {
    state.adZoneFilter = values;
  },

  setSiteFilter(state, values) {
    state.siteFilter = values;
  },

  setSalesmanFilter(state, values) {
    state.salesmanFilter = values;
  },

  setManagerFilter(state, values) {
    state.managerFilter = values;
  },

  setCustomerFilter(state, values) {
    state.customerFilter = values;
  },

  setMobileFilter(state, value) {
    state.mobileFilter = value;
  },

  setDesktopFilter(state, value) {
    state.desktopFilter = value;
  },

  setOnlyApprovedCampaigns(state, value) {
    state.onlyApprovedCampaigns = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
