<template>
  <div class="campaign-form-view">
    <back-link :url="backLinkUrl" :label="backLinkLabel"></back-link>
    <div class="page-title">
      {{ titleLabel }}

      <dropdown-menu direction="right" :overlay="false" :is-open="dropdownIsOpen">
        <div class="button icon-only clean" slot="trigger" @click="dropdownIsOpen = true">
          <i class="icon kebab-menu" />
        </div>
        <ul slot="body" @click="() => dropdownIsOpen = false">
          <li @click="createCopy">Másolat létrehozása</li>
        </ul>
      </dropdown-menu>
    </div>
    <div v-if="isLoading" class="loader">
      <i />
    </div>
    <campaign-form v-if="entity != null" :entity="entity" />
  </div>
</template>
<script>
import CampaignForm from '@/components/CampaignForm.vue';
import Campaign from '@/entities/Campaign';
import BackLink from '@/components/BackLink.vue';
import CampaignService from '@/services/Campaign';

export default {
  name: 'CampaignFormView',
  components: {
    CampaignForm,
    BackLink,
  },
  data: () => ({
    dropdownIsOpen: false,
    isLoading: false,
    entity: null,
    titleLabel: null,
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    backLinkLabel() {
      if (!this.entity) {
        return '';
      }

      if (this.entity && !this.entity.approved) {
        return 'Vissza a függőben lévő kampányokhoz';
      }

      return 'Vissza a kampányokhoz';
    },
    backLinkUrl() {
      if (this.entity && !this.entity.approved) {
        return '/campaigns/pending';
      }

      return '/campaigns';
    }
  },
  created() {
    this.label();
    this.loadEntity();
  },
  methods: {
    label() {
      if (this.id === 'new') {
        this.titleLabel = 'Kampány hozzáadása';
      } else {
        this.titleLabel = 'Kampány szerkesztése';
      }
    },
    loadEntity() {
      if (this.id === 'new') {
        this.entity = new Campaign();
        return;
      }

      this.isLoading = true;

      CampaignService.getCampaign(this.id)
        .then((resp) => {
          this.entity = new Campaign(resp);
        })
        .catch(() => {
          this.$router.replace('/campaigns');
          this.$toast.error(
            'Váratlan hiba történt a kampány betöltése közben.'
          );
        })
        .finally(() => (this.isLoading = false));
    },
    createCopy() {
      if (this.entity.isNew) {
        return;
      }

      this.$store.commit('app/isLoading', true);

      const model = new Campaign(this.entity);
      model.name += ' - Másolat';

      CampaignService.create(model)
        .then((resp) => {
          const responsedEntity = new Campaign(resp);
          this.$router.replace('/campaign/' + responsedEntity.id);
          this.$toast.success('Másolat sikeresen létrehozva.');
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error('Váratlan hiba történt a másolat készítése közben.');
        })
        .finally(() => this.$store.commit('app/isLoading', false));
    },
  },
};
</script>
