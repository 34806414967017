import Api from '@/services/Api';

export default {
  getList(params) {
    let path = '/customers';
    if (params) {
      path += '?' + new URLSearchParams(params).toString();
    }

    return Api()
      .get(path)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  create(entity) {
    const data = {
      salesman_id: entity.salesman ? entity.salesman.id : null,
      manager_id: entity.manager ? entity.manager.id : null,
      name: entity.name,
      contact_name: entity.contact_name,
      contact_email: entity.contact_email,
      contact_phone: entity.contact_phone,
    };

    return Api()
      .post('/customer/new', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    const data = {
      salesman_id: entity.salesman ? entity.salesman.id : null,
      manager_id: entity.manager ? entity.manager.id : null,
      name: entity.name,
      contact_name: entity.contact_name,
      contact_email: entity.contact_email,
      contact_phone: entity.contact_phone,
    };

    return Api()
      .put('/customer/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/customer/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
