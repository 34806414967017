<template>
  <div class="home-view">
    <div class="top-buttons">
      <button
        class="button"
        :class="{ bordered: onlyMine }"
        :disabled="isLoading"
        @click="() => onlyMine = false"
      >
        Mutasd mindet
      </button>
      <button
        class="button"
        :class="{ bordered: !onlyMine }"
        :disabled="isLoading"
        @click="() => onlyMine = true"
      >
        Mutasd az én foglalásaimat
      </button>
    </div>

    <div class="campaigns">
      <div class="title">Eheti kampányok</div>
      <div v-if="isLoading" class="loader">
        <i />
      </div>
      <div v-else-if="thisWeek.length == 0" class="emptylist">
        Nincsenek megjeleníthető elemek
      </div>
      <div v-else class="list-grid">
        <campaign-card
          v-for="entity in thisWeek"
          :key="entity.id"
          :entity="entity"
        />
      </div>
    </div>

    <div class="campaigns">
      <div class="title">Jövőheti kampányok</div>
      <div v-if="isLoading" class="loader">
        <i />
      </div>
      <div v-else-if="nextWeek.length == 0" class="emptylist">
        Nincsenek megjeleníthető elemek
      </div>
      <div v-else class="list-grid">
        <campaign-card
          v-for="entity in nextWeek"
          :key="entity.id"
          :entity="entity"
        />
      </div>
    </div>

    <div class="campaigns">
      <div class="title">Utána</div>
      <div v-if="isLoading" class="loader">
        <i />
      </div>
      <div v-else-if="next2Week.length == 0" class="emptylist">
        Nincsenek megjeleníthető elemek
      </div>
      <div v-else class="list-grid">
        <campaign-card
          v-for="entity in next2Week"
          :key="entity.id"
          :entity="entity"
        />
      </div>
    </div>

    <router-link to="/campaigns" class="button all-campaign">
      Mutasd az összes kampányt
    </router-link>
  </div>
</template>
<script>
import moment from 'moment';

import CampaignCard from '@/components/CampaignCard';
import CampaignService from '@/services/Campaign';
import Campaign from '@/entities/Campaign';

export default {
  name: 'HomeView',
  components: {
    CampaignCard,
  },
  data: () => ({
    isLoading: false,
    onlyMine: false,
    thisWeek: [],
    nextWeek: [],
  }),
  computed: {
    userId() {
      return this.$store.getters['auth/user'].id;
    },
    mondayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 1;

      const monday = new Date(today.setDate(first));

      return monday;
    },
    sundayOfCurrentWeek() {
      const date = new Date(this.mondayOfCurrentWeek);
      date.setDate(date.getDate() + 6);
      return date;
    },
    mondayOfNextWeek() {
      const date = new Date(this.mondayOfCurrentWeek);
      date.setDate(date.getDate() + 7);
      return date;
    },
    sundayOfNextWeek() {
      const date = new Date(this.mondayOfNextWeek);
      date.setDate(date.getDate() + 6);
      return date;
    },
    mondayOfNext2Week() {
      const date = new Date(this.mondayOfNextWeek);
      date.setDate(date.getDate() + 7);
      return date;
    },
    sundayOfNext2Week() {
      const date = new Date(this.sundayOfNextWeek);
      date.setDate(date.getDate() + 6);
      return date;
    },
  },
  watch: {
    onlyMine() {
      this.loadCampaigns();
    },
  },
  created() {
    this.loadCampaigns();
  },
  methods: {
    loadCampaigns() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const thisWeekPromise = CampaignService.getList({
        approved: 1,
        after: moment(this.mondayOfCurrentWeek).format('YYYY-MM-DD'),
        before: moment(this.sundayOfCurrentWeek).format('YYYY-MM-DD'),
        user_id: this.onlyMine ? this.userId : '',
        order_by: 'date_from',
        sort: 'asc',
        limit: 999,
      });
      const nextWeekPromise = CampaignService.getList({
        approved: 1,
        after: moment(this.mondayOfNextWeek).format('YYYY-MM-DD'),
        before: moment(this.sundayOfNextWeek).format('YYYY-MM-DD'),
        user_id: this.onlyMine ? this.userId : '',
        order_by: 'date_from',
        sort: 'asc',
        limit: 999,
      });
      const next2WeekPromise = CampaignService.getList({
        approved: 1,
        after: moment(this.mondayOfNext2Week).format('YYYY-MM-DD'),
        before: moment(this.sundayOfNext2Week).format('YYYY-MM-DD'),
        user_id: this.onlyMine ? this.userId : '',
        order_by: 'date_from',
        sort: 'asc',
        limit: 999,
      });

      const promises = [
        thisWeekPromise,
        nextWeekPromise,
        next2WeekPromise,
      ];

      this.thisWeek = [];
      this.nextWeek = [];
      this.next2Week = [];

      Promise.all(promises)
        .then(resp => {
          resp.forEach((items, promiseKey) => {
            let campaigns = [];

            for (let campaign of items) {
              if (!(campaign instanceof Campaign)) {
                campaign = new Campaign(campaign);

                campaigns.push(campaign);
              }
            }

            campaigns.sort((a, b) => {
              if (!a.ad_zones || a.ad_zones.length === 0) {
                return 1;
              }
              if (!b.ad_zones || b.ad_zones.length === 0) {
                return -1;
              }

              return a.ad_zones[0].name.localeCompare(b.ad_zones[0].name);
            });

            campaigns.forEach(campaign => {
              switch (promiseKey) {
                case 0:
                  this.thisWeek.push(campaign);
                  break;

                case 1:
                  this.nextWeek.push(campaign);
                  break;

                case 2:
                  this.next2Week.push(campaign);
                  break;
              }
            });
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
