<template>
  <card :class="wrapperClasses" :color="entity.color">
    <div class="texture" />

    <div class="campaign-data">
      <div class="name">
        [{{ entity.customer.name }}] {{ entity.name }}
      </div>
      <div>
        <b>Státusz:</b> {{ statusLabel }}
      </div>
      <div>
        <b>Típus:</b> {{ camapignTypes }}
        <small v-if="isAvBased">
          ({{ avSum }})
        </small>
      </div>
      <div>
        <b>Dátum:</b> {{ campaignDate }}
      </div>
    </div>
    <div class="actions">
      <button class="button clean icon-only" v-tooltip="infoTooltipContent">
        <i class="icon info"></i>
      </button>
      <button
        v-if="entity.note"
        v-tooltip="noteTooltipContent"
        class="button clean icon-only"
      >
        <i class="icon comment"></i>
      </button>

      <div class="spacer" />

      <router-link
        class="button clean icon-only"
        :to="'/campaign/' + entity.id"
      >
        <i class="icon edit"></i>
      </router-link>
      <button class="button clean icon-only" @click="showDeleteConfirmCampaign">
        <i class="icon trash"></i>
      </button>
    </div>
  </card>
</template>

<script>
import Card from '@/components/Card';
import Campaign, { CampaignType } from '@/entities/Campaign';
import CampaignService from '@/services/Campaign';
import { range as dateRangeFormatter } from '@/utils/dateFormatter';

export default {
  name: 'CampaignCard',
  components: {
    Card,
  },
  props: {
    entity: {
      type: Campaign,
      required: true,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'campaign-card': true,
        'unfinalized': this.entity.unfinalized && !this.entity.flexible,
        'flexible': this.entity.flexible && !this.entity.unfinalized,
        'flexible-unfinalized': this.entity.flexible && this.entity.unfinalized,
      };
    },
    campaignDate() {
      const dateFrom = new Date(this.entity.date_from);
      const dateTo = new Date(this.entity.date_to);

      return dateRangeFormatter(dateFrom, dateTo);
    },
    isAvBased() {
      return this.entity.type === CampaignType.AV_BASED;
    },
    avSum() {
      let sum = 0;
      if (this.entity.mobile_av) {
        sum += this.entity.mobile_av;
      }
      if (this.entity.desktop_av) {
        sum += this.entity.desktop_av;
      }

      const numberFormatter = new Intl.NumberFormat('hu-HU');
      return numberFormatter.format(sum);
    },
    camapignTypes() {
      if (this.isAvBased) {
        return 'AV alapú';
      } else {
        return 'Idő alapú';
      }
    },
    statusLabel() {
      let labelParts = [];
      if (!this.entity.approved) {
        labelParts.push('Elfogadásra vár');
      }
      if (this.entity.unfinalized) {
        labelParts.push('Ceruzás');
      }
      if (this.entity.flexible) {
        labelParts.push('Rugalmas');
      }

      return labelParts.length === 0 ? 'Végleges' : labelParts.join(', ');
    },
    infoTooltipContent() {
      if (!this.entity.ad_zones.length) {
        return 'Nincs még zónához rendelve';
      }

      let tooltip = '';
      this.entity.ad_zones.forEach(item => {
        tooltip += item.name + '<br/>';
      });

      return tooltip;
    },
    noteTooltipContent() {
      return this.entity.note;
    },
  },
  methods: {
    showDeleteConfirmCampaign() {
      let content = 'Valóban törölni szeretnéd a felvitt kampányt?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteCampaign,
      });
    },
    deleteCampaign() {
      return CampaignService.delete(this.entity)
        .then(() => {
          this.$toast.success('Kampány törlése sikeresen megtörtént');
          this.$emit('deleted', this.entity);
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
