import AdZoneGroup from '@/entities/AdZoneGroup';
import AdZoneGroupService from '@/services/AdZoneGroup';

const state = {
  isLoading: true,
  list: [],
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  list(state) {
    return state.list;
  },

  get: (state) => (id) => {
    for (let item of state.list) {
      if (item.id === id) {
        return item;
      }
    }

    return null;
  },
};

const actions = {
  fetch(store) {
    this.isFetching = true;

    return AdZoneGroupService.getList({ limit: 999 }).then((resp) => {
      store.commit('setList', resp);
      store.commit('setIsLoading', false);
      return resp;
    });
  },

  save(store, entity) {
    let promise;
    if (entity.isNew) {
      promise = AdZoneGroupService.create(entity);
    } else {
      promise = AdZoneGroupService.update(entity);
    }

    return promise.then((resp) => {
      const responsedEntity = new AdZoneGroup(resp);

      if (entity.isNew) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },
  delete(store, entity) {
    return AdZoneGroupService.delete(entity).then(
      store.commit('deleteFromList', entity)
    );
  },
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setList(state, list) {
    state.list = [];

    for (let item of list) {
      if (item instanceof AdZoneGroup) {
        state.list.push(item);
      } else {
        state.list.push(new AdZoneGroup(item));
      }
    }
  },

  addToList(state, entity) {
    state.list.push(entity);
  },

  updateInList(state, entity) {
    for (let item of state.list) {
      if (item.id !== entity.id) {
        continue;
      }

      item.updateWith(entity);

      break;
    }
  },
  deleteFromList(state, entity) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
