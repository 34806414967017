var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-flags-view" },
    [
      _c("date-flag-filter", {
        attrs: { disabled: _vm.isLoading },
        on: { change: _vm.loadNewData },
      }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "loader" }, [_c("i")])
            : [
                _vm.list.length === 0
                  ? _c(
                      "div",
                      { staticClass: "empty" },
                      [
                        _vm._v(
                          " A kijelölt időszakban (" +
                            _vm._s(_vm.dateRangeString) +
                            ") nincsenek megjelölt időszakok. "
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass: "button",
                            attrs: { to: "/date-flag/new" },
                          },
                          [_vm._v(" Új időszak felvitele ")]
                        ),
                      ],
                      1
                    )
                  : [
                      _c(
                        "table",
                        [
                          _vm._m(0),
                          _vm._l(_vm.list, function (entity, key) {
                            return _c("tr", { key: "row-" + key }, [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: "/date-flag/" + entity.id },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "color",
                                        style:
                                          "background-color: " + entity.color,
                                      }),
                                      _vm._v(" " + _vm._s(entity.name) + " "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(_vm._s(_vm.flagKeyValues[entity.flag])),
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dateRangeFormatter(
                                      entity.date_from,
                                      entity.date_to
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: "/date-flag/" + entity.id },
                                    },
                                    [_c("i", { staticClass: "icon edit" })]
                                  ),
                                  _c("i", {
                                    staticClass: "icon trash",
                                    on: {
                                      click: () =>
                                        _vm.showDeleteConfirm(entity),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ],
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("Megnevezés")]),
      _c("td", [_vm._v("Típus")]),
      _c("td", [_vm._v("Időszak")]),
      _c("td"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }