import('./assets/scss/styles.scss');

/* IMPORT SCRIPTS */
import Vue from 'vue';
import { router } from '@/router';
import { store } from '@/store';

import VModal from 'vue-js-modal';
Vue.use(VModal);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import { FunctionalCalendar } from 'vue-functional-calendar';
Vue.component('functional-calendar', FunctionalCalendar);

import { Settings } from 'luxon';
Settings.defaultLocale = 'hu';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.component('vue-toast', VueToast);
Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 5000,
  dismissible: true,
});

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import ConfirmationModal from '@/plugins/confirmation';
Vue.use(ConfirmationModal);

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/dist/v-dropdown-menu.css';
Vue.use(DropdownMenu);

import AccessHandlerMixin from '@/mixins/accessHandler.js';
Vue.mixin(AccessHandlerMixin);

import App from './App.vue';

Vue.config.productionTip = false;

Vue.prototype.$roles = [
  { machine_name: 'superadmin', label: 'Adminisztrátor' },
  { machine_name: 'user', label: 'Felhasználó' },
];

Vue.prototype.$store = store;

Vue.prototype.$months = [
  'Január',
  'Február',
  'Március',
  'Április',
  'Május',
  'Június',
  'Július',
  'Augusztus',
  'Szeptember',
  'Október',
  'November',
  'December',
];
Vue.prototype.$shortDays = ['Hét', 'Kedd', 'Szer', 'Csüt', 'Pén', 'Szom', 'Vas'];
Vue.prototype.$days = ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'];

Vue.prototype.$dateFlags = [
  { machine_name: 'basic', label: 'Alap' },
  { machine_name: 'highlighted', label: 'Kiemelt' },
  { machine_name: 'extra_highlighted', label: 'Extra kiemelt' },
]

window.AppInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
