import Api from '@/services/Api';

export default {
  getList(params) {
    let path = '/campaigns';
    if (params) {
      path += '?' + new URLSearchParams(params).toString();
    }

    return Api()
      .get(path)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  getCampaign(entity) {
    return Api()
      .get('/campaign/' + entity)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  create(entity) {
    const data = {
      type: entity.type,
      name: entity.name,
      color: entity.color,
      salesman_id: entity.salesman ? entity.salesman.id : null,
      manager_id: entity.manager ? entity.manager.id : null,
      customer_id: entity.customer ? entity.customer.id : null,
      ad_zone_ids: entity.ad_zones.map(item => item.id),
      mobile: entity.mobile,
      desktop: entity.desktop,
      mobile_av: entity.mobile_av ? entity.mobile_av : null,
      desktop_av: entity.desktop_av ? entity.desktop_av : null,
      flexible: entity.flexible,
      unfinalized: entity.unfinalized,
      note: entity.note,
      price: entity.price,
      currency: entity.currency,
      date_from: entity.date_from,
      date_to: entity.date_to,
    };

    return Api()
      .post('campaign/new', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  update(entity) {
    const data = {
      type: entity.type,
      name: entity.name,
      color: entity.color,
      salesman_id: entity.salesman ? entity.salesman.id : null,
      manager_id: entity.manager ? entity.manager.id : null,
      customer_id: entity.customer ? entity.customer.id : null,
      ad_zone_ids: entity.ad_zones.map(item => item.id),
      mobile: entity.mobile,
      desktop: entity.desktop,
      mobile_av: entity.mobile_av ? entity.mobile_av : null,
      desktop_av: entity.desktop_av ? entity.desktop_av : null,
      flexible: entity.flexible,
      unfinalized: entity.unfinalized,
      note: entity.note,
      price: entity.price,
      currency: entity.currency,
      date_from: entity.date_from,
      date_to: entity.date_to,
      approved: entity.approved,
    };

    return Api()
      .put('/campaign/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  delete(entity) {
    return Api()
      .delete('/campaign/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
