import Api from '@/services/Api';

export default {
  getList() {
    let path = '/ad-zone-groups';

    return Api()
      .get(path)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  create(entity) {
    const data = {
      name: entity.name,
      ad_zone_ids: entity.ad_zones.map(item => item.id),
    };

    return Api()
      .post('/ad-zone-group/new', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    const data = {
      name: entity.name,
      ad_zone_ids: entity.ad_zones.map(item => item.id),
    };

    return Api()
      .put('/ad-zone-group/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  delete(entity) {
    return Api()
      .delete('/ad-zone-group/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
