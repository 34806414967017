<template>
  <div class="campaign-form-occupancy-block-component">
    <div class="info">
      Jelenlegi foglaltság: {{ formattedCurrentOccupiedSum }} AV
      |
      Foglalás után: {{ formattedAfterOccupiedSum }} AV
      <span v-if="occupiedPercentage > 0" :class="'occupied-percentage ' + (occupiedPercentage > 100 ? 'overfilled' : '')">
        ({{ occupiedPercentage + '%' }})
      </span>
    </div>

    <div class="forecast">
      <i class="icon tick" />
      Az adott időszakban várható AV: {{ formattedForecastedSum }}
    </div>

    <div v-if="editedEntity.desktop && !!forecasted.desktop" class="row">
      <label>Desktop</label>
      <progress-bar :value="parseInt(afterOccupied.desktop / forecasted.desktop * 100)" />
    </div>
    <div v-if="editedEntity.mobile && !!forecasted.mobile" class="row">
      <label>Mobil</label>
      <progress-bar :value="parseInt(afterOccupied.mobile / forecasted.mobile * 100)" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import ProgressBar from '@/components/ProgressBar';

import Campaign from '@/entities/Campaign.js';
import { CampaignType } from '@/entities/Campaign';

import StatService from '@/services/Stat';

export default {
  name: 'CampaignFormOccupancyBlock',
  components: {
    ProgressBar,
  },
  props: {
    originalEntity: {
      type: Campaign,
      required: true,
    },
    editedEntity: {
      type: Campaign,
      required: true,
    },
  },
  data() {
    return {
      forecasted: {
        desktop: 0,
        mobile: 0,
      },
      occupied: {
        desktop: 0,
        mobile: 0,
      },
      occupiedWithoutCurrentCampaign: {
        desktop: 0,
        mobile: 0,
      },
    };
  },
  computed: {
    forecastedSum() {
      let sum = 0;
      if (this.editedEntity.desktop) {
        sum += this.forecasted.desktop;
      }
      if (this.editedEntity.mobile) {
        sum += this.forecasted.mobile;
      }
      return sum;
    },
    formattedForecastedSum() {
      return this.forecastedSum ? new Intl.NumberFormat('hu-HU').format(this.forecastedSum) : '-';
    },
    currentOccupiedSum() {
      let sum = 0;
      if (this.editedEntity.desktop) {
        sum += this.occupied.desktop;
      }
      if (this.editedEntity.mobile) {
        sum += this.occupied.mobile;
      }
      return sum;
    },
    formattedCurrentOccupiedSum() {
      return this.currentOccupiedSum ? new Intl.NumberFormat('hu-HU').format(this.currentOccupiedSum) : '-';
    },
    occupiedWithoutCurrentCampaignSum() {
      let sum = 0;
      if (this.editedEntity.desktop) {
        sum += this.occupiedWithoutCurrentCampaign.desktop;
      }
      if (this.editedEntity.mobile) {
        sum += this.occupiedWithoutCurrentCampaign.mobile;
      }
      return sum;
    },
    afterOccupied() {
      let occupiedValues = {
        desktop: 0 + this.occupiedWithoutCurrentCampaign.desktop,
        mobile: 0 + this.occupiedWithoutCurrentCampaign.mobile,
      };

      if (this.editedEntity.type === CampaignType.TIME_BASED) {
        if (this.editedEntity.desktop) {
          occupiedValues.desktop += this.forecasted.desktop;
        }
        if (this.editedEntity.mobile) {
          occupiedValues.mobile += this.forecasted.mobile;
        }
      }
      else {
        if (this.editedEntity.desktop) {
          occupiedValues.desktop += (this.editedEntity.desktop_av ? parseInt(this.editedEntity.desktop_av) : 0);
        }
        if (this.editedEntity.mobile) {
          occupiedValues.mobile += (this.editedEntity.mobile_av ? parseInt(this.editedEntity.mobile_av) : 0);
        }
      }

      return occupiedValues;
    },
    afterOccupiedSum() {
      let sum = 0;
      if (this.editedEntity.desktop) {
        sum += this.afterOccupied.desktop;
      }
      if (this.editedEntity.mobile) {
        sum += this.afterOccupied.mobile;
      }
      return sum;
    },
    formattedAfterOccupiedSum() {
      return this.afterOccupiedSum ? new Intl.NumberFormat('hu-HU').format(this.afterOccupiedSum) : '-';
    },
    occupiedPercentage() {
      if (!this.afterOccupiedSum || !this.forecastedSum) {
        return 0;
      }

      return Math.round(this.afterOccupiedSum / this.forecastedSum * 100);
    },
  },
  watch: {
    'editedEntity.ad_zones'() {
      this.updateStats();
    },
    'editedEntity.date_from'() {
      this.updateStats();
    },
    'editedEntity.date_to'() {
      this.updateStats();
    },
  },
  methods: {
    updateStats: _.debounce(function () {
      console.log(this.editedEntity);
      StatService.getByZones({
        adZones: this.editedEntity.ad_zones,
        mobile: this.editedEntity.mobile,
        desktop: this.editedEntity.desktop,
        dateFrom: this.editedEntity.date_from,
        dateTo: this.editedEntity.date_to,
        ignoredCampaign: !this.originalEntity.isNew ? this.originalEntity : null,
      })
        .then((resp) => {
          this.forecasted.desktop = resp.desktop.forecasted_av;
          this.forecasted.mobile = resp.mobile.forecasted_av;

          this.occupied.desktop = resp.desktop.occupied_av;
          this.occupied.mobile = resp.mobile.occupied_av;

          this.occupiedWithoutCurrentCampaign.desktop = resp.desktop.occupied_av_without_ignored_campaign;
          this.occupiedWithoutCurrentCampaign.mobile = resp.mobile.occupied_av_without_ignored_campaign;
        });
    }, 25),
  },
};
</script>
