<template>
  <div class="auth-page login-form-view">
    <auth-logo />

    <div class="content">
      <div class="title">Bejelentkezés</div>
      <div class="credentials">
        <input v-model="email" type="email" placeholder="E-mail cím megadása" />
        <input
          v-model="password"
          type="password"
          placeholder="Jelszó megadása"
        />
        <button class="button" @click="submit">
          <pulse-loader
            v-if="isLoading"
            :loading="isLoading"
            color="#ffffff"
            size="6px"
          />
          <template v-else> Bejelentkezek </template>
        </button>
      </div>

      <router-link to="/auth/forgotten-password" class="forgotten-password">
        Elfelejtetted a jelszavad?
      </router-link>
    </div>
    <div class="footer">
      <div class="logo"><i class="icon sk-logo" /></div>
      <div class="text">
        AdbookR | Hirdetés kelező rendszer {{ year }} | All right reserved |
        <a href="" target="_blank">Adatvédelmi szabályzat</a>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import AuthLogo from '@/components/AuthLogo';

import { UnauthorizedError } from '@/errors';
import { isValidEmail } from '@/utils/validators';

export default {
  name: 'LoginFormView',
  components: {
    PulseLoader,
    AuthLogo,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      isLoading: false,
      email: '',
      password: '',
    };
  },
  methods: {
    resetForm() {
      this.email = '';
      this.password = '';
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      this.$store
        .dispatch('auth/login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.replace({ path: '/' });
          this.resetForm();
        })
        .catch((err) => {
          let message;
          if (err instanceof UnauthorizedError) {
            message = 'Érvénytelen email cím vagy jelszó';
          } else {
            message = err.message;
          }

          this.$toast.error(message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      this.$toast.clear();

      if (!this.email) {
        this.$toast.error('Email mező üres');
        return false;
      } else if (!isValidEmail(this.email)) {
        this.$toast.error('A megadott email cím érvénytelen');
        return false;
      } else if (!this.password) {
        this.$toast.error('Jelszó mező üres');
        return false;
      }

      return true;
    },
  },
};
</script>
