var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { class: "user-card" + (_vm.pending ? " pending" : "") }, [
    _c("div", { staticClass: "circle" }, [
      _c("div", { staticClass: "circle-txt" }, [
        _vm._v(_vm._s(_vm.getFirstLetters(_vm.entity.name))),
      ]),
    ]),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.entity.name))]),
      _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.entity.email))]),
      _vm.pending
        ? _c(
            "button",
            { staticClass: "button bordered", on: { click: _vm.resendEmail } },
            [
              _vm.isLoadingEmail
                ? _c("pulse-loader", {
                    attrs: { color: "#000000", size: "6px" },
                  })
                : [_vm._v(" Meghívó újraküldése")],
            ],
            2
          )
        : _c(
            "div",
            [
              _c("v-select", {
                attrs: {
                  label: "name",
                  options: _vm.roles,
                  disabled: !_vm.isSuperUser || _vm.isItMe,
                  searchable: false,
                },
                on: { input: _vm.updateUser },
                model: {
                  value: _vm.entity.roles,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "roles", $$v)
                  },
                  expression: "entity.roles",
                },
              }),
            ],
            1
          ),
      _c("div", { staticClass: "delete" }, [
        _vm.isSuperUser && !_vm.isItMe
          ? _c(
              "button",
              {
                staticClass: "button bordered",
                on: { click: _vm.showDeleteConfirmUserCard },
              },
              [
                _c("i", { staticClass: "icon trash" }),
                _vm._v(" " + _vm._s(_vm.submitButtonLabel) + " "),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }