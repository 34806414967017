import Site from '@/entities/Site';
import SiteService from '@/services/Site';

const state = {
  list: [],
};

const getters = {
  list(state) {
    return state.list;
  },
  get: (state) => (id) => {
    for (let item of state.list) {
      if (item.id === id) {
        return item;
      }
    }

    return null;
  },
};

const actions = {
  fetch(store) {
    return SiteService.getList({ limit: 999 }).then((resp) => {
      store.commit('setList', resp);
      return resp;
    });
  },
  save(store, entity) {
    let promise;
    if (entity.isNew) {
      promise = SiteService.create(entity);
    } else {
      promise = SiteService.update(entity);
    }

    return promise.then((resp) => {
      const responsedEntity = new Site(resp);

      if (entity.isNew) {
        store.commit('addToList', responsedEntity);
      } else {
        store.commit('updateInList', responsedEntity);
      }

      return responsedEntity;
    });
  },
  delete(store, entity) {
    return SiteService.delete(entity).then(
      store.commit('deleteFromList', entity)
    );
  },
};

const mutations = {
  setList(state, list) {
    state.list = [];

    for (let item of list) {
      if (item instanceof Site) {
        state.list.push(item);
      } else {
        state.list.push(new Site(item));
      }
    }
  },

  addToList(state, entity) {
    state.list.push(entity);
  },

  updateInList(state, entity) {
    for (let item of state.list) {
      if (item.id !== entity.id) {
        continue;
      }

      item.updateWith(entity);

      break;
    }
  },
  deleteFromList(state, entity) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
