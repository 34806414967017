import moment from 'moment';
import Api from '@/services/Api';

export default {
  getZones({
    dateFrom,
    dateTo,
    mobile,
    desktop,
    sites = [],
    adZones = [],
    salesman = [],
    manager = [],
    customers = [],
    term,
    ignoreOnlyApprovedFilter,
  }) {
    const params = {};
    if (ignoreOnlyApprovedFilter) {
      params['ignore_only_approved_filter'] = 1;
    }

    if (dateFrom) {
      params['date_from'] = moment(dateFrom).format('YYYY-MM-DD');
    }

    if (dateTo) {
      params['date_to'] = moment(dateTo).format('YYYY-MM-DD');
    }

    params['mobile'] = mobile ? 1 : 0;
    params['desktop'] = desktop ? 1 : 0;

    if (typeof term === 'string' && term.length !== 0) {
      params['term'] = term;
    }

    let query = new URLSearchParams(params).toString();

    if (Array.isArray(sites) && sites.length !== 0) {
      sites.forEach(item => query += '&site_ids[]=' + item.id);
    }
    if (Array.isArray(adZones) && adZones.length !== 0) {
      adZones.forEach(item => query += '&ad_zone_ids[]=' + item.id);
    }
    if (Array.isArray(salesman) && salesman.length !== 0) {
      salesman.forEach(item => query += '&salesman_ids[]=' + item.id);
    }
    if (Array.isArray(manager) && manager.length !== 0) {
      manager.forEach(item => query += '&manager_ids[]=' + item.id);
    }
    if (Array.isArray(customers) && customers.length !== 0) {
      customers.forEach(item => query += '&customer_ids[]=' + item.id);
    }

    return Api()
      .get('/stats/zones?' + query)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  getWeekly({
    dateFrom,
    dateTo,
    mobile,
    desktop,
    sites = [],
    adZones = [],
    salesman = [],
    manager = [],
    customers = [],
    term,
    ignoreOnlyApprovedFilter,
  }) {
    const params = {};
    if (ignoreOnlyApprovedFilter) {
      params['ignore_only_approved_filter'] = 1;
    }

    if (dateFrom) {
      params['date_from'] = moment(dateFrom).format('YYYY-MM-DD');
    }

    if (dateTo) {
      params['date_to'] = moment(dateTo).format('YYYY-MM-DD');
    }

    params['mobile'] = mobile ? 1 : 0;
    params['desktop'] = desktop ? 1 : 0;

    if (typeof term === 'string' && term.length !== 0) {
      params['term'] = term;
    }

    let query = new URLSearchParams(params).toString();

    if (Array.isArray(sites) && sites.length !== 0) {
      sites.forEach(item => query += '&site_ids[]=' + item.id);
    }
    if (Array.isArray(adZones) && adZones.length !== 0) {
      adZones.forEach(item => query += '&ad_zone_ids[]=' + item.id);
    }
    if (Array.isArray(salesman) && salesman.length !== 0) {
      salesman.forEach(item => query += '&salesman_ids[]=' + item.id);
    }
    if (Array.isArray(manager) && manager.length !== 0) {
      manager.forEach(item => query += '&manager_ids[]=' + item.id);
    }
    if (Array.isArray(customers) && customers.length !== 0) {
      customers.forEach(item => query += '&customer_ids[]=' + item.id);
    }

    return Api()
      .get('/stats/weekly?' + query)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  getByZones({
    adZones,
    mobile,
    desktop,
    dateFrom,
    dateTo,
    ignoredCampaign,
  }) {
    const params = {
      ad_zone_ids: adZones.map(i => i.id),
      mobile: mobile ? 1 : 0,
      desktop: desktop ? 1 : 0,
      date_from: dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : null,
      date_to: dateTo ? moment(dateTo).format('YYYY-MM-DD') : null,
      ignored_campaign_id: ignoredCampaign ? ignoredCampaign.id : null,
    };

    let query = new URLSearchParams(params).toString();

    if (Array.isArray(adZones) && adZones.length !== 0) {
      adZones.forEach(item => query += '&ad_zone_ids[]=' + item.id);
    }

    return Api()
      .get('/ad-zones/stat?' + query)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  export({
    dateFrom,
    dateTo,
    mobile,
    desktop,
    sites = [],
    adZones = [],
    salesman = [],
    manager = [],
    customers = [],
    term,
    ignoreOnlyApprovedFilter,
  }) {
    const params = {};
    if (ignoreOnlyApprovedFilter) {
      params['ignore_only_approved_filter'] = 1;
    }

    if (dateFrom) {
      params['date_from'] = moment(dateFrom).format('YYYY-MM-DD');
    }

    if (dateTo) {
      params['date_to'] = moment(dateTo).format('YYYY-MM-DD');
    }

    params['mobile'] = mobile ? 1 : 0;
    params['desktop'] = desktop ? 1 : 0;

    if (typeof term === 'string' && term.length !== 0) {
      params['term'] = term;
    }

    let query = new URLSearchParams(params).toString();

    if (Array.isArray(sites) && sites.length !== 0) {
      sites.forEach(item => query += '&site_ids[]=' + item.id);
    }
    if (Array.isArray(adZones) && adZones.length !== 0) {
      adZones.forEach(item => query += '&ad_zone_ids[]=' + item.id);
    }
    if (Array.isArray(salesman) && salesman.length !== 0) {
      salesman.forEach(item => query += '&salesman_ids[]=' + item.id);
    }
    if (Array.isArray(manager) && manager.length !== 0) {
      manager.forEach(item => query += '&manager_ids[]=' + item.id);
    }
    if (Array.isArray(customers) && customers.length !== 0) {
      customers.forEach(item => query += '&customer_ids[]=' + item.id);
    }

    return Api()
      .get('/stats/export?' + query)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
