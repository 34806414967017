<template>
  <div class="users-view">
    <div class="title">Felvitt csapattagok</div>
    <entity-sorter :entities="filteredEntities" />
    <entity-search @change="filter" />

    <div class="emptyList" v-if="filteredEntities.length == 0">
      Nincsenek a szűrésnek megfelelő elemek
    </div>
    <div class="list-grid" v-else>
      <user-card
        v-for="entity in filteredEntities"
        :key="entity.id"
        :entity="entity"
        :pending="false"
      />
    </div>
    <div class="bottom">
      <router-link class="button" :to="'/user/invite'">
        Csapattag hozzáadása
      </router-link>
    </div>

    <div class="invites">
      <div class="title">Meghívott csapattagok</div>
      <div v-if="invites.length === 0" class="emptyList">
        Nincsenek függőben lévő meghívások
      </div>
      <div v-else class="list-grid">
        <user-card
          v-for="invite in invites"
          :key="invite.id"
          :pending="true"
          :entity="invite"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EntitySearch from '@/components/EntitySearch';
import EntitySorter from '@/components/EntitySorter';
import UserCard from '@/components/UserCard';

export default {
  name: 'UsersView',
  components: {
    EntitySearch,
    EntitySorter,
    UserCard,
  },
  data: () => ({
    filteredEntities: [],
  }),
  computed: {
    entities() {
      return this.$store.getters['auth/companyMembers'];
    },
    invites() {
      return this.$store.getters['invites/list'];
    },
  },
  mounted() {
    this.filteredEntities = this.entities;
  },
  methods: {
    filter(t) {
      const term = t.toLowerCase();

      this.filteredEntities = [];
      this.entities.map(entity => {
        if (!term) {
          this.filteredEntities.push(entity);
          return;
        }

        if (entity.name.toLowerCase().includes(term)) {
          this.filteredEntities.push(entity);
        }
      });
    }
  }
};
</script>
