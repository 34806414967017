<template>
  <div class="ad-zone-form-view">
    <back-link url="/ad-zones" label="Vissza a zónákhoz"></back-link>
    <div class="page-title">{{ pageTitle }}</div>
    <ad-zone-form v-if="entity != null" :entity="entity" />
  </div>
</template>
<script>
import AdZoneForm from '@/components/AdZoneForm';
import BackLink from '@/components/BackLink';

import AdZone from '@/entities/AdZone';

export default {
  name: 'AdZoneFormView',
  components: {
    AdZoneForm,
    BackLink,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    entity() {
      if (this.id === 'new') {
        return new AdZone();
      }

      return this.$store.getters['adZone/get'](this.id);
    },
    pageTitle() {
      if (this.id === 'new') {
        return 'Zóna hozzáadása';
      } else {
        return 'Zóna szerkesztése';
      }
    },
  },
  watch: {
    entity: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.$router.replace('/ad-zones');
        }
      },
    },
  },
};
</script>
