var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isPanelOpen
          ? _c("div", {
              staticClass: "sidebar-backdrop",
              on: { click: _vm.closeSidebarPanel },
            })
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isPanelOpen
          ? _c("div", { staticClass: "sidebar-panel" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "logo" }, [
                    _c("i", { staticClass: "icon logo" }),
                  ]),
                  _c("div", { staticClass: "close" }, [
                    _c("i", {
                      staticClass: "icon close",
                      on: { click: _vm.closeSidebarPanel },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "options",
                    on: { click: _vm.closeSidebarPanel },
                  },
                  [
                    !_vm.isOutsiderUser
                      ? _c("router-link", { attrs: { to: "/" } }, [
                          _vm._v(" Áttekintés "),
                        ])
                      : _vm._e(),
                    _c(
                      "router-link",
                      { attrs: { to: "/ad-zone-occupancies" } },
                      [_vm._v(" Foglaltsági naptár ")]
                    ),
                    _c("router-link", { attrs: { to: "/campaigns" } }, [
                      _vm._v(" Kampányok "),
                    ]),
                    !_vm.isOutsiderUser
                      ? _c(
                          "router-link",
                          { attrs: { to: "/campaigns/past" } },
                          [_vm._v(" Múltbéli kampányok ")]
                        )
                      : _vm._e(),
                    _vm.isSuperUser
                      ? _c(
                          "router-link",
                          { attrs: { to: "/campaigns/pending" } },
                          [_vm._v(" Elfogadásra váró kampányok ")]
                        )
                      : _vm._e(),
                    _c("router-link", { attrs: { to: "/customers" } }, [
                      _vm._v(" Ügyfelek "),
                    ]),
                    _vm.isSuperUser
                      ? _c("router-link", { attrs: { to: "/sites" } }, [
                          _vm._v(" Oldalak "),
                        ])
                      : _vm._e(),
                    _vm.isSuperUser
                      ? _c("router-link", { attrs: { to: "/ad-zones" } }, [
                          _vm._v(" Zónák "),
                        ])
                      : _vm._e(),
                    _vm.isSuperUser
                      ? _c(
                          "router-link",
                          { attrs: { to: "/ad-zone-groups" } },
                          [_vm._v(" Zónaszettek ")]
                        )
                      : _vm._e(),
                    _vm.isSuperUser
                      ? _c("router-link", { attrs: { to: "/users" } }, [
                          _vm._v(" Csapattagok "),
                        ])
                      : _vm._e(),
                    _vm.isSuperUser
                      ? _c("router-link", { attrs: { to: "/flagged-dates" } }, [
                          _vm._v(" Kiemelt időszakok "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "footer" }, [
                _c("div", { staticClass: "sk-logo" }, [
                  _c("i", { staticClass: "icon sk-logo" }),
                ]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    " HKR | Hirdetés kelező rendszer " + _vm._s(_vm.year) + " I"
                  ),
                  _c("br"),
                  _vm._v(" All right reserved | "),
                  _c("a", { attrs: { href: "", target: "”_blank”" } }, [
                    _vm._v(" Adatvédelmi szabályzat"),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }