<template>
  <div v-if="inventoryIsLoading" class="loader">
    <i />
  </div>
  <div v-else class="ad-zone-form">
    <v-select
      v-model="model.site"
      :options="sites"
      label="name"
      placeholder="Oldal"
    >
      <template #no-options> Nincsenek kiválasztható elemek. </template>
    </v-select>
    <input type="text" placeholder="Zónanév" v-model="model.name" />
    <v-select
      v-model="model.ad_manager_code"
      :options="selectableZones"
      :disabled="!model.isNew"
      placeholder="Ad Manager zóna"
    >
      <template #no-options>
        Nincsenek elérhető új zónák
      </template>
    </v-select>
    <v-select
      v-if="showSimilarSelect"
      :options="zonesWithForecast"
      placeholder="Hasonló zóna"
      v-model="model.similar"
    ></v-select>

    <div class="color-picker-and-checkboxes">
      <color-picker v-model="model.color" @input="onColorSelected" />

      <div class="checkboxes">
        <label>
          <input type="checkbox" v-model="model.mobile" />
          Mobil
        </label>
        <label>
          <input type="checkbox" v-model="model.desktop" />
          Desktop
        </label>
      </div>
    </div>

    <div class="actions">
      <button class="button" :disabled="isSubmitDisabled" @click="submit">
        <pulse-loader v-if="isSaving" color="#ffffff" size="6px" />
        <template v-else>
          {{ submitButtonLabel }}
        </template>
      </button>
      <button
        v-if="!model.isNew"
        @click="showDeleteConfirmAdZone"
        class="button clean"
      >
        Zóna törlése
      </button>
    </div>
  </div>
</template>

<script>
import { Compact as ColorPicker } from 'vue-color';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import AdZone from '@/entities/AdZone';

import AdZoneService from '@/services/AdZone';

export default {
  name: 'AdZoneForm',
  components: {
    ColorPicker,
    PulseLoader,
  },
  props: {
    entity: {
      type: AdZone,
      required: true,
    },
  },
  data: () => ({
    inventoryIsLoading: false,
    model: null,
    isSaving: false,
    selectableZones: [],
    zonesWithForecast: [],
    similar: '',
  }),
  created() {
    this.inventoryIsLoading = true;
    AdZoneService.getInventory()
      .then((resp) => {
        this.selectableZones = resp.selectable_zones;
        this.zonesWithForecast = resp.zones_with_forecast;
      })
      .finally(() => this.inventoryIsLoading = false);

    this.model = new AdZone(this.entity);
    if (this.sites.length === 1) {
      this.model.site = this.sites[0];
    }
  },
  computed: {
    sites() {
      return this.$store.getters['site/list'];
    },
    isSubmitDisabled() {
      if (
        !this.model.name ||
        (!this.model.mobile && !this.model.desktop) ||
        !this.model.ad_manager_code
      ) {
        return true;
      }

      return false;
    },
    submitButtonLabel() {
      return this.model.isNew ? 'Zóna hozzáadása' : 'Zóna módosítása';
    },
    showSimilarSelect() {
      return this.model.isNew && !!this.model.ad_manager_code && !this.zonesWithForecast.includes(this.model.ad_manager_code);
    },
  },
  methods: {
    showDeleteConfirmAdZone() {
      let content = 'Valóban törölni szeretnéd a felvitt zónát?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteZone,
      });
    },
    onColorSelected(value) {
      this.model.color = value.hex;
    },
    submit() {
      if (this.isSaving || this.isSubmitDisabled) {
        return;
      }

      this.isSaving = true;

      this.$store
        .dispatch('adZone/save', this.model)
        .then(() => {
          this.$router.replace('/ad-zones');
          this.$toast.success('Zóna mentése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
    deleteZone() {
      this.isSaving = true;
      return this.$store
        .dispatch('adZone/delete', this.entity)
        .then(() => {
          this.$toast.success('Zóna törlése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
  },
};
</script>
