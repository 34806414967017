const fieldLabels = {
  'customer_id': 'Ügyfél',
  'salesman_id': 'Értékesítő',
  'site_id': 'Oldal',
  'ad_zone_ids': 'Zónák',
  'color': 'Szín',
  'name': 'Név',
  'type': 'Típus',
  'date_from': 'Dátum',
  'date_to': 'Dátum',
  'email': 'Email',
  'password': 'Jelszó',
  'current_password': 'Jelenlegi jelszó',
  'new_password': 'Új jelszó',
};

const errorTypeLabels = {
  'required': 'mező nem lehet üres',
};

class ValidationError extends Error {
  constructor(errors) {
    const message = ValidationError.buildMessage(errors);
    super(message);
    this.errors = errors;
  }

  get errors() {
    return this.errorList;
  }

  set errors(errors) {
    this.errorList = errors;
  }

  get firstError() {
    return this.getError(Object.keys(this.errors)[0]);
  }

  hasError(field) {
    return typeof this.errors[field] !== 'undefined';
  }

  getError(field) {
    return this.errors[field];
  }

  static buildMessage(errors) {
    const field = Object.keys(errors)[0];
    const value = errors[field];

    if (value.indexOf('validation.') !== 0) {
      return value;
    }

    const errorType = value.replace('validation.', '');

    const fieldLabel = field in fieldLabels ? fieldLabels[field] : field;
    const labelStartsWithConsonant = 'bcdfghjklmnpqrstvwxy'.indexOf(field[field.length - 1].toLocaleLowerCase()) !== -1;

    const errorTypeLabel = errorType in errorTypeLabels ? errorTypeLabels[errorType] : errorType;

    return (labelStartsWithConsonant ? 'A' : 'Az') + ' ' + fieldLabel + ' ' + errorTypeLabel;
  }
}

module.exports = ValidationError;
