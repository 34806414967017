<template>
  <div class="site-form">
    <input type="text" class="name" placeholder="Név" v-model="model.name" />
    <input type="text" calss="name" placeholder="Url" v-model="model.url" />
    <div class="actions">
      <button class="button" @click="submit">
        <pulse-loader v-if="isSaving" color="#ffffff" size="6px" />
        <template v-else>
          {{ submitButtonLabel }}
        </template>
      </button>
      <button
        v-if="showDeleteButton"
        @click="showDeleteConfirmSite"
        class="button clean"
      >
        Oldal törlése
      </button>
    </div>
  </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import Site from '@/entities/Site';
export default {
  name: 'SiteForm',
  components: {
    PulseLoader,
  },
  props: {
    entity: {
      type: Site,
      required: true,
    },
  },
  data: () => ({
    model: null,
    isSaving: false,
    save: true,
  }),
  created() {
    this.model = new Site(this.entity);
  },
  computed: {
    submitButtonLabel() {
      return this.model.isNew ? 'Oldal hozzáadása' : 'Oldal módosítása';
    },
    showDeleteButton() {
      return this.model.id;
    },
  },
  methods: {
    showDeleteConfirmSite() {
      let content = 'Valóban törölni szeretnéd a felvitt oldalt?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteSite,
      });
    },
    submit() {
      this.$toast.clear();
      this.save = true;
      if (!this.model.name) {
        this.$toast.error('Név mező kitöltése kötelező');
        this.save = false;
      } else if (!this.isValidHttpUrl(this.model.url)) {
        this.$toast.error('Nem megfelelő URL cím');
        this.save = false;
      }

      if (this.save) {
        if (this.isSaving) {
          return;
        }

        this.isSaving = true;
        this.$store
          .dispatch('site/save', this.model)
          .then(() => {
            this.$router.replace('/sites');
            this.$toast.success('Oldal mentése sikeresen megtörtént');
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => (this.isSaving = false));
      }
    },

    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    deleteSite() {
      return this.$store
        .dispatch('site/delete', this.entity)
        .then(() => {
          this.$toast.success('Oldal törlése sikeresen megtörtént');
          this.$router.replace('/sites');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
