import AdZone from '@/entities/AdZone';

import Vue from 'vue';

export default class AdZoneGroup {
  id = null;
  name = '';
  ad_zones = [];
  created_at = new Date();

  constructor(rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if (key === 'ad_zones') {
        this[key] = [];

        for (let item of value) {
          let entity = Vue.prototype.$store.getters['adZone/get'](item.id);
          if (!entity) {
            entity = new AdZone(item);
          }

          this[key].push(entity);
        }
      }
      else if (
        (key.indexOf('date') !== -1 || key === 'created_at') &&
        !!value
      ) {
        this[key] = new Date(value);
      }
      else {
        this[key] = value;
      }
    }
  }
}
