var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ad-zones-view" },
    [
      _vm.entities.length === 0
        ? _c("div", { staticClass: "emptyList" }, [
            _vm._v(" Nincsenek megjeleníthető elemek "),
          ])
        : [
            _c("entity-sorter", { attrs: { entities: _vm.filteredEntities } }),
            _c("entity-search", { on: { change: _vm.filter } }),
            _vm.filteredEntities.length == 0
              ? _c("div", { staticClass: "emptyList" }, [
                  _vm._v(" Nincsenek a szűrésnek megfelelő elemek "),
                ])
              : _c(
                  "div",
                  { staticClass: "list-grid" },
                  _vm._l(_vm.filteredEntities, function (entity) {
                    return _c("ad-zone-card", {
                      key: entity.id,
                      attrs: { entity: entity },
                    })
                  }),
                  1
                ),
          ],
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "router-link",
            { staticClass: "button", attrs: { to: "/ad-zone/new" } },
            [_vm._v(" Zóna hozzáadása ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }