<template>
  <div class="ad-zone-groups-view">
    <div v-if="isLoading" class="loader">
      <i />
    </div>
    <div v-else-if="entities.length === 0" class="emptyList">
      Nincsenek megjeleníthető elemek
    </div>
    <template v-else>
      <div v-for="(entity, key) in entities" :key="'ad-zone-group--' + key" class="table-row">
        <div class="name">
          <router-link :to="'/ad-zone-group/' + entity.id">
            {{ entity.name }}
          </router-link>
        </div>
        <div class="actions">
          <router-link class="button clean icon-only" :to="'/ad-zone-group/' + entity.id">
            <i class="icon edit" />
          </router-link>
          <button class="button clean icon-only" @click="() => showDeleteConfirmAdZoneGroup(entity)">
            <i class="icon trash" />
          </button>
        </div>
      </div>
    </template>

    <div class="bottom">
      <router-link class="button" :to="'/ad-zone-group/new'">
        Zónaszett hozzáadása
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdZoneGroupsView',
  computed: {
    isLoading() {
      return this.$store.getters['adZoneGroup/isLoading'];
    },
    entities() {
      return this.$store.getters['adZoneGroup/list'];
    },
  },
  methods: {
    showDeleteConfirmAdZoneGroup(entity) {
      let content = 'Valóban törölni szeretnéd a zónaszettet?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: () => this.deleteZoneGroup(entity),
      });
    },
    deleteZoneGroup(entity) {
      return this.$store
        .dispatch('adZoneGroup/delete', entity)
        .then(() => {
          this.$toast.success('Zónaszett törlése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
