var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "site-card" }, [
    _c("div", { staticClass: "details" }, [
      _vm._v(" " + _vm._s(_vm.entity.name)),
      _c("br"),
      _vm.entity.url
        ? _c("a", { attrs: { href: _vm.entity.url, target: "_blank" } }, [
            _vm._v(" " + _vm._s(_vm.entity.url) + " "),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c(
          "router-link",
          { staticClass: "button", attrs: { to: "/site/" + _vm.entity.id } },
          [_c("i", { staticClass: "icon edit white" }), _vm._v(" Módosít ")]
        ),
        _c(
          "button",
          {
            staticClass: "button bordered",
            on: { click: _vm.showDeleteConfirmSite },
          },
          [_c("i", { staticClass: "icon trash" }), _vm._v(" Törlés ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }