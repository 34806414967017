<template>
  <div class="auth-logo-component">
    <div class="adbookr"><i class="icon logo"></i></div>
    <div class="hkr">a hirdetés kezelő rendszer</div>
  </div>
</template>

<script>
export default {
  name: 'AuthLogo',
};
</script>
