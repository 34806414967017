<template>
  <div class="profile-view">
    <user-profile-form :entity="profile" />
  </div>
</template>
<script>
import UserProfileForm from '@/components/UserProfileForm.vue';
export default {
  name: 'ProfileView',
  components: {
    UserProfileForm,
  },
  computed: {
    profile() {
      return this.$store.getters['auth/user'];
    },
  },
};
</script>
