var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bar-component" }, [
    _c("div", {
      staticClass: "bar",
      style: "width: " + _vm.processedValue + "%",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }