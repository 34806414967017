<template>
  <div class="entity-sorter">
    <v-select
      v-model="orderBy"
      :options="options"
      label="label"
      placeholder="Rendezés"
      @input="onSelected"
    >
      <template #no-options>
        Nincsenek rendezhető elemek
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'EntityOrderSorter',
  props: {
    entities: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    orderBy: null,
  }),
  computed: {
    options() {
      const options = [];

      if (this.entities.length) {
        if (typeof this.entities[0].created_at !== 'undefined') {
          options.push({ label: 'Újonnan létrehozottak elől', property: 'created_at', order: 'desc' });
          options.push({ label: 'Régebben létrehozottak elől', property: 'created_at', order: 'asc' });
        }
        if (typeof this.entities[0].name !== 'undefined') {
          options.push({ label: 'ABC szerint növekvő', property: 'name', order: 'asc' });
          options.push({ label: 'ABC szerint csökkenő', property: 'name', order: 'desc' });
        }
      }

      return options
    }
  },
  methods: {
    onSelected() {
      const property = this.orderBy.property;
      const order = this.orderBy.order;

      this.entities.sort((a,b) => {
        const aValue = property === 'created_at' ? a[property].getTime() : a[property];
        const bValue = property === 'created_at' ? b[property].getTime() : b[property];

        if (typeof aValue === 'string') {
          const compare = aValue.localeCompare(bValue, 'hu', { sensitivity: 'base' });
          return order === 'asc' ? compare : -1 * compare;
        } else {
          if (order === 'asc') {
            return aValue == bValue ? 0 : aValue > bValue ? 1 : -1;
          } else {
            return aValue == bValue ? 0 : aValue < bValue ? 1 : -1;
          }
        }
      });

      this.$emit('change', this.orderBy);
    }
  }
};
</script>
