<template>
  <div class="progress-bar-component">
    <div class="bar" :style="'width: ' + processedValue + '%'" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    processedValue() {
      if (this.value < 0) {
        return 0;
      }
      if (this.value > 100) {
        return 100;
      }

      return this.value;
    },
  },
};
</script>
