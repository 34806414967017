<template>
  <div class="sites-view">
    <div class="emptyList" v-if="entities.length == 0">
      Nincsenek megjeleníthető elemek
    </div>
    <div class="list-grid" v-else>
      <site-card v-for="entity in entities" :key="entity.id" :entity="entity" />
    </div>

    <div class="bottom">
      <router-link class="button" :to="'/site/new'">
        Oldal hozzáadása
      </router-link>
    </div>
  </div>
</template>
<script>
import SiteCard from '@/components/SiteCard';

export default {
  name: 'SitesView',
  components: {
    SiteCard,
  },
  computed: {
    entities() {
      return this.$store.getters['site/list'];
    },
  },
};
</script>
