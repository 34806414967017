var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-header" }, [
    _c("div", { staticClass: "logo" }, [_c("menu-burger")], 1),
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c("router-link", { attrs: { to: _vm.addButtonRouteTo } }, [
          _c("i", { staticClass: "icon add" }),
        ]),
        _c("router-link", { attrs: { to: "/profile" } }, [
          _c("i", { staticClass: "icon user" }),
        ]),
        _c("i", {
          staticClass: "icon logout",
          on: {
            click: function ($event) {
              return _vm.logOut()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }