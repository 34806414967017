<template>
  <div class="ad-zone-group-form-view">
    <back-link url="/ad-zone-groups" label="Vissza a zónaszettekhez"></back-link>
    <div class="page-title">{{ pageTitle }}</div>
    <ad-zone-group-form v-if="entity != null" :entity="entity" />
  </div>
</template>
<script>
import AdZoneGroupForm from '@/components/AdZoneGroupForm';
import BackLink from '@/components/BackLink';

import AdZoneGroup from '@/entities/AdZoneGroup';

export default {
  name: 'AdZoneFormView',
  components: {
    AdZoneGroupForm,
    BackLink,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    entity() {
      if (this.id === 'new') {
        return new AdZoneGroup();
      }

      return this.$store.getters['adZoneGroup/get'](this.id);
    },
    pageTitle() {
      if (this.id === 'new') {
        return 'Zónaszett hozzáadása';
      } else {
        return 'Zónaszett szerkesztése';
      }
    },
  },
  watch: {
    adZone: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.$router.replace('/ad-zone-groups');
        }
      },
    },
  },
};
</script>
