var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-component" },
    [
      _c("div", { staticClass: "background background--gradient" }),
      _c("div", {
        staticClass: "background background--color",
        style: "background-color:" + _vm.color,
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }