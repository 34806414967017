var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "campaign-form" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.name,
            expression: "model.name",
          },
        ],
        attrs: { type: "text", placeholder: "Kampány neve" },
        domProps: { value: _vm.model.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.model, "name", $event.target.value)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "select-with-button" },
        [
          _c("v-select", {
            attrs: {
              options: _vm.customers,
              label: "name",
              placeholder: "Ügyfél",
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [
                    _vm._v(" Előbb fel kell venned legalább egy ügyfelet. "),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.model.customer,
              callback: function ($$v) {
                _vm.$set(_vm.model, "customer", $$v)
              },
              expression: "model.customer",
            },
          }),
          _c(
            "router-link",
            {
              staticClass: "button add-new-customer-button",
              attrs: { to: "/customer/new" },
            },
            [_c("i", { staticClass: "icon add" }), _vm._v(" Új ügyfél ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "select-with-button" },
        [
          _c("v-select", {
            attrs: {
              multiple: "",
              options: _vm.adZones,
              label: "name",
              placeholder: "Felület",
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function () {
                  return [_vm._v(" Nincsenek kiválasztható elemek. ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.model.ad_zones,
              callback: function ($$v) {
                _vm.$set(_vm.model, "ad_zones", $$v)
              },
              expression: "model.ad_zones",
            },
          }),
          _c(
            "button",
            { staticClass: "button", on: { click: _vm.showZoneGroupModal } },
            [_c("i", { staticClass: "icon add" }), _vm._v(" Zónaszett ")]
          ),
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          options: _vm.campaignTypes,
          label: "label",
          reduce: (item) => item.value,
          placeholder: "Értékesítés típusa",
        },
        model: {
          value: _vm.model.type,
          callback: function ($$v) {
            _vm.$set(_vm.model, "type", $$v)
          },
          expression: "model.type",
        },
      }),
      _c("div", { staticClass: "lower" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("div", { staticClass: "av-inputs" }, [
              _c("div", { staticClass: "av-input-row" }, [
                _c("label", { staticClass: "checkbox-wrapper" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.desktop,
                        expression: "model.desktop",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.model.desktop)
                        ? _vm._i(_vm.model.desktop, null) > -1
                        : _vm.model.desktop,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.model.desktop,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.model, "desktop", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "desktop",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "desktop", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" Desktop "),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.desktop_av,
                      expression: "model.desktop_av",
                    },
                  ],
                  staticClass: "avs",
                  attrs: {
                    type: "number",
                    placeholder: "Desktop AV",
                    disabled: !_vm.model.desktop || _vm.disabledAvInputs,
                  },
                  domProps: { value: _vm.model.desktop_av },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.model, "desktop_av", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "av-input-row" }, [
                _c("label", { staticClass: "checkbox-wrapper" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.mobile,
                        expression: "model.mobile",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.model.mobile)
                        ? _vm._i(_vm.model.mobile, null) > -1
                        : _vm.model.mobile,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.model.mobile,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.model, "mobile", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "mobile",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "mobile", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" Mobil "),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.mobile_av,
                      expression: "model.mobile_av",
                    },
                  ],
                  staticClass: "avs",
                  attrs: {
                    type: "number",
                    placeholder: "Mobil AV",
                    disabled: !_vm.model.mobile || _vm.disabledAvInputs,
                  },
                  domProps: { value: _vm.model.mobile_av },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.model, "mobile_av", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("campaign-form-occupancy-block", {
              attrs: {
                "original-entity": _vm.entity,
                "edited-entity": _vm.model,
              },
            }),
            _c("div", { staticClass: "checkboxes" }, [
              _c("label", { staticClass: "checkbox-wrapper" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.unfinalized,
                      expression: "model.unfinalized",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.model.unfinalized)
                      ? _vm._i(_vm.model.unfinalized, null) > -1
                      : _vm.model.unfinalized,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.model.unfinalized,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.model,
                              "unfinalized",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.model,
                              "unfinalized",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.model, "unfinalized", $$c)
                      }
                    },
                  },
                }),
                _vm._v("Ceruzás "),
              ]),
              _c("label", { staticClass: "checkbox-wrapper" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.flexible,
                      expression: "model.flexible",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.model.flexible)
                      ? _vm._i(_vm.model.flexible, null) > -1
                      : _vm.model.flexible,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.model.flexible,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.model, "flexible", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.model,
                              "flexible",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.model, "flexible", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" Rugalmas "),
              ]),
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.note,
                  expression: "model.note",
                },
              ],
              attrs: { placeholder: "Megjegyzés" },
              domProps: { value: _vm.model.note },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.model, "note", $event.target.value)
                },
              },
            }),
            _c("color-picker", {
              on: { input: _vm.onColorSelected },
              model: {
                value: _vm.model.color,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "color", $$v)
                },
                expression: "model.color",
              },
            }),
            _c("label", [_vm._v("Értékesítő")]),
            _c("v-select", {
              attrs: {
                options: _vm.users,
                label: "name",
                placeholder: "Értékesítő",
              },
              model: {
                value: _vm.model.salesman,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "salesman", $$v)
                },
                expression: "model.salesman",
              },
            }),
            _c("label", [_vm._v("Kampánymenedzser")]),
            _c("v-select", {
              attrs: {
                options: _vm.users,
                label: "name",
                placeholder: "Kampánymenedzser",
              },
              model: {
                value: _vm.model.manager,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "manager", $$v)
                },
                expression: "model.manager",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm._m(0),
            _c("functional-calendar", {
              ref: "calendar",
              staticClass: "calendar",
              attrs: { configs: _vm.calendarConfigs },
              model: {
                value: _vm.calendarData,
                callback: function ($$v) {
                  _vm.calendarData = $$v
                },
                expression: "calendarData",
              },
            }),
            _vm.model.owner
              ? _c("div", { staticClass: "owner" }, [
                  _vm._v(
                    " Kampányt felvitte: " + _vm._s(_vm.model.owner.name) + " "
                  ),
                ])
              : _vm._e(),
            _vm.model.approval_needed && _vm.isSuperUser
              ? _c("label", { staticClass: "checkbox-wrapper approve" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.approved,
                        expression: "model.approved",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.model.approved)
                        ? _vm._i(_vm.model.approved, null) > -1
                        : _vm.model.approved,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.model.approved,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.model, "approved", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "approved",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "approved", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" Jóváhagyva "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          { staticClass: "button", on: { click: _vm.submit } },
          [
            _vm.isSaving
              ? _c("pulse-loader", {
                  attrs: {
                    loading: _vm.isSaving,
                    color: "#ffffff",
                    size: "6px",
                  },
                })
              : [_vm._v(" " + _vm._s(_vm.submitButtonLabel) + " ")],
          ],
          2
        ),
        _vm.showDeleteButton
          ? _c(
              "button",
              {
                staticClass: "button clean delete-button",
                on: { click: _vm.showDeleteConfirmCampaign },
              },
              [_vm._v(" Kampány törlése ")]
            )
          : _vm._e(),
      ]),
      _c(
        "modal",
        {
          staticClass: "without-background",
          attrs: { name: "select-zone-group-modal" },
        },
        [
          _c("h2", [_vm._v("Zónaszett hozzáadása kapányhoz")]),
          _c("v-select", {
            attrs: {
              options: _vm.adZoneGroups,
              label: "name",
              placeholder: "Zónaszett",
            },
            model: {
              value: _vm.adZoneGroup,
              callback: function ($$v) {
                _vm.adZoneGroup = $$v
              },
              expression: "adZoneGroup",
            },
          }),
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { disabled: !_vm.adZoneGroup },
              on: { click: _vm.addAdZoneGroup },
            },
            [_vm._v(" Szett zónáinak hozzáadása ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calendar-title" }, [
      _c("label", [_vm._v("Időszak kiválasztása")]),
      _c("i", { staticClass: "icon calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }