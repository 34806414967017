<template>
  <div :class="wrapperClasses">
    <div class="zone-main">
      <div class="zone" @click="toggle">
        <i v-if="!isOutsiderUser" class="icon dropdown-arrow" />
        <div class="zone-color" :style="'background-color: ' + zone.color" />
        <div class="zone-name">
          {{ zone.name }}
          <i
            v-if="isOverfilled"
            v-tooltip="overfilledTooltipContent"
            class="icon warning"
          />
        </div>
      </div>

      <progress-bar :value="occupancedRate" />
      <div class="oppupancy-rate">{{ occupancedRate }}% foglaltság</div>
      <div class="oppupanced-av" v-tooltip="mainOccupancyTooltip">
        Foglalt AV: {{ formattedNumber(occupiedTotal) }}
        <i class="icon info" />
      </div>
      <div v-if="customers.length !== 0 && !isOutsiderUser" class="customers">
        <a
          v-for="(customer, key) in customers"
          :key="'customer-' + key"
          :href="'/customer/' + customer.id"
          target="_blank"
        >
          {{ customer.name }}
        </a>
      </div>
    </div>

    <transition-expand>
      <div v-if="isOpen" class="campaign-rows">
        <div
          v-for="campaign in campaigns"
          :key="'campaign--' + campaign.id"
          class="campaign-row">
          <a
            class="campaign-name"
            :href="'/campaign/' + campaign.id"
            target="_blank"
          >
            <i v-if="campaign.flexible" v-tooltip="'Rugalmas'" class="icon flexible" />
            <i v-if="campaign.unfinalized" v-tooltip="'Ceruzás'" class="icon unfinalized" />
            <i v-if="!campaign.flexible && !campaign.unfinalized" v-tooltip="'Végleges'" class="icon finalized" />

            [{{ campaign.customer.name }}] {{ campaign.name }}
          </a>
          <div class="type">
            {{ campaignTypeHumanLabel(campaign.type) }}
          </div>
          <div class="oppupanced-av" v-tooltip="campaignOccupancyTooltip(campaign)">
            Foglalt AV: {{ formattedNumber(campaign.occupied.total) }}
            <i class="icon info" />
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar';
import TransitionExpand from '@/components/TransitionExpand';

import AdZone from '@/entities/AdZone';

import { CampaignType } from '@/entities/Campaign';

export default {
  name: 'AdZoneOccupancyZoneRow',
  components: {
    ProgressBar,
    TransitionExpand,
  },
  props: {
    zone: {
      type: AdZone,
      required: true,
    },
    campaigns: {
      type: Array,
      required: false,
      default: () => [],
    },
    forecastedMobile: {
      type: Number,
      required: false,
      default: 0,
    },
    forecastedDesktop: {
      type: Number,
      required: false,
      default: 0,
    },
    occupiedMobile: {
      type: Number,
      required: false,
      default: 0,
    },
    occupiedDesktop: {
      type: Number,
      required: false,
      default: 0,
    },
    flexible: {
      type: Number,
      required: false,
      default: 0,
    },
    unfinalized: {
      type: Number,
      required: false,
      default: 0,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    wrapperClasses() {
      return {
        'ad-zone-occupancy-zone-row-component': true,
        'is-open': this.isOpen,
      };
    },
    occupiedTotal() {
      return this.occupiedMobile + this.occupiedDesktop;
    },
    forecastedTotal() {
      return this.forecastedMobile + this.forecastedDesktop;
    },
    overfilledMobile() {
      return this.forecastedMobile < this.occupiedMobile;
    },
    overfilledDesktop() {
      return this.forecastedDesktop < this.occupiedDesktop;
    },
    customers() {
      const customers = this.campaigns.map(i => i.customer);

      let customerIdsInSet = [];
      let result = [];

      customers.forEach(customer => {
        if (!customerIdsInSet.includes(customer.id)) {
          customerIdsInSet.push(customer.id);
          result.push(customer);
        }
      });

      return result;
    },
    occupancedRate() {
      if (!this.forecastedTotal) {
        return 0;
      }

      return Math.floor(this.occupiedTotal / this.forecastedTotal * 100);
    },
    occupancedRateString() {
      return this.occupancedRate > 0 ? this.occupancedRate + '%' : '-';
    },
    formattedOccupancedAvNumber() {
      const numberFormatter = new Intl.NumberFormat('hu-HU');
      return numberFormatter.format(Math.floor(this.occupiedTotal));
    },
    isOverfilled() {
      return this.overfilledMobile || this.overfilledDesktop;
    },
    overfilledTooltipContent() {
      let parts = [];
      if (this.overfilledMobile) {
        parts.push('mobil');
      }
      if (this.overfilledDesktop) {
        parts.push('desktop');
      }

      return 'Túlfoglalt ' + parts.join(' & ');
    },
    mainOccupancyTooltip() {
      let forecastedMobile = '-';
      let forecastedDesktop = '-';
      let occupiedMobile = '-';
      let occupiedDesktop = '-';

      if (this.forecastedMobile) {
        forecastedMobile = this.formattedNumber(this.forecastedMobile) + ' AV';
      }
      if (this.forecastedDesktop) {
        forecastedDesktop = this.formattedNumber(this.forecastedDesktop) + ' AV';
      }
      if (this.occupiedMobile) {
        occupiedMobile = this.formattedNumber(this.occupiedMobile) + ' AV';
      }
      if (this.occupiedDesktop) {
        occupiedDesktop = this.formattedNumber(this.occupiedDesktop) + ' AV';
      }

      return '<b>MOBIL</b><br/>' +
        ' &nbsp; Előrejelzett: ' + forecastedMobile + ' &nbsp; <br/>' +
        ' &nbsp; Foglalt: ' + occupiedMobile + ' <hr/>' +
        '<b>DESKTOP</b><br/>' +
        ' &nbsp; Előrejelzett: ' + forecastedDesktop + ' &nbsp; <br/>' +
        ' &nbsp; Foglalt: ' + occupiedDesktop;
    }
  },
  watch: {
    open() {
      this.isOpen = this.open;
    }
  },
  methods: {
    toggle() {
      if (!this.isOutsiderUser) {
        this.isOpen = !this.isOpen;
      }
    },
    formattedNumber(number) {
      const numberFormatter = new Intl.NumberFormat('hu-HU');
      return numberFormatter.format(Math.floor(number));
    },
    campaignTypeHumanLabel(machineName) {
      switch (machineName) {
        case CampaignType.TIME_BASED:
          return 'Idő alapú';
        case CampaignType.AV_BASED:
          return 'AV alapú';
      }

      return '';
    },
    campaignOccupancyTooltip(campaign) {
      let occupiedMobile = '-';
      let occupiedDesktop = '-';

      if (campaign.occupied.mobile) {
        occupiedMobile = this.formattedNumber(campaign.occupied.mobile) + ' AV';
      }
      if (campaign.occupied.desktop) {
        occupiedDesktop = this.formattedNumber(campaign.occupied.desktop) + ' AV';
      }

      return '<b>MOBIL</b><br/>' +
        ' &nbsp; Foglalt: ' + occupiedMobile + ' &nbsp; <hr/>' +
        '<b>DESKTOP</b><br/>' +
        ' &nbsp; Foglalt: ' + occupiedDesktop + ' &nbsp; ';
    }
  },
}
</script>
