var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users-view" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Felvitt csapattagok")]),
      _c("entity-sorter", { attrs: { entities: _vm.filteredEntities } }),
      _c("entity-search", { on: { change: _vm.filter } }),
      _vm.filteredEntities.length == 0
        ? _c("div", { staticClass: "emptyList" }, [
            _vm._v(" Nincsenek a szűrésnek megfelelő elemek "),
          ])
        : _c(
            "div",
            { staticClass: "list-grid" },
            _vm._l(_vm.filteredEntities, function (entity) {
              return _c("user-card", {
                key: entity.id,
                attrs: { entity: entity, pending: false },
              })
            }),
            1
          ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "router-link",
            { staticClass: "button", attrs: { to: "/user/invite" } },
            [_vm._v(" Csapattag hozzáadása ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "invites" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Meghívott csapattagok")]),
        _vm.invites.length === 0
          ? _c("div", { staticClass: "emptyList" }, [
              _vm._v(" Nincsenek függőben lévő meghívások "),
            ])
          : _c(
              "div",
              { staticClass: "list-grid" },
              _vm._l(_vm.invites, function (invite) {
                return _c("user-card", {
                  key: invite.id,
                  attrs: { pending: true, entity: invite },
                })
              }),
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }