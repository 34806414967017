var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ad-zone-groups-view" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [_c("i")])
        : _vm.entities.length === 0
        ? _c("div", { staticClass: "emptyList" }, [
            _vm._v(" Nincsenek megjeleníthető elemek "),
          ])
        : _vm._l(_vm.entities, function (entity, key) {
            return _c(
              "div",
              { key: "ad-zone-group--" + key, staticClass: "table-row" },
              [
                _c(
                  "div",
                  { staticClass: "name" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/ad-zone-group/" + entity.id } },
                      [_vm._v(" " + _vm._s(entity.name) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "button clean icon-only",
                        attrs: { to: "/ad-zone-group/" + entity.id },
                      },
                      [_c("i", { staticClass: "icon edit" })]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button clean icon-only",
                        on: {
                          click: () => _vm.showDeleteConfirmAdZoneGroup(entity),
                        },
                      },
                      [_c("i", { staticClass: "icon trash" })]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "router-link",
            { staticClass: "button", attrs: { to: "/ad-zone-group/new" } },
            [_vm._v(" Zónaszett hozzáadása ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }