var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "auth-logo-component" }, [
      _c("div", { staticClass: "adbookr" }, [
        _c("i", { staticClass: "icon logo" }),
      ]),
      _c("div", { staticClass: "hkr" }, [_vm._v("a hirdetés kezelő rendszer")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }