<template>
  <div class="customers-view">
    <div class="emptyList" v-if="entities.length == 0">
      Nincsenek megjeleníthető elemek
    </div>
    <template v-else>
      <entity-sorter :entities="filteredEntities" />
      <entity-search @change="filter" />

      <div class="emptyList" v-if="filteredEntities.length == 0">
        Nincsenek a szűrésnek megfelelő elemek
      </div>
      <div class="list-grid" v-else>
        <customer-card
          v-for="entity in filteredEntities"
          v-show="!entity.hideInList"
          :key="entity.id"
          :entity="entity"
        />
      </div>
      <div class="bottom">
        <router-link class="button" :to="'/customer/new'">
          Ügyfél hozzáadása
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import CustomerCard from '@/components/CustomerCard';
import EntitySearch from '@/components/EntitySearch';
import EntitySorter from '@/components/EntitySorter';

export default {
  name: 'CustomersView',
  components: {
    CustomerCard,
    EntitySearch,
    EntitySorter,
  },
  data: () => ({
    filteredEntities: [],
  }),
  computed: {
    entities() {
      return this.$store.getters['customer/list'];
    },
  },
  mounted() {
    this.filteredEntities = this.entities;
  },
  methods: {
    filter(t) {
      const term = t.toLowerCase();

      this.filteredEntities = [];
      this.entities.map(entity => {
        if (!term) {
          this.filteredEntities.push(entity);
          return;
        }

        if (entity.name.toLowerCase().includes(term)) {
          this.filteredEntities.push(entity);
        }
      });
    }
  }
};
</script>
