var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-form" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.model.name,
          expression: "model.name",
        },
      ],
      staticClass: "name",
      attrs: { type: "text", placeholder: "Név" },
      domProps: { value: _vm.model.name },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.model, "name", $event.target.value)
        },
      },
    }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.model.url,
          expression: "model.url",
        },
      ],
      attrs: { type: "text", calss: "name", placeholder: "Url" },
      domProps: { value: _vm.model.url },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.model, "url", $event.target.value)
        },
      },
    }),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        { staticClass: "button", on: { click: _vm.submit } },
        [
          _vm.isSaving
            ? _c("pulse-loader", { attrs: { color: "#ffffff", size: "6px" } })
            : [_vm._v(" " + _vm._s(_vm.submitButtonLabel) + " ")],
        ],
        2
      ),
      _vm.showDeleteButton
        ? _c(
            "button",
            {
              staticClass: "button clean",
              on: { click: _vm.showDeleteConfirmSite },
            },
            [_vm._v(" Oldal törlése ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }