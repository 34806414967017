var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ad-zone-group-form-view" },
    [
      _c("back-link", {
        attrs: { url: "/ad-zone-groups", label: "Vissza a zónaszettekhez" },
      }),
      _c("div", { staticClass: "page-title" }, [_vm._v(_vm._s(_vm.pageTitle))]),
      _vm.entity != null
        ? _c("ad-zone-group-form", { attrs: { entity: _vm.entity } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }