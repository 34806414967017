<template>
  <div class="ad-zones-view">
    <div v-if="entities.length === 0" class="emptyList">
      Nincsenek megjeleníthető elemek
    </div>
    <template v-else>
      <entity-sorter :entities="filteredEntities" />
      <entity-search @change="filter" />

      <div class="emptyList" v-if="filteredEntities.length == 0">
        Nincsenek a szűrésnek megfelelő elemek
      </div>
      <div class="list-grid" v-else>
        <ad-zone-card
          v-for="entity in filteredEntities"
          :key="entity.id"
          :entity="entity"
        />
      </div>
    </template>

    <div class="bottom">
      <router-link class="button" :to="'/ad-zone/new'">
        Zóna hozzáadása
      </router-link>
    </div>
  </div>
</template>

<script>
import AdZoneCard from '@/components/AdZoneCard';
import EntitySearch from '@/components/EntitySearch';
import EntitySorter from '@/components/EntitySorter';

export default {
  name: 'AdZoneView',
  components: {
    AdZoneCard,
    EntitySearch,
    EntitySorter,
  },
  data: () => ({
    filteredEntities: [],
  }),
  computed: {
    entities() {
      return this.$store.getters['adZone/list'];
    },
  },
  mounted() {
    this.filteredEntities = this.entities;
  },
  methods: {
    filter(t) {
      const term = t.toLowerCase();

      console.log(term)

      this.filteredEntities = [];
      this.entities.map(entity => {
        if (!term) {
          this.filteredEntities.push(entity);
          return;
        }

        if (entity.name.toLowerCase().includes(term) || entity.ad_manager_code.toLowerCase().includes(term)) {
          this.filteredEntities.push(entity);
        }
      });
    }
  }
};
</script>
