<template>
  <div class="main-content">
    <app-header
      v-if="
        this.$route.name != 'Login' &&
        this.$route.name != 'Signup' &&
        this.$route.name != 'ForgottenPassword' &&
        this.$route.name != 'ResetPassword'
      "
    ></app-header>
    <div class="router-frame">
      <transition name="fade--fast">
        <div v-if="isLoading" class="loader">
          <i />
        </div>
        <router-view v-else />
      </transition>
    </div>
    <menu-sidebar></menu-sidebar>
  </div>
</template>

<script>
import MenuSidebar from '@/components/MenuSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
export default {
  name: 'App',
  components: {
    MenuSidebar,
    AppHeader,
  },
  computed: {
    isLoading() {
      return this.$store.getters['app/isLoading'];
    },
    loggedInUser() {
      return this.$store.getters['auth/user'];
    },
  },
  watch: {
    loggedInUser(val, oldVal) {
      if (!val) {
        this.$store.commit('adZone/setList', []);
        this.$store.commit('customer/setList', []);
        this.$store.commit('site/setList', []);
      } else if (oldVal === null) {
        this.fetchAllData();
      }
    },
  },
  beforeCreate: function () {
    document.body.className = 'colorful';
  },
  created() {
    if (this.$store.getters['auth/loggedIn']) {
      this.$store.dispatch('auth/fetch').finally(() => {
        if (this.loggedInUser === null) {
          this.$store.commit('app/isLoading', false);
        }
      });
    } else {
      this.$store.commit('app/isLoading', false);
    }
  },
  methods: {
    fetchAllData() {
      const promises = [
        this.$store.dispatch('adZone/fetch'),
        this.$store.dispatch('customer/fetch'),
        this.$store.dispatch('site/fetch'),
        this.$store.dispatch('app/fetch'),
      ];

      this.$store.commit('app/isLoading', true);
      Promise.all(promises)
        .then(() => {
          this.$store.dispatch('invites/fetch');
          this.$store.dispatch('adZoneGroup/fetch');
        })
        .catch(() =>
          this.$toast.error('Hiba történt az adatok betöltése közben')
        )
        .finally(() => this.$store.commit('app/isLoading', false));
    },
  },
};
</script>
