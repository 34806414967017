<template>
  <div class="customer-form-view">
    <back-link url="/customers" label="Vissza az ügyfelekhez"></back-link>
    <div class="page-title">{{ pageTitle }}</div>
    <customer-form v-if="customer != null" :entity="customer" />
  </div>
</template>
<script>
import CustomerForm from '@/components/CustomerForm.vue';
import Customer from '@/entities/Customer';
import BackLink from '@/components/BackLink.vue';

export default {
  name: 'CustomerFormView',
  components: {
    CustomerForm,
    BackLink,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    customer() {
      if (this.id === 'new') {
        return new Customer();
      }
      return this.$store.getters['customer/get'](this.id);
    },
    pageTitle() {
      if (this.id === 'new') {
        return 'Ügyfél hozzáadása';
      } else {
        return 'Ügyfél szerkesztése';
      }
    },
  },
  watch: {
    customer: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.$router.replace('/customers');
        }
      },
    },
  },
};
</script>
