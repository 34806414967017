var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-view" },
    [
      _c("div", { staticClass: "top-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button",
            class: { bordered: _vm.onlyMine },
            attrs: { disabled: _vm.isLoading },
            on: { click: () => (_vm.onlyMine = false) },
          },
          [_vm._v(" Mutasd mindet ")]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            class: { bordered: !_vm.onlyMine },
            attrs: { disabled: _vm.isLoading },
            on: { click: () => (_vm.onlyMine = true) },
          },
          [_vm._v(" Mutasd az én foglalásaimat ")]
        ),
      ]),
      _c("div", { staticClass: "campaigns" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Eheti kampányok")]),
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [_c("i")])
          : _vm.thisWeek.length == 0
          ? _c("div", { staticClass: "emptylist" }, [
              _vm._v(" Nincsenek megjeleníthető elemek "),
            ])
          : _c(
              "div",
              { staticClass: "list-grid" },
              _vm._l(_vm.thisWeek, function (entity) {
                return _c("campaign-card", {
                  key: entity.id,
                  attrs: { entity: entity },
                })
              }),
              1
            ),
      ]),
      _c("div", { staticClass: "campaigns" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Jövőheti kampányok")]),
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [_c("i")])
          : _vm.nextWeek.length == 0
          ? _c("div", { staticClass: "emptylist" }, [
              _vm._v(" Nincsenek megjeleníthető elemek "),
            ])
          : _c(
              "div",
              { staticClass: "list-grid" },
              _vm._l(_vm.nextWeek, function (entity) {
                return _c("campaign-card", {
                  key: entity.id,
                  attrs: { entity: entity },
                })
              }),
              1
            ),
      ]),
      _c("div", { staticClass: "campaigns" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Utána")]),
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [_c("i")])
          : _vm.next2Week.length == 0
          ? _c("div", { staticClass: "emptylist" }, [
              _vm._v(" Nincsenek megjeleníthető elemek "),
            ])
          : _c(
              "div",
              { staticClass: "list-grid" },
              _vm._l(_vm.next2Week, function (entity) {
                return _c("campaign-card", {
                  key: entity.id,
                  attrs: { entity: entity },
                })
              }),
              1
            ),
      ]),
      _c(
        "router-link",
        { staticClass: "button all-campaign", attrs: { to: "/campaigns" } },
        [_vm._v(" Mutasd az összes kampányt ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }