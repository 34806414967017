<template>
  <card class="ad-zone-card" :color="entity.color">
    <div class="name">{{ entity.name }}</div>
    <div>
      Oldal: {{ entity.site.name }}<br/>
      Eszköz: {{ devices }}
    </div>
    <div class="actions">
      <router-link class="button" :to="'/ad-zone/' + entity.id">
        <i class="icon edit white"></i>
        Módosít
      </router-link>
      <button class="button bordered" @click="showDeleteConfirmAdZone">
        <i class="icon trash"></i>
        Törlés
      </button>
    </div>
  </card>
</template>

<script>
import Card from '@/components/Card';

import AdZone from '@/entities/AdZone';
import AdZoneService from '@/services/AdZone';

export default {
  name: 'AdZoneCard',
  props: {
    entity: {
      type: AdZone,
      required: true,
    },
  },
  components: {
    Card,
  },
  computed: {
    devices() {
      const arr = [];
      if (this.entity.desktop) {
        arr.push('desktop')
      }
      if (this.entity.mobile) {
        arr.push('mobil')
      }
      return arr.join(', ')
    }
  },
  methods: {
    showDeleteConfirmAdZone() {
      let content = 'Valóban törölni szeretnéd a felvitt zónát?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteZone,
      });
    },
    async deleteZone() {
      const resp = await AdZoneService.deletable(this.entity);
      if (!resp.deletable) {
        const errorMessage = 'A zóna nem törölhető, mert még van benne tervezett kampány.';
        this.$toast.error(errorMessage);
        return new Promise((resolve, reject) => reject(errorMessage));
      }

      return this.$store
        .dispatch('adZone/delete', this.entity)
        .then(() => {
          this.$toast.success('Zóna törlése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
