import Api from '@/services/Api';

export default {
  invitationValidate(hash) {
    return Api()
      .get('/auth/invitation/' + hash + '/validate')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  signup(hash, password) {
    return Api()
      .post('/auth/invitation/' + hash, { password })
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  login(email, password) {
    return Api()
      .post(
        '/auth/login',
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  logout() {
    return Api()
      .delete('/auth/logout')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  refreshToken() {
    return Api()
      .post('/auth/refresh')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  fetch() {
    return Api()
      .get('/auth/user')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  delete(entity) {
    return Api()
      .delete('/user/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  deleteInvitation(entity) {
    return Api()
      .delete('/user-invitation/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  resendInvitation(entity) {
    return Api()
      .post('/user-invitation/' + entity.id + '/resend')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  fetchInvitations() {
    return Api()
      .get('/user-invitations')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  fetchRoles() {
    return Api()
      .get('/user-roles')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  createUser(entity) {
    const data = {
      name: entity.name,
      email: entity.email,
      role_id: entity.role_id,
    };
    return Api()
      .post('/user-invitation', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  updateUser(entity) {
    const data = {
      name: entity.name,
      email: entity.email,
      role_ids: entity.role_ids,
    };
    return Api()
      .put('/user/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  updatePassword(entity) {
    const data = {
      current_password: entity.oldPassword,
      new_password: entity.newPassword,
    };
    return Api()
      .put('/user/' + entity.id + '/password', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  forgottenPassword(entity) {
    const data = {
      email: entity.email,
    };
    return Api()
      .post('/auth/forgotten-password', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  resetPassword(entity) {
    const data = {
      email: entity.email,
      password: entity.password,
    };
    return Api()
      .post('/auth/forgotten-password/' + entity.token, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
