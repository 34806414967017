<template>
  <div class="burger" @click.prevent="toggle">
    <i class="icon burger"></i>
    <div class="text">Menü</div>
  </div>
</template>
<script>
export default {
  methods: {
    toggle() {
      this.$store.commit('app/toggleNav');
    },
  },
};
</script>
