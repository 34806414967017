<template>
  <div class="date-flags-form-view">
    <back-link url="/flagged-dates" label="Vissza a kiemelt időszakokhoz"></back-link>
    <div class="page-title">{{ pageTitle }}</div>
    <date-flag-form v-if="entity != null" :entity="entity" />
  </div>
</template>
<script>
import BackLink from '@/components/BackLink';
import DateFlagForm from '@/components/DateFlagForm';
import DateFlag from '@/entities/DateFlag';

import DateFlagService from '@/services/DateFlag';

export default {
  name: 'DateFlagFormView',
  components: {
    DateFlagForm,
    BackLink,
  },
  data: () => ({
    isLoading: false,
    entity: null,
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    pageTitle() {
      if (this.id === 'new') {
        return 'Időszak hozzáadása';
      } else {
        return 'Időszak szerkesztése';
      }
    },
  },
  /* watch: {
    entity: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.$router.replace('/flagged-dates');
        }
      },
    },
  }, */
  created() {
    this.loadEntity();
  },
  methods: {
    loadEntity() {
      if (this.id === 'new') {
        this.entity = new DateFlag();
        return;
      }

      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      DateFlagService.get(this.id)
        .then((resp) => {
          this.entity = new DateFlag(resp);
        })
        .catch(() => {
          this.$router.replace('/flagged-dates');
          this.$toast.error(
            'Váratlan hiba történt az időszak betöltése közben.'
          );
        })
        .finally(() => (this.isLoading = false));
    },
  }
};
</script>
