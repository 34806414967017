// import Vue from 'vue';

export function formatForCalendar(dateObj) {
  if (!dateObj) {
    return '';
  }

  return (
    dateObj.getDate() +
    '/' +
    (dateObj.getMonth() + 1) +
    '/' +
    dateObj.getFullYear()
  );
}

export function range(from, to) {
  if (!to) {
    to = from;
  }

  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  var finalDate =
    dateFrom.getFullYear() +
    '. ' +
    // Vue.prototype.$months[dateFrom.getMonth()] +
    (dateFrom.getMonth() + 1).toString().padStart(2, '0') + '.' +
    ' ' +
    // dateFrom.getDate() +
    dateFrom.getDate().toString().padStart(2, '0') +
    '.';

  if (
    dateFrom.getFullYear() === dateTo.getFullYear() &&
    dateFrom.getMonth() === dateTo.getMonth() &&
    dateFrom.getDate() === dateTo.getDate()
  ) {
    return finalDate;
  }

  finalDate = finalDate.substring(0, finalDate.length - 1);
  finalDate += ' - ';

  if (dateFrom.getFullYear() !== dateTo.getFullYear()) {
    return (
      finalDate +
      dateTo.getFullYear() +
      '. ' +
      // Vue.prototype.$months[dateTo.getMonth()] +
      (dateTo.getMonth() + 1).toString().padStart(2, '0') + '.' +
      ' ' +
      // dateTo.getDate() +
      dateTo.getDate().toString().padStart(2, '0') +
      '.'
    );
  }

  if (dateFrom.getMonth() !== dateTo.getMonth()) {
    return (
      finalDate +
      // Vue.prototype.$months[dateTo.getMonth()] +
      (dateTo.getMonth() + 1).toString().padStart(2, '0') + '.' +
      ' ' +
      // dateTo.getDate() +
      dateTo.getDate().toString().padStart(2, '0') +
      '.'
    );
  }

  return finalDate + dateTo.getDate().toString().padStart(2, '0') + '.';
}
