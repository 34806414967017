<template>
  <div class="app-header">
    <div class="logo">
      <menu-burger />
    </div>
    <div class="title">{{ title }}</div>
    <div class="buttons">
      <router-link :to="addButtonRouteTo">
        <i class="icon add" />
      </router-link>
      <router-link to="/profile">
        <i class="icon user" />
      </router-link>
      <i class="icon logout" @click="logOut()" />
    </div>
  </div>
</template>

<script>
import MenuBurger from '@/components/MenuBurger.vue';

export default {
  name: 'AppHeader',
  components: {
    MenuBurger,
  },
  computed: {
    title() {
      switch (this.$route.name) {
        case 'AdZone':
        case 'AdZones':
          return 'Zónák';

        case 'AdZoneGroup':
        case 'AdZoneGroups':
          return 'Zónaszettek';

        case 'Site':
        case 'Sites':
          return 'Oldalak';

        case 'Customer':
        case 'Customers':
          return 'Ügyfelek';

        case 'Campaign':
        case 'Campaigns':
          if (this.$route.meta.past) {
            return 'Múltbéli kapmányok';
          }
          if (this.$route.meta.pending) {
            return 'Elfogadásra váró kapmányok';
          }
          return 'Kampányok';

        case 'User':
        case 'Users':
        case 'UserInvite':
          return 'Csapattagok';

        case 'AdZoneOccupancies':
          return 'Foglaltsági naptár';
      }

      return 'Hello, üdv újra!';
    },
    addButtonRouteTo() {
      switch (this.$route.name) {
        case 'AdZone':
        case 'AdZones':
          return '/ad-zone/new';

        case 'Site':
        case 'Sites':
          return '/site/new';

        case 'Customer':
        case 'Customers':
          return '/customer/new';

        case 'User':
        case 'Users':
        case 'UserInvite':
          return '/user/invite';

        case 'DateFlag':
        case 'DateFlags':
          return '/date-flag/new';
      }

      return '/campaign/new';
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
