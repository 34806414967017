var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      this.$route.name != "Login" &&
      this.$route.name != "Signup" &&
      this.$route.name != "ForgottenPassword" &&
      this.$route.name != "ResetPassword"
        ? _c("app-header")
        : _vm._e(),
      _c(
        "div",
        { staticClass: "router-frame" },
        [
          _c(
            "transition",
            { attrs: { name: "fade--fast" } },
            [
              _vm.isLoading
                ? _c("div", { staticClass: "loader" }, [_c("i")])
                : _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
      _c("menu-sidebar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }