<template>
  <card class="customer-card">
    <div class="customer-data">
      <div>Cégnév: {{ entity.name }}</div>
      <div>Név: {{ entity.contact_name }}</div>
      <div>E-mail: {{ entity.contact_email }}</div>
      <div>Telefonszám: {{ entity.contact_phone }}</div>
    </div>
    <div class="actions">
      <router-link class="button" :to="'/customer/' + entity.id">
        <i class="icon edit white"></i>
        Módosít
      </router-link>
      <button
        class="button bordered"
        @click="showDeleteConfirmCustomer"
        v-if="isSuperUser"
      >
        <i class="icon trash"></i>
        Törlés
      </button>
    </div>
  </card>
</template>

<script>
import Card from '@/components/Card';

import Customer from '@/entities/Customer';

export default {
  name: 'CustomerCard',
  components: {
    Card,
  },
  props: {
    entity: {
      type: Customer,
      required: true,
    },
  },
  methods: {
    showDeleteConfirmCustomer() {
      let content = 'Valóban törölni szeretnéd a felvitt partnert?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteCustomer,
      });
    },
    deleteCustomer() {
      return this.$store
        .dispatch('customer/delete', this.entity)
        .then(() => {
          this.$toast.success('Ügyfél törlése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
