export default {
  computed: {
    totalOccupancedRate() {
      if (this.rates) {
        return Object.values(this.rates).reduce((a, b) => a + b, 0);
      }

      return this.totalOccupanced / this.totalForecasted;
    },
    color() {
      const hueValue = ((1 - this.totalOccupancedRate) * 125).toFixed(0);
      const colorBegin = 'hsl(' + hueValue + ', 80%, 70%)';
      const colorEnd = 'hsl(' + hueValue + ', 100%, 40%)';

      return 'linear-gradient(90deg, ' + colorBegin + ' 0%, ' + colorEnd + ' 100%);';
    },
  },
}
