export default {
  computed: {
    viewType() {
      return this.$store.getters['occupancyStat/type'];
    },
    dateFrom() {
      return this.$store.getters['occupancyStat/dateFrom'];
    },
    dateTo() {
      return this.$store.getters['occupancyStat/dateTo'];
    },
    termFilter() {
      return this.$store.getters['occupancyStat/termFilter'];
    },
    adZoneFilter() {
      return this.$store.getters['occupancyStat/adZoneFilter'];
    },
    siteFilter() {
      return this.$store.getters['occupancyStat/siteFilter'];
    },
    salesmanFilter() {
      return this.$store.getters['occupancyStat/salesmanFilter'];
    },
    managerFilter() {
      return this.$store.getters['occupancyStat/managerFilter'];
    },
    customerFilter() {
      return this.$store.getters['occupancyStat/customerFilter'];
    },
    mobileFilter() {
      return this.$store.getters['occupancyStat/mobileFilter'];
    },
    desktopFilter() {
      return this.$store.getters['occupancyStat/desktopFilter'];
    },
    onlyApprovedCampaigns() {
      return this.$store.getters['occupancyStat/onlyApprovedCampaigns'];
    },
  },
}
