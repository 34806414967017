<template>
  <div class="date-flags-view">
    <date-flag-filter
      :disabled="isLoading"
      @change="loadNewData"
    />

    <div class="table">
      <div v-if="isLoading" class="loader">
        <i />
      </div>
      <template v-else>
        <div v-if="list.length === 0" class="empty">
          A kijelölt időszakban ({{ dateRangeString }}) nincsenek megjelölt időszakok.

          <router-link class="button" to="/date-flag/new">
            Új időszak felvitele
          </router-link>
        </div>
        <template v-else>
          <table>
            <tr>
              <td>Megnevezés</td>
              <td>Típus</td>
              <td>Időszak</td>
              <td></td>
            </tr>
            <tr v-for="(entity, key) in list" :key="'row-' + key">
              <td>
                <router-link :to="'/date-flag/' + entity.id">
                  <div class="color" :style="'background-color: ' + entity.color" />
                  {{ entity.name }}
                </router-link>
              </td>
              <td>{{ flagKeyValues[entity.flag] }}</td>
              <td>{{ dateRangeFormatter(entity.date_from, entity.date_to) }}</td>
              <td>
                <router-link :to="'/date-flag/' + entity.id">
                  <i class="icon edit" />
                </router-link>
                <i class="icon trash" @click="() => showDeleteConfirm(entity)" />
              </td>
            </tr>
          </table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import DateFlagService from '@/services/DateFlag';
import DateFlagFilter from '@/components/DateFlagFilter';

import { range as dateRangeFormatter } from '@/utils/dateFormatter';

export default {
  name: 'DateFlagsView',
  components: {
    DateFlagFilter,
  },
  data: () => ({
    isLoading: true,
    list: [],
  }),
  computed: {
    dateFrom() {
      return this.$store.getters['dateFlag/dateFrom'];
    },
    dateTo() {
      return this.$store.getters['dateFlag/dateTo'];
    },
    dateRangeString() {
      return dateRangeFormatter(this.dateFrom, this.dateTo);
    },
    flagKeyValues() {
      let result = {};
      this.$dateFlags.forEach(item => {
        result[item.machine_name] = item.label;
      })

      return result;
    },
  },
  mounted() {
    if (this.list.length === 0) {
      this.$nextTick(() => this.loadNewData());
    }
  },
  methods: {
    loadNewData() {
      this.isLoading = true;
      this.list = [];

      DateFlagService.getList({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      })
        .then(resp => {
          this.list = resp;
        })
        .catch(e => {
          console.error(e);
          this.$toast.error('Hiba történt az adatok betöltése közben');
        })
        .finally(() => this.isLoading = false);
    },
    dateRangeFormatter(dateFrom, dateTo) {
      return dateRangeFormatter(dateFrom, dateTo);
    },
    showDeleteConfirm(entity) {
      let content = 'Valóban törölni szeretnéd a felvitt időszakmegjelölést?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: () => this.deleteEntity(entity),
      });
    },
    deleteEntity(entity) {
      return DateFlagService.delete(entity)
        .then(() => {
          this.$toast.success('Időszak törlése sikeresen megtörtént');
          this.list = this.list.filter(i => i !== entity);
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
}
</script>
