var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "campaign-form-occupancy-block-component" }, [
    _c("div", { staticClass: "info" }, [
      _vm._v(
        " Jelenlegi foglaltság: " +
          _vm._s(_vm.formattedCurrentOccupiedSum) +
          " AV | Foglalás után: " +
          _vm._s(_vm.formattedAfterOccupiedSum) +
          " AV "
      ),
      _vm.occupiedPercentage > 0
        ? _c(
            "span",
            {
              class:
                "occupied-percentage " +
                (_vm.occupiedPercentage > 100 ? "overfilled" : ""),
            },
            [_vm._v(" (" + _vm._s(_vm.occupiedPercentage + "%") + ") ")]
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "forecast" }, [
      _c("i", { staticClass: "icon tick" }),
      _vm._v(
        " Az adott időszakban várható AV: " +
          _vm._s(_vm.formattedForecastedSum) +
          " "
      ),
    ]),
    _vm.editedEntity.desktop && !!_vm.forecasted.desktop
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c("label", [_vm._v("Desktop")]),
            _c("progress-bar", {
              attrs: {
                value: parseInt(
                  (_vm.afterOccupied.desktop / _vm.forecasted.desktop) * 100
                ),
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.editedEntity.mobile && !!_vm.forecasted.mobile
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c("label", [_vm._v("Mobil")]),
            _c("progress-bar", {
              attrs: {
                value: parseInt(
                  (_vm.afterOccupied.mobile / _vm.forecasted.mobile) * 100
                ),
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }