import AuthService from '@/services/Auth';
import User from '@/entities/User';
import { router } from '@/router';

const token = localStorage.getItem('token');
const loggedIn = typeof token === 'string' && token.length > 0;

const state = {
  user: null,
  token,
  tokenReceivedAt: loggedIn
    ? parseInt(localStorage.getItem('tokenReceivedAt'))
    : 0,
  loggedIn,
};

const getters = {
  user(state) {
    return state.user;
  },

  company() {
    if (!state.user) {
      return null;
    }

    return !state.user.company;
  },

  companyMembers() {
    if (!state.user || !state.user.company) {
      return [];
    }

    return state.user.company.members.sort((a, b) => a['name'].localeCompare(b['name']));
  },

  token(state) {
    return state.token;
  },

  tokenReceivedAt(state) {
    return state.tokenReceivedAt;
  },

  loggedIn(state) {
    return state.loggedIn;
  },
};

const actions = {
  fetch(store) {
    return AuthService.fetch().then((resp) => {
      store.commit('setUser', resp);
      return resp;
    });
  },

  signup(store, { hash, password }) {
    return AuthService.signup(hash, password).then((resp) => {
      store.commit('setToken', resp.access_token);
      store.commit('setUser', resp.user);

      return resp;
    });
  },

  login(store, { email, password }) {
    return AuthService.login(email, password).then((resp) => {
      store.commit('setToken', resp.access_token);
      store.commit('setUser', resp.user);

      return resp;
    });
  },

  refresh(store) {
    if (!store.getters['loggedIn']) {
      return;
    }

    return AuthService.refreshToken().then((resp) => {
      store.commit('setToken', resp.access_token);
    });
  },

  logout(store) {
    return AuthService.logout().then(() => {
      store.commit('logout');
    });
  },
  delete(store, entity) {
    return AuthService.delete(entity).then(
      store.commit('deleteFromList', entity)
    );
  },
  updateUser(store, entity) {
    return AuthService.updateUser(entity);
  },

  saveUser(store, entity) {
    let promise;
    promise = AuthService.createUser(entity);

    return promise.then((resp) => {
      const responsedEntity = new User(resp);

      store.commit('addToList', responsedEntity);

      return responsedEntity;
    });
  },
  updatePassword(store, entity) {
    return AuthService.updatePassword(entity);
  },
  forgottenPassword(store, entity) {
    return AuthService.forgottenPassword(entity);
  },
  resetPassword(store, entity) {
    return AuthService.resetPassword(entity);
  },
};

const mutations = {
  logout(state) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenReceivedAt');
    state.token = null;
    state.tokenReceivedAt = 0;
    state.loggedIn = false;
    state.user = null;

    router.replace('/auth/login');
  },

  setToken(state, accessToken) {
    const now = new Date().getTime();

    localStorage.setItem('token', accessToken);
    localStorage.setItem('tokenReceivedAt', now);
    state.token = accessToken;
    state.tokenReceivedAt = now;
    state.loggedIn = true;
  },

  setUser(state, user) {
    if (!(user instanceof User) && typeof user === 'object') {
      state.user = new User(user);
    } else {
      state.user = user;
    }
  },
  addToList(state, entity) {
    state.user.company.members.push(entity);
  },

  deleteFromList(state, entity) {
    for (var i = 0; i < state.user.company.members.length; i++) {
      if (state.user.company.members[i].id === entity.id) {
        state.user.company.members.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
