var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-sorter" },
    [
      _c("v-select", {
        attrs: {
          options: _vm.options,
          label: "label",
          placeholder: "Rendezés",
        },
        on: { input: _vm.onSelected },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function () {
              return [_vm._v(" Nincsenek rendezhető elemek ")]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.orderBy,
          callback: function ($$v) {
            _vm.orderBy = $$v
          },
          expression: "orderBy",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }