var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "campaigns-view" }, [
    !_vm.isOutsiderUser && !_vm.isPending
      ? _c("div", { staticClass: "top-buttons" }, [
          _c(
            "div",
            { staticClass: "sort-and-search" },
            [
              _c(
                "div",
                { staticClass: "entity-sorter" },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.sortOptions,
                      label: "label",
                      placeholder: "Rendezés",
                      searchable: false,
                    },
                    on: { input: _vm.reRender },
                    model: {
                      value: _vm.orderBy,
                      callback: function ($$v) {
                        _vm.orderBy = $$v
                      },
                      expression: "orderBy",
                    },
                  }),
                ],
                1
              ),
              _c("entity-search", { on: { change: _vm.search } }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: { bordered: _vm.onlyMine },
              attrs: { disabled: _vm.isLoading },
              on: { click: () => (_vm.onlyMine = false) },
            },
            [_vm._v(" Mutasd mindet ")]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              class: { bordered: !_vm.onlyMine },
              attrs: { disabled: _vm.isLoading },
              on: { click: () => (_vm.onlyMine = true) },
            },
            [_vm._v(" Mutasd az én foglalásaimat ")]
          ),
        ])
      : _vm._e(),
    !_vm.isLoading && _vm.entities.length == 0
      ? _c(
          "div",
          { staticClass: "emptyList" },
          [
            _vm._v(" Nincsenek megjeleníthető elemek "),
            !_vm.isPending
              ? _c(
                  "router-link",
                  { staticClass: "button", attrs: { to: "/campaign/new" } },
                  [_vm._v(" Új kampány felvitele ")]
                )
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "list-grid" },
          _vm._l(_vm.entities, function (entity) {
            return _c("campaign-card", {
              key: entity.id,
              attrs: { entity: entity },
              on: { deleted: _vm.onCampaignDeleted },
            })
          }),
          1
        ),
    _vm.isLoading ? _c("div", { staticClass: "loader" }, [_c("i")]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }