var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ad-zone-occupancies-view" },
    [
      _c("occupancy-filter", {
        attrs: { disabled: _vm.isLoading },
        on: { change: _vm.loadNewWeeklyData },
      }),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c("div", { staticClass: "view-type-switch" }, [
            _c(
              "button",
              {
                staticClass: "button",
                class: { bordered: _vm.viewType !== "weekly" },
                attrs: { disabled: _vm.isLoading },
                on: { click: () => _vm.switchViewType("weekly") },
              },
              [_vm._v(" Heti nézet ")]
            ),
            _c(
              "button",
              {
                staticClass: "button",
                class: { bordered: _vm.viewType !== "daily" },
                attrs: { disabled: _vm.isLoading },
                on: { click: () => _vm.switchViewType("daily") },
              },
              [_vm._v(" Napi bontás ")]
            ),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "item" }, [
              _c("i", { staticClass: "icon fire" }),
              _vm._v(" Foglalt felületek "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.occupiedRate)),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("i", { staticClass: "icon info" }),
              _vm._v(" Szabad felületek "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.freeRate)),
              ]),
            ]),
            _c("div", { staticClass: "spacer" }),
            !_vm.isOutsiderUser
              ? _c(
                  "button",
                  { staticClass: "button", on: { click: _vm.exportStat } },
                  [
                    _vm.isExporting
                      ? _c("pulse-loader", {
                          attrs: {
                            loading: _vm.isExporting,
                            color: "#ffffff",
                            size: "4px",
                          },
                        })
                      : [_vm._v(" Export ")],
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm.isLoading
            ? _c("div", { staticClass: "loader" }, [_c("i")])
            : _vm._l(_vm.weeklyData, function (item, key) {
                return _c("ad-zone-occupancy-week-row", {
                  key: "occupancy-row-" + key,
                  attrs: {
                    "week-number": item.week_number,
                    days: item.days,
                    rates: item.rates,
                    "total-forecasted": item.total_forecasted,
                    "total-occupanced": item.total_occupied,
                    flexible: item.flexible,
                    "flexible-unfinalized": item.flexible_unfinalized,
                    unfinalized: item.unfinalized,
                    "has-overfilled-mobile-zone": item.overfilled.mobile,
                    "has-overfilled-desktop-zone": item.overfilled.desktop,
                    "date-flag": _vm.dateFlagByWeek(item),
                  },
                })
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }