<template>
  <card :class="'user-card' + (pending ? ' pending' : '')">
    <div class="circle">
      <div class="circle-txt">{{ getFirstLetters(entity.name) }}</div>
    </div>
    <div class="right">
      <div class="name">{{ entity.name }}</div>
      <div class="email">{{ entity.email }}</div>

      <button v-if="pending" class="button bordered" @click="resendEmail">
        <pulse-loader v-if="isLoadingEmail" color="#000000" size="6px" />
        <template v-else> Meghívó újraküldése</template>
      </button>
      <div v-else>
        <v-select
          label="name"
          :options="roles"
          v-model="entity.roles"
          :disabled="!isSuperUser || isItMe"
          :searchable="false"
          @input="updateUser"
        />
      </div>
      <div class="delete">
        <button
          v-if="isSuperUser && !isItMe"
          class="button bordered"
          @click="showDeleteConfirmUserCard"
        >
          <i class="icon trash"></i>
          {{ submitButtonLabel }}
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import Card from '@/components/Card';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import User from '@/entities/User';

export default {
  name: 'UserCard',
  components: {
    Card,
    PulseLoader,
  },
  props: {
    entity: {
      type: User,
      required: false,
    },
    pending: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isLoadingEmail: false,
  }),
  computed: {
    roles() {
      return this.$store.getters['app/userRoles'];
    },
    isItMe() {
      const currentUser = this.$store.getters['auth/user'];
      return this.entity.id === currentUser.id;
    },
    submitButtonLabel() {
      return this.pending ? 'Meghívás törlése' : 'Csapattag törlése';
    },
  },
  methods: {
    updateUser() {
      const data = {
        id: this.entity.id,
        name: this.entity.name,
        email: this.entity.email,
        role_ids: [this.entity.roles.id],
      };

      this.$store.dispatch('auth/updateUser', data).catch((err) => {
        this.$toast.error(err.message);
      });
    },
    resendEmail() {
      this.isLoadingEmail = true;
      this.$store
        .dispatch('invites/resendInvitation', this.entity)
        .then(() => {
          this.$toast.success('Email újraküldése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isLoadingEmail = false));
    },
    modalName() {
      return 'delete-confirm-user-card' + this.entity.id;
    },
    showDeleteConfirmUserCard() {
      let content =
        'Valóban törölni szeretnéd ' + this.entity.name + ' nevű felhasználót?';
      if (this.pending) {
        content =
          'Valóban törölni szeretnéd ' +
          this.entity.name +
          ' nevű felhasználó meghívását?';
      }

      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteUserCard,
      });
    },
    deleteUserCard() {
      if (!this.pending) {
        return this.$store
          .dispatch('auth/delete', this.entity)
          .then(() => {
            this.$toast.success('Csapattag törlése sikeresen megtörtént');
          })
          .catch((err) => {
            this.$toast.error(err.message);
          });
      } else {
        return this.$store
          .dispatch('invites/deleteInvitation', this.entity)
          .then(() => {
            this.$toast.success('Meghívás törlése sikeresen megtörtént');
          })
          .catch((err) => {
            this.$toast.error(err.message);
          });
      }
    },
    getFirstLetters(str) {
      const firstLetters = str
        .split(' ')
        .map((word) => word[0])
        .join('');
      return firstLetters;
    },
  },
};
</script>
