<template>
  <div class="user-invite-form">
    <div class="user-invite-form-input">
      <div>
        <input type="text" placeholder="Név" v-model="model.name" />
      </div>
      <div>
        <input type="email" placeholder="Email" v-model="model.email" />
      </div>
      <div>
        <v-select
          v-model="model.roles"
          :options="roles"
          placeholder="Felhasználói jogosultság"
          label="name"
        />
      </div>
    </div>
    <div class="actions">
      <button class="button" :disabled="isSubmitDisabledInvite" @click="submit">
        <pulse-loader v-if="isSaving" color="#ffffff" size="6px" />
        <template v-else> Meghívó küldése</template>
      </button>
    </div>
  </div>
</template>

<script>
import User from '@/entities/User';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  name: 'UserInviteForm',
  props: {
    entity: {
      type: User,
      required: true,
    },
  },
  components: {
    PulseLoader,
  },
  data: () => ({
    model: null,
    isSaving: false,
  }),
  computed: {
    isSubmitDisabledInvite() {
      if (
        this.model.name.length == 0 ||
        this.model.email.length == 0 ||
        this.model.role === null
      ) {
        return true;
      }
      return false;
    },
    roles() {
      return this.$store.getters['app/userRoles'];
    },
  },
  created() {
    this.model = new User(this.entity);
  },
  methods: {
    submit() {
      if (this.isSaving || this.isSubmitDisabledInvite) {
        return;
      }

      this.isSaving = true;
      const data = {
        id: this.model.id,
        name: this.model.name,
        email: this.model.email,
        role_id: this.model.roles.id,
      };
      this.$store
        .dispatch('invites/saveUser', data)
        .then(() => {
          this.$router.replace('/users');
          this.$toast.success('Csapattag mentése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
  },
};
</script>
