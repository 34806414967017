<template>
  <div class="back-link">
    <router-link class="back" :to="this.url">
      <i class="icon left-arrow"></i>
      <div class="label">{{ this.label }}</div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'BackLink',
  props: {
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
