<template>
  <div class="card-component">
    <div class="background background--gradient" />
    <div class="background background--color" :style="'background-color:' + color" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    color: {
      type: String,
      required: false,
      default: '#ffffff',
    }
  }
};
</script>
