var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-page signup-form-view" },
    [
      _c("auth-logo"),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Regisztráció")]),
        _vm.isLoading
          ? _c("div", { staticClass: "loader" }, [_c("i")])
          : _vm.invalidLink
          ? _c("div", { staticClass: "invalid-link-text" }, [
              _vm._v(" Érvénytelen meghívó link. "),
            ])
          : _c("div", { staticClass: "credentials" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "email",
                attrs: {
                  type: "email",
                  placeholder: "E-mail cím megadása",
                  readonly: "",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                ],
                staticClass: "name",
                attrs: {
                  type: "text",
                  placeholder: "Név megadása",
                  readonly: "",
                },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.name = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "password",
                attrs: { type: "password", placeholder: "Jelszó" },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordAgain,
                    expression: "passwordAgain",
                  },
                ],
                staticClass: "password",
                attrs: { type: "password", placeholder: "Jelszó megerősítése" },
                domProps: { value: _vm.passwordAgain },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.passwordAgain = $event.target.value
                  },
                },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  " A regisztrációval elfogadom a szolgáltatási feltételeit, és tudomásul veszem az Adatvédelmi irányelveket. "
                ),
              ]),
              _c(
                "button",
                { staticClass: "button", on: { click: _vm.submit } },
                [
                  _vm.isSaving
                    ? _c("pulse-loader", {
                        attrs: {
                          loading: _vm.isSaving,
                          color: "#ffffff",
                          size: "6px",
                        },
                      })
                    : [_vm._v(" Regisztrálok ")],
                ],
                2
              ),
            ]),
      ]),
      _c("div", { staticClass: "footer" }, [
        _vm._m(0),
        _c("div", { staticClass: "text" }, [
          _vm._v(
            " AdbookR | Hirdetés kelező rendszer " +
              _vm._s(_vm.year) +
              " | All right reserved | "
          ),
          _c("a", { attrs: { href: "", target: "_blank" } }, [
            _vm._v("Adatvédelmi szabályzat"),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("i", { staticClass: "icon sk-logo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }