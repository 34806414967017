import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store';

import HomeView from '@/views/HomeView';
import AdZoneOccupanciesView from '@/views/AdZoneOccupanciesView';
import AdZoneFormView from '@/views/AdZoneFormView';
import AdZonesView from '@/views/AdZonesView';
import AdZoneGroupFormView from '@/views/AdZoneGroupFormView';
import AdZoneGroupsView from '@/views/AdZoneGroupsView';
import CampaignsView from '@/views/CampaignsView';
import CampaignFormView from '@/views/CampaignFormView';
import CustomersView from '@/views/CustomersView';
import CustomerFormView from '@/views/CustomerFormView';
import DateFlagsView from '@/views/DateFlagsView';
import DateFlagFormView from '@/views/DateFlagFormView';
import ForgottenPasswordView from '@/views/ForgottenPasswordView';
import LoginFormView from '@/views/LoginFormView';
import SignupFormView from '@/views/SignupFormView';
import SitesView from '@/views/SitesView';
import SiteFormView from '@/views/SiteFormView';
import UserInviteFormView from '@/views/UserInviteFormView';
import UsersView from '@/views/UsersView';
import ProfileView from '@/views/ProfileView';
import ResetPasswordView from '@/views/ResetPasswordView';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth/login',
      name: 'Login',
      component: LoginFormView,
    },
    {
      path: '/auth/signup/:hash',
      name: 'Signup',
      component: SignupFormView,
    },
    {
      path: '/auth/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPasswordView,
    },
    {
      path: '/auth/forgotten-password',
      name: 'ForgottenPassword',
      component: ForgottenPasswordView,
    },
    {
      path: '/',
      name: 'Home',
      component: HomeView,
    },
    {
      path: '/ad-zones',
      name: 'AdZones',
      component: AdZonesView,
    },
    {
      path: '/ad-zone-groups',
      name: 'AdZoneGroups',
      component: AdZoneGroupsView,
    },
    {
      path: '/users',
      name: 'Users',
      component: UsersView,
    },
    {
      path: '/user/invite',
      name: 'UserInvite',
      component: UserInviteFormView,
    },
    {
      path: '/customers',
      name: 'Customers',
      component: CustomersView,
    },
    {
      path: '/customer/:id',
      name: 'Customer',
      component: CustomerFormView,
    },
    {
      path: '/ad-zone/:id',
      name: 'AdZone',
      component: AdZoneFormView,
    },
    {
      path: '/ad-zone-group/:id',
      name: 'AdZoneGroup',
      component: AdZoneGroupFormView,
    },
    {
      path: '/sites',
      name: 'Sites',
      component: SitesView,
    },
    {
      path: '/site/:id',
      name: 'Site',
      component: SiteFormView,
    },
    {
      path: '/ad-zone-occupancies',
      name: 'AdZoneOccupancies',
      component: AdZoneOccupanciesView,
    },
    {
      path: '/campaigns',
      name: 'Campaigns',
      component: CampaignsView,
    },
    {
      path: '/campaigns/past',
      name: 'Campaigns',
      component: CampaignsView,
      meta: {
        past: true,
      },
    },
    {
      path: '/campaigns/pending',
      name: 'Campaigns',
      component: CampaignsView,
      meta: {
        pending: true,
      },
    },
    {
      path: '/campaign/:id',
      name: 'Campaign',
      component: CampaignFormView,
    },
    {
      path: '/flagged-dates',
      name: 'DateFlags',
      component: DateFlagsView,
    },
    {
      path: '/date-flag/:id',
      name: 'DateFlag',
      component: DateFlagFormView,
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfileView,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const publicViewNames = [
    'Login',
    'Signup',
    'ForgottenPassword',
    'ResetPassword',
  ];

  const loggedIn = store.state.auth.loggedIn;
  const toPublicView = publicViewNames.includes(to.name);

  if (!loggedIn && !toPublicView) {
    return next('/auth/login');
  }
  if (loggedIn && toPublicView) {
    return next('/');
  }

  next();
});
