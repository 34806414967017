export default class DateFlag {
  id = null;
  name = '';
  date_from = null;
  date_to = null;
  flag = '';
  color = '#000000';
  created_at = new Date();

  constructor(rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if (
        (key.indexOf('date') !== -1 || key === 'created_at') &&
        !!value
      ) {
        this[key] = new Date(value);
      }
      else {
        this[key] = value;
      }
    }
  }
}
