import Company from '@/entities/Company';
import UserRole from '@/entities/UserRole';

export default class User {
  id = null;
  name = '';
  email = '';
  company = null;
  roles = [];
  created_at = new Date();

  constructor(rawData) {
    if (Object.prototype.toString.call(rawData) !== '[object Object]') {
      return;
    }

    this.updateWith(rawData);
  }

  get isNew() {
    return this.id === null;
  }

  get isSuperadmin() {
    return (
      this.roles.filter((i) => i.machine_name === 'superadmin').length !== 0
    );
  }

  get isOutsider() {
    return (
      this.roles.filter((i) => i.machine_name === 'outsider').length !== 0
    );
  }

  updateWith(newValues) {
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof this[key] === 'undefined') {
        continue;
      }

      if (key === 'company' && !!value) {
        this[key] = new Company(value);
      }
      else if (key === 'roles') {
        this[key] = [];

        for (let item of value) {
          const entity = new UserRole(item);
          this[key].push(entity);
        }
      }
      else if (key === 'created_at' && !!value) {
        this[key] = new Date(value);
      }
      else {
        this[key] = value;
      }
    }
  }
}
