var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-invite-form" }, [
    _c("div", { staticClass: "user-invite-form-input" }, [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.name,
              expression: "model.name",
            },
          ],
          attrs: { type: "text", placeholder: "Név" },
          domProps: { value: _vm.model.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "name", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.email,
              expression: "model.email",
            },
          ],
          attrs: { type: "email", placeholder: "Email" },
          domProps: { value: _vm.model.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "email", $event.target.value)
            },
          },
        }),
      ]),
      _c(
        "div",
        [
          _c("v-select", {
            attrs: {
              options: _vm.roles,
              placeholder: "Felhasználói jogosultság",
              label: "name",
            },
            model: {
              value: _vm.model.roles,
              callback: function ($$v) {
                _vm.$set(_vm.model, "roles", $$v)
              },
              expression: "model.roles",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: _vm.isSubmitDisabledInvite },
          on: { click: _vm.submit },
        },
        [
          _vm.isSaving
            ? _c("pulse-loader", { attrs: { color: "#ffffff", size: "6px" } })
            : [_vm._v(" Meghívó küldése")],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }