var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      _c(
        "div",
        { staticClass: "zone-main" },
        [
          _c("div", { staticClass: "zone", on: { click: _vm.toggle } }, [
            !_vm.isOutsiderUser
              ? _c("i", { staticClass: "icon dropdown-arrow" })
              : _vm._e(),
            _c("div", {
              staticClass: "zone-color",
              style: "background-color: " + _vm.zone.color,
            }),
            _c("div", { staticClass: "zone-name" }, [
              _vm._v(" " + _vm._s(_vm.zone.name) + " "),
              _vm.isOverfilled
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.overfilledTooltipContent,
                        expression: "overfilledTooltipContent",
                      },
                    ],
                    staticClass: "icon warning",
                  })
                : _vm._e(),
            ]),
          ]),
          _c("progress-bar", { attrs: { value: _vm.occupancedRate } }),
          _c("div", { staticClass: "oppupancy-rate" }, [
            _vm._v(_vm._s(_vm.occupancedRate) + "% foglaltság"),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.mainOccupancyTooltip,
                  expression: "mainOccupancyTooltip",
                },
              ],
              staticClass: "oppupanced-av",
            },
            [
              _vm._v(
                " Foglalt AV: " +
                  _vm._s(_vm.formattedNumber(_vm.occupiedTotal)) +
                  " "
              ),
              _c("i", { staticClass: "icon info" }),
            ]
          ),
          _vm.customers.length !== 0 && !_vm.isOutsiderUser
            ? _c(
                "div",
                { staticClass: "customers" },
                _vm._l(_vm.customers, function (customer, key) {
                  return _c(
                    "a",
                    {
                      key: "customer-" + key,
                      attrs: {
                        href: "/customer/" + customer.id,
                        target: "_blank",
                      },
                    },
                    [_vm._v(" " + _vm._s(customer.name) + " ")]
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c("transition-expand", [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "campaign-rows" },
              _vm._l(_vm.campaigns, function (campaign) {
                return _c(
                  "div",
                  {
                    key: "campaign--" + campaign.id,
                    staticClass: "campaign-row",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "campaign-name",
                        attrs: {
                          href: "/campaign/" + campaign.id,
                          target: "_blank",
                        },
                      },
                      [
                        campaign.flexible
                          ? _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Rugalmas",
                                  expression: "'Rugalmas'",
                                },
                              ],
                              staticClass: "icon flexible",
                            })
                          : _vm._e(),
                        campaign.unfinalized
                          ? _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Ceruzás",
                                  expression: "'Ceruzás'",
                                },
                              ],
                              staticClass: "icon unfinalized",
                            })
                          : _vm._e(),
                        !campaign.flexible && !campaign.unfinalized
                          ? _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Végleges",
                                  expression: "'Végleges'",
                                },
                              ],
                              staticClass: "icon finalized",
                            })
                          : _vm._e(),
                        _vm._v(
                          " [" +
                            _vm._s(campaign.customer.name) +
                            "] " +
                            _vm._s(campaign.name) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "type" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.campaignTypeHumanLabel(campaign.type)) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.campaignOccupancyTooltip(campaign),
                            expression: "campaignOccupancyTooltip(campaign)",
                          },
                        ],
                        staticClass: "oppupanced-av",
                      },
                      [
                        _vm._v(
                          " Foglalt AV: " +
                            _vm._s(
                              _vm.formattedNumber(campaign.occupied.total)
                            ) +
                            " "
                        ),
                        _c("i", { staticClass: "icon info" }),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }