<template>
  <div class="campaigns-view">
    <div v-if="!isOutsiderUser && !isPending" class="top-buttons">
      <div class="sort-and-search">
        <div class="entity-sorter">
          <v-select
            v-model="orderBy"
            :options="sortOptions"
            label="label"
            placeholder="Rendezés"
            :searchable="false"
            @input="reRender"
          />
        </div>
        <entity-search @change="search" />
      </div>
      <button
        class="button"
        :class="{ bordered: onlyMine }"
        :disabled="isLoading"
        @click="() => onlyMine = false"
      >
        Mutasd mindet
      </button>
      <button
        class="button"
        :class="{ bordered: !onlyMine }"
        :disabled="isLoading"
        @click="() => onlyMine = true"
      >
        Mutasd az én foglalásaimat
      </button>
    </div>
    <div class="emptyList" v-if="!isLoading && entities.length == 0">
      Nincsenek megjeleníthető elemek
      <router-link v-if="!isPending" class="button" :to="'/campaign/new'">
        Új kampány felvitele
      </router-link>
    </div>
    <div class="list-grid" v-else>
      <campaign-card
        v-for="entity in entities"
        :key="entity.id"
        :entity="entity"
        @deleted="onCampaignDeleted"
      />
    </div>
    <div v-if="isLoading" class="loader">
      <i />
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import CampaignCard from '@/components/CampaignCard';
import EntitySearch from '@/components/EntitySearch';
import CampaignService from '@/services/Campaign';
import Campaign from '@/entities/Campaign';

export default {
  name: 'CampaignsView',
  components: {
    CampaignCard,
    EntitySearch,
  },
  data: () => ({
    entities: [],
    page: 0,
    limit: 20,
    hasMore: true,
    isLoading: false,
    onlyMine: null,
    orderBy: null,
    term: '',
  }),
  computed: {
    userId() {
      return this.$store.getters['auth/user'].id;
    },
    isPast() {
      return this.$route.meta.past;
    },
    isPending() {
      return this.$route.meta.pending;
    },
    sortOptions() {
      let options = [
        { label: 'Hamarosan indulók elől', property: 'date_from', order: 'asc' },
        { label: 'Mostanában szerkesztettek elől', property: 'updated_at', order: 'desc' },
        { label: 'Később indulók elől', property: 'date_from', order: 'desc' },
        { label: 'Újonnan létrehozottak elől', property: 'created_at', order: 'desc' },
        { label: 'Régebben létrehozottak elől', property: 'created_at', order: 'asc' },
        { label: 'ABC szerint növekvő', property: 'name', order: 'asc' },
        { label: 'ABC szerint csökkenő', property: 'name', order: 'desc' },
      ];

      if (this.isPast) {
        options.splice(0, 1);
      }

      return options;
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.orderBy = this.sortOptions[this.isPast ? 1 : 0];
          this.reRender();
        });
      },
    },
  },
  created() {
    this.orderBy = this.sortOptions[this.isPast ? 1 : 0];
    this.onlyMine = document.location.hash === '#only-mine' || this.isOutsiderUser;
  },
  methods: {
    onCampaignDeleted(entity) {
      for (var i = 0; i < this.entities.length; i++) {
        if (this.entities[i].id === entity.id) {
          this.entities.splice(i, 1);
        }
      }
    },
    reRender() {
      this.reset();
      this.loadCampaigns();
    },
    reset() {
      this.page = 0;
      this.hasMore = true;
      this.entities = [];
    },
    search(term) {
      this.term = term;
      this.reRender();
    },
    loadCampaigns() {
      this.isLoading = true;
      const queryOptions = {
        page: this.page,
        limit: this.limit,
        user_id: this.onlyMine ? this.userId : '',
        order_by: this.orderBy.property,
        sort: this.orderBy.order,
        term: this.term,
      };
      if (this.isPast) {
        queryOptions['before'] = moment().format('YYYY-MM-DD');
      }
      else if (this.orderBy.property === 'date_from' && this.orderBy.order === 'asc') {
        queryOptions['after'] = moment().format('YYYY-MM-DD');
      }

      if (this.isPending) {
        queryOptions['approved'] = 0;
      }
      else if (!this.isOutsiderUser) {
        queryOptions['approved'] = 1;
      }

      CampaignService.getList(queryOptions)
        .then((result) => {
          this.hasMore = result.length === this.limit;

          for (let campaign of result) {
            if (campaign instanceof Campaign) {
              this.entities.push(campaign);
            } else {
              this.entities.push(new Campaign(campaign));
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.infiniteScroll());
          }
      });
    },
    infiniteScroll() {
      if (!this.hasMore) {
        return;
      }

      const cards = document.querySelectorAll('.card-component');
      if (cards.length === 0) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.page += 1;
          this.loadCampaigns();
          observer.unobserve(entry[0].target);
        }
      });
      observer.observe(cards[cards.length - 1]);
    },
  },
};
</script>
