var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-form" }, [
    _c("div", { staticClass: "customer-form-input" }, [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.name,
              expression: "model.name",
            },
          ],
          attrs: { type: "text", placeholder: "Cégnév" },
          domProps: { value: _vm.model.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "name", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.contact_name,
              expression: "model.contact_name",
            },
          ],
          attrs: { type: "text", placeholder: "Kapcsolattartó név" },
          domProps: { value: _vm.model.contact_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "contact_name", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.contact_email,
              expression: "model.contact_email",
            },
          ],
          attrs: { type: "email", placeholder: "Kapcsolattartó email" },
          domProps: { value: _vm.model.contact_email },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "contact_email", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.contact_phone,
              expression: "model.contact_phone",
            },
          ],
          attrs: { type: "text", placeholder: "Kapcsolattartó telefonszám" },
          domProps: { value: _vm.model.contact_phone },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "contact_phone", $event.target.value)
            },
          },
        }),
      ]),
      _c(
        "div",
        [
          _c("v-select", {
            attrs: {
              options: _vm.users,
              label: "name",
              placeholder: "Értékesítő",
            },
            model: {
              value: _vm.model.salesman,
              callback: function ($$v) {
                _vm.$set(_vm.model, "salesman", $$v)
              },
              expression: "model.salesman",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-select", {
            attrs: {
              options: _vm.users,
              label: "name",
              placeholder: "Kampánymenedzser",
            },
            model: {
              value: _vm.model.manager,
              callback: function ($$v) {
                _vm.$set(_vm.model, "manager", $$v)
              },
              expression: "model.manager",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: _vm.isSubmitDisabled },
          on: { click: _vm.submit },
        },
        [
          _vm.isSaving
            ? _c("pulse-loader", { attrs: { color: "#ffffff", size: "6px" } })
            : [_vm._v(" " + _vm._s(_vm.submitButtonLabel) + " ")],
        ],
        2
      ),
      _vm.showDeleteButton
        ? _c(
            "button",
            {
              staticClass: "button clean",
              on: { click: _vm.showDeleteConfirmCustomer },
            },
            [_vm._v(" Ügyfél törlése ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }