import Api from '@/services/Api';

export default {
  getList(params) {
    let path = '/ad-zones';
    if (params) {
      path += '?' + new URLSearchParams(params).toString();
    }

    return Api()
      .get(path)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  create(entity) {
    const data = {
      site_id: entity.site.id,
      name: entity.name,
      ad_manager_code: entity.ad_manager_code,
      mobile: entity.mobile,
      desktop: entity.desktop,
      color: entity.color,
      similar: entity.similar,
    };

    return Api()
      .post('/ad-zone/new', data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },

  update(entity) {
    const data = {
      site_id: entity.site.id,
      name: entity.name,
      ad_manager_code: entity.ad_manager_code,
      mobile: entity.mobile,
      desktop: entity.desktop,
      color: entity.color,
    };

    return Api()
      .put('/ad-zone/' + entity.id, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  deletable(entity) {
    return Api()
      .get('/ad-zone/' + entity.id + '/deletable')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  delete(entity) {
    return Api()
      .delete('/ad-zone/' + entity.id)
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
  getInventory() {
    return Api()
      .get('/ad-zones/inventory')
      .then((resp) => {
        if (resp instanceof Error) {
          throw resp;
        }
        return resp;
      });
  },
};
