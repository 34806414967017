export default {
  created() {
    if (!this.isOutsiderUser) {
      return;
    }

    const availableForOutsiders = [
      'AdZoneOccupancies',
      'Campaign',
      'Campaigns',
      'Customers',
      'Customer',
      'Profile',
    ];

    if (!availableForOutsiders.includes(this.$route.name)) {
      this.$router.replace('/ad-zone-occupancies');
    }
  },
  computed: {
    isSuperUser() {
      const currentUser = this.$store.getters['auth/user'];
      return currentUser.isSuperadmin;
    },
    isOutsiderUser() {
      const currentUser = this.$store.getters['auth/user'];
      return currentUser && currentUser.isOutsider;
    },
  }
}
