<template>
  <div class="occupancy-filter-component">
    <div class="info">
      <div class="item">
        <i class="icon finalized" />
        Foglalt
      </div>
      <div class="item">
        <i class="icon flexible" />
        Rugalmas
      </div>
      <div class="item">
        <i class="icon unfinalized" />
        Ceruzás
      </div>
    </div>

    <div class="checkboxes">
      <label>
        <input v-model="mobileFilter" type="checkbox" value="1" :disabled="disabled" />
        Mobil
      </label>
      <label>
        <input v-model="desktopFilter" type="checkbox" value="1" :disabled="disabled" />
        Desktop
      </label>
    </div>

    <div class="horizontal-line" />

    <div class="label">Dátum</div>
    <input
      ref="dateInput"
      v-model="dateInputValue"
      type="text"
      class="date-input"
      :disabled="disabled || isOutsiderUser"
      @focus="onFocusDateInput"
      @blur="onBlurDateInput"
    />

    <functional-calendar
      v-show="calendarIsVisible && !disabled"
      ref="calendar"
      v-model="calendarData"
      :configs="calendarConfigs"
      class="calendar"
      @click.native="onClickCalendar"
    >
      <template #footer>
        <button class="button button-ok">
          Rendben
        </button>
      </template>
    </functional-calendar>

    <template v-if="!isOutsiderUser">
      <div class="horizontal-line" />
      <div class="label">Keresés a kampányok között</div>
      <input v-model="termFilter" type="text" :disabled="disabled" />
    </template>

    <div class="horizontal-line" />

    <div class="label">Oldal</div>
    <v-select
      v-model="siteFilter"
      :options="sites"
      label="name"
      placeholder=""
      multiple
      :disabled="disabled"
    >
      <template #no-options>
        Nincsenek kiválasztható elemek.
      </template>
    </v-select>

    <div class="horizontal-line" />
    <div class="label">Zóna</div>
    <v-select
      v-model="adZoneFilter"
      :options="adZones"
      label="name"
      placeholder=""
      multiple
      :disabled="disabled"
    >
      <template #no-options>
        Nincsenek kiválasztható elemek.
      </template>
    </v-select>

    <template v-if="!isOutsiderUser">
      <div class="horizontal-line" />

      <div class="label">Értékesítő</div>
      <v-select
        v-model="salesmanFilter"
        :options="companyMembers"
        label="name"
        placeholder=""
        multiple
        :disabled="disabled"
      >
        <template #no-options>
          Nincsenek kiválasztható elemek.
        </template>
      </v-select>

      <div class="label">Kampánymenedzser</div>
      <v-select
        v-model="managerFilter"
        :options="companyMembers"
        label="name"
        placeholder=""
        multiple
      >
        <template #no-options>
          Nincsenek kiválasztható elemek.
        </template>
      </v-select>

      <div class="horizontal-line" />

      <div class="label">Ügyfél</div>
      <v-select
        v-model="customerFilter"
        :options="customers"
        label="name"
        placeholder=""
        multiple
        :disabled="disabled"
      >
        <template #no-options>
          Nincsenek kiválasztható elemek.
        </template>
      </v-select>
    </template>

    <template v-if="isSuperUser">
      <div class="horizontal-line" />
      <label class="checkbox-wrapper">
        <input type="checkbox" v-model="onlyApprovedCampaigns"> Csak jóváhagyott kampányok
      </label>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';

import { range as dateRangeFormatter, formatForCalendar } from '@/utils/dateFormatter';

export default {
  name: 'OccupancyFilter',
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.setCalendarInitDates();
  },
  data() {
    return {
      dateInputValue: '',
      calendarIsVisible: false,
      calendarHiddingTimer: null,
      calendarData: {},
      calendarConfigs: {
        sundayStart: false,
        isDateRange: true,
        isDatePicker: false,
        markedDateRange: {
          start: null,
          end: null,
        },
        monthNames: this.$months,
        dayNames: this.$shortDays,
      },
    };
  },
  computed: {
    termFilter: {
      get() {
        return this.$store.getters['occupancyStat/termFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setTermFilter', value);
      },
    },
    siteFilter: {
      get() {
        return this.$store.getters['occupancyStat/siteFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setSiteFilter', value);
      },
    },
    adZoneFilter: {
      get() {
        return this.$store.getters['occupancyStat/adZoneFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setAdZoneFilter', value);
      },
    },
    salesmanFilter: {
      get() {
        return this.$store.getters['occupancyStat/salesmanFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setSalesmanFilter', value);
      },
    },
    managerFilter: {
      get() {
        return this.$store.getters['occupancyStat/managerFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setManagerFilter', value);
      },
    },
    customerFilter: {
      get() {
        return this.$store.getters['occupancyStat/customerFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setCustomerFilter', value);
      },
    },
    mobileFilter: {
      get() {
        return this.$store.getters['occupancyStat/mobileFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setMobileFilter', value);
      },
    },
    desktopFilter: {
      get() {
        return this.$store.getters['occupancyStat/desktopFilter'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setDesktopFilter', value);
      },
    },
    onlyApprovedCampaigns: {
      get() {
        return this.$store.getters['occupancyStat/onlyApprovedCampaigns'];
      },
      set(value) {
        this.$store.commit('occupancyStat/setOnlyApprovedCampaigns', value);
      },
    },
    sites() {
      return this.$store.getters['site/list'];
    },
    adZones() {
      const zones = this.$store.getters['adZone/list'];
      if (this.siteFilter.length === 0) {
        return zones;
      }

      let result = [];

      for (let zone of zones) {
        if (zone.site && this.siteFilter.map(i => i.id).includes(zone.site.id)) {
          result.push(zone);
        }
      }

      return result;
    },
    customers() {
      return this.$store.getters['customer/list'];
    },
    companyMembers() {
      return this.$store.getters['auth/companyMembers'];
    },
  },
  watch: {
    termFilter() {
      this.onChangeFilter();
    },
    siteFilter() {
      this.onChangeFilter();
    },
    adZoneFilter() {
      this.onChangeFilter();
    },
    customerFilter() {
      this.onChangeFilter();
    },
    salesmanFilter() {
      this.onChangeFilter();
    },
    managerFilter() {
      this.onChangeFilter();
    },
    mobileFilter() {
      this.onChangeFilter();
    },
    desktopFilter() {
      this.onChangeFilter();
    },
    onlyApprovedCampaigns() {
      this.onChangeFilter();
    },
    calendarData: {
      deep: true,
      handler() {
        let from, to;
        if (this.calendarData.dateRange.start) {
          from = moment(this.calendarData.dateRange.start, 'DD/MM/YYYY').toDate();
        }
        if (this.calendarData.dateRange.end) {
          to = moment(this.calendarData.dateRange.end, 'DD/MM/YYYY').toDate();
        }

        this.$store.commit('occupancyStat/setDateFrom', from);
        this.$store.commit('occupancyStat/setDateTo', to);

        this.dateInputValue = dateRangeFormatter(from, to);
      },
    },
  },
  methods: {
    onChangeFilter: _.debounce(function () {
      this.$emit('change');
    }, 600),
    setCalendarInitDates() {
      let from = this.$store.getters['occupancyStat/dateFrom'];
      let to = this.$store.getters['occupancyStat/dateTo'];

      if (!from && !to) {
        const now = new Date();
        const thisMonday = new Date(new Date().setDate(now.getDate() - now.getDay() + 1));

        const defaultVisibleDays = this.isOutsiderUser ? (6 + 1)*7 : 89;

        const plusThreeMonth = new Date(thisMonday.getTime() + 86400 * 1000 * defaultVisibleDays);
        plusThreeMonth.setDate(plusThreeMonth.getDate() - plusThreeMonth.getDay()); // Step to last sunday.

        from = thisMonday;
        to = plusThreeMonth;
      }

      this.calendarData.dateRange = this.calendarData.dateRange || {};
      this.calendarData.dateRange.start = formatForCalendar(from);
      this.calendarData.dateRange.end = formatForCalendar(to);
      this.calendarData = Object.assign({}, this.calendarData);
    },
    onFocusDateInput() {
      if (this.calendarHiddingTimer !== null) {
        clearTimeout(this.calendarHiddingTimer);
        this.calendarHiddingTimer = null;
      }

      this.calendarIsVisible = true;
    },
    onBlurDateInput() {
      this.calendarHiddingTimer = setTimeout(() => {
        this.calendarIsVisible = false;
        this.onChangeFilter();
      }, 200);
    },
    onClickCalendar(e) {
      if (e.target.classList.contains('button-ok')) {
        this.calendarIsVisible = false;
        this.onChangeFilter();
        return;
      }

      this.$refs.dateInput.focus();
    },
  }
}
</script>
