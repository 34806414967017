var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "date-flag-form-component" }, [
    _c(
      "div",
      { staticClass: "form-input" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.name,
              expression: "model.name",
            },
          ],
          attrs: { type: "text", placeholder: "Megnevezés" },
          domProps: { value: _vm.model.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.model, "name", $event.target.value)
            },
          },
        }),
        _c("v-select", {
          attrs: {
            options: _vm.$dateFlags,
            reduce: (item) => item.machine_name,
            searchable: false,
            placeholder: "Kiemelés típusa",
          },
          model: {
            value: _vm.model.flag,
            callback: function ($$v) {
              _vm.$set(_vm.model, "flag", $$v)
            },
            expression: "model.flag",
          },
        }),
        _c("color-picker", {
          staticClass: "color-picker",
          on: { input: _vm.onColorSelected },
          model: {
            value: _vm.model.color,
            callback: function ($$v) {
              _vm.$set(_vm.model, "color", $$v)
            },
            expression: "model.color",
          },
        }),
        _vm._m(0),
        _c("functional-calendar", {
          ref: "calendar",
          staticClass: "calendar",
          attrs: { configs: _vm.calendarConfigs },
          model: {
            value: _vm.calendarData,
            callback: function ($$v) {
              _vm.calendarData = $$v
            },
            expression: "calendarData",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "actions" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { disabled: _vm.isSubmitDisabled },
          on: { click: _vm.submit },
        },
        [
          _vm.isSaving
            ? _c("pulse-loader", { attrs: { color: "#ffffff", size: "6px" } })
            : [_vm._v(" " + _vm._s(_vm.submitButtonLabel) + " ")],
        ],
        2
      ),
      _vm.showDeleteButton
        ? _c(
            "button",
            {
              staticClass: "button clean",
              on: { click: _vm.showDeleteConfirm },
            },
            [_vm._v(" Időszak törlése ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calendar-title" }, [
      _c("label", [_vm._v("Időszak kiválasztása")]),
      _c("i", { staticClass: "icon calendar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }