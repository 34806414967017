<template>
  <div :class="wrapperClasses">
    <div class="main">
      <div class="date" @click="toggle">
        <div class="color-overlay" :style="'background: ' + color" />
        <div class="content">
          <i class="icon dropdown-arrow" />
          <div class="label">
            <div class="text">
              {{ dateString }}<br/>
              {{ dayName }}
            </div>
            <i
              v-if="hasOverfilledZone"
              v-tooltip="overfilledTooltipContent"
              class="icon warning"
              />
          </div>
        </div>
      </div>

      <div class="info-items">
        <div class="info">
          {{ formattedOccupancedAvNumber }} AV
        </div>

        <div class="info">
          <i class="icon fire" />
          Foglalt felületek: {{ occupancedRateString }}
        </div>

        <div class="info">
          <i class="icon info" />
          Szabad felületek: {{ freeRateString }}
        </div>
      </div>
    </div>

    <transition-expand>
      <div v-if="isOpen" class="zone-rows">
        <div v-if="isLoading" class="loader">
          <i />
        </div>
        <template v-else-if="filteredZones.length !== 0">
          <span
            v-if="!isOutsiderUser"
            class="toggle-all"
            @click="isAllZoneOpen = !isAllZoneOpen"
            v-html="isAllZoneOpen ? 'Összes becsukása' : 'Összes kinyitása'"
          />
          <ad-zone-occupancy-zone-row
            v-for="(item, key) in filteredZones"
            :key="'zone-row-' + key"
            :zone="item.zone"
            :forecasted-mobile="item.forecast.mobile"
            :forecasted-desktop="item.forecast.desktop"
            :occupied-mobile="item.occupied.mobile"
            :occupied-desktop="item.occupied.desktop"
            :flexible="item.flexible"
            :unfinalized="item.unfinalized"
            :campaigns="item.campaigns"
            :open="isAllZoneOpen"
          />
        </template>
        <div v-else class="empty-text">
          Erre a napra nem találtunk a beállított feltételeknek megfelelő foglalást.
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import OccupancyColorMixin from '@/mixins/occupancyColor';
import StatMixin from '@/mixins/stat';

import AdZoneOccupancyZoneRow from '@/components/AdZoneOccupancyZoneRow';
import TransitionExpand from '@/components/TransitionExpand';

import AdZone from '@/entities/AdZone';
import StatService from '@/services/Stat';

export default {
  name: 'AdZoneOccupancyWeekRow',
  mixins: [ OccupancyColorMixin, StatMixin ],
  components: {
    AdZoneOccupancyZoneRow,
    TransitionExpand,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    rates: {
      type: Object,
      required: true
    },
    totalForecasted: {
      type: Number,
      required: false,
      default: 0,
    },
    totalOccupanced: {
      type: Number,
      required: false,
      default: 0,
    },
    flexible: {
      type: Number,
      required: false,
      default: 0,
    },
    unfinalized: {
      type: Number,
      required: false,
      default: 0,
    },
    hasOverfilledMobileZone: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hasOverfilledDesktopZone: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    isLoading: false,
    isOpen: false,
    isAllZoneOpen: false,
    zones: null,
  }),
  computed: {
    wrapperClasses() {
      return {
        'ad-zone-occupancy-day-row-component': true,
        'is-open': this.isOpen,
      };
    },
    dateString() {
      return this.$months[this.date.getMonth()] + ' ' + this.date.getDate() + '.';
    },
    dayName() {
      return this.$days[this.date.getDay()];
    },
    formattedOccupancedAvNumber() {
      const numberFormatter = new Intl.NumberFormat('hu-HU');
      return numberFormatter.format(Math.floor(this.totalOccupanced));
    },
    occupancedRate() {
      if (!this.totalForecasted) {
        return 0;
      }

      return Math.floor(this.totalOccupanced / this.totalForecasted * 100);
    },
    occupancedRateString() {
      return this.totalOccupancedRate >= 0.01 ? (this.totalOccupancedRate * 100).toFixed(0) + '%' : '-';
    },
    freeRate() {
      if (!this.totalForecasted || this.totalOccupancedRate > 1) {
        return 0;
      }

      return 1 - this.totalOccupancedRate;
    },
    freeRateString() {
      return this.freeRate >= 0.01 ? (this.freeRate * 100).toFixed(0) + '%' : '-';
    },
    filteredZones() {
      return this.zones.filter(i => i.occupied.total > 0);
    },
    hasOverfilledZone() {
      return this.hasOverfilledMobileZone || this.hasOverfilledDesktopZone;
    },
    overfilledTooltipContent() {
      let parts = [];
      if (this.hasOverfilledMobileZone) {
        parts.push('mobil');
      }
      if (this.hasOverfilledDesktopZone) {
        parts.push('desktop');
      }

      return 'Túlfoglalt ' + parts.join(' & ');
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;

      if (!this.isOpen || this.isLoading || !!this.zones) {
        return;
      }

      this.isLoading = true;

      StatService.getZones({
        dateFrom: this.date,
        term: this.termFilter,
        adZones: this.zoneFilter,
        sites: this.siteFilter,
        salesman: this.salesmanFilter,
        managers: this.managerFilter,
        customers: this.customerFilter,
        mobile: this.mobileFilter,
        desktop: this.desktopFilter,
        ignoreOnlyApprovedFilter: !this.onlyApprovedCampaigns,
      })
        .then(resp => {
          this.zones = resp.zones;

          for (let key in this.zones) {
            this.zones[key]['zone'] = new AdZone(this.zones[key]['zone']);
          }
        })
        .catch(e => {
          console.error(e);
          this.$toast.error('Hiba történt az adatok betöltése közben');
        })
        .finally(() => this.isLoading = false);
    },
  }
}
</script>
