var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.weekTooltip,
                expression: "weekTooltip",
              },
            ],
            staticClass: "week",
            style: _vm.weekStyle,
            on: { click: _vm.toggle },
          },
          [
            _c("i", { staticClass: "icon dropdown-arrow" }),
            _c("div", { staticClass: "label" }, [
              _c("div", { staticClass: "week-number" }, [
                _vm._v(" " + _vm._s(_vm.weekNumber) + ". hét "),
              ]),
              _vm.hasOverfilledZone
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.overfilledTooltipContent,
                        expression: "overfilledTooltipContent",
                      },
                    ],
                    staticClass: "icon warning",
                  })
                : _vm._e(),
              _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.date))]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.tooltipContent,
                expression: "tooltipContent",
              },
            ],
            staticClass: "bar",
            on: { click: _vm.toggle },
          },
          [
            _vm.finalizedBarWidth > 0
              ? _c("div", {
                  staticClass: "finalized",
                  style: "width: " + _vm.finalizedBarWidth + "%",
                })
              : _vm._e(),
            _vm.flexibleBarWidth > 0
              ? _c("div", {
                  staticClass: "flexible",
                  style: "width: " + _vm.flexibleBarWidth + "%",
                })
              : _vm._e(),
            _vm.flexibleUnfinalizedBarWidth > 0
              ? _c("div", {
                  staticClass: "flexible-unfinalized",
                  style: "width: " + _vm.flexibleUnfinalizedBarWidth + "%",
                })
              : _vm._e(),
            _vm.unfinalizedBarWidth > 0
              ? _c("div", {
                  staticClass: "unfinalized",
                  style: "width: " + _vm.unfinalizedBarWidth + "%",
                })
              : _vm._e(),
            _vm.colorOverlayBarWidth > 0
              ? _c("div", {
                  staticClass: "color-overlay",
                  style:
                    "width: " +
                    _vm.colorOverlayBarWidth +
                    "%; background: " +
                    _vm.color +
                    ";",
                })
              : _vm._e(),
          ]
        ),
      ]),
      _c("transition-expand", [
        _vm.isOpen
          ? _c(
              "div",
              { class: _vm.tableSubItemWrapperClasses },
              [
                _vm.viewType === "daily"
                  ? _vm._l(_vm.days, function (item, key) {
                      return _c("ad-zone-occupancy-day-row", {
                        key: "day-row-" + key,
                        attrs: {
                          date: new Date(item.date),
                          rates: item.rates,
                          "total-forecasted": item.total_forecasted,
                          "total-occupanced": item.total_occupied,
                          flexible: item.flexible,
                          unfinalized: item.unfinalized,
                          "has-overfilled-mobile-zone": item.overfilled.mobile,
                          "has-overfilled-desktop-zone":
                            item.overfilled.desktop,
                        },
                      })
                    })
                  : _vm._e(),
                _vm.viewType === "weekly"
                  ? [
                      _vm.isLoading
                        ? _c("div", { staticClass: "loader" }, [_c("i")])
                        : _vm.zones.length !== 0
                        ? [
                            !_vm.isOutsiderUser
                              ? _c("span", {
                                  staticClass: "toggle-all",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.isAllZoneOpen
                                        ? "Összes becsukása"
                                        : "Összes kinyitása"
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.isAllZoneOpen = !_vm.isAllZoneOpen
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._l(_vm.zones, function (item, key) {
                              return _c("ad-zone-occupancy-zone-row", {
                                key: "zone-row-" + key,
                                attrs: {
                                  zone: item.zone,
                                  "forecasted-mobile": item.forecast.mobile,
                                  "forecasted-desktop": item.forecast.desktop,
                                  "occupied-mobile": item.occupied.mobile,
                                  "occupied-desktop": item.occupied.desktop,
                                  flexible: item.flexible,
                                  unfinalized: item.unfinalized,
                                  campaigns: item.campaigns,
                                  open: _vm.isAllZoneOpen,
                                },
                              })
                            }),
                          ]
                        : _c("div", { staticClass: "empty-text" }, [
                            _vm._v(
                              " Erre a hétre nem találtunk a beállított feltételeknek megfelelő foglalást. "
                            ),
                          ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }