<template>
  <div class="ad-zone-group-form">
    <input
      type="text"
      placeholder="Zónaszett neve"
      v-model="model.name"
    />

    <v-select
      multiple
      :options="adZones"
      label="name"
      v-model="model.ad_zones"
      placeholder="Zónák"
    >
      <template #no-options>
        Nincsenek kiválasztható elemek.
      </template>
    </v-select>

    <div class="actions">
      <button class="button" @click="submit">
        <pulse-loader
          v-if="isSaving"
          :loading="isSaving"
          color="#ffffff"
          size="6px"
        />
        <template v-else> {{ submitButtonLabel }} </template>
      </button>

      <button
        v-if="showDeleteButton"
        @click="showDeleteConfirmAdZoneGroup"
        class="button clean delete-button"
      >
        Zónaszett törlése
      </button>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

import AdZoneGroup from '@/entities/AdZoneGroup';

export default {
  name: 'AdZoneGroupForm',
  components: {
    PulseLoader,
  },
  props: {
    entity: {
      type: AdZoneGroup,
      required: true,
    },
  },
  created() {
    this.model = new AdZoneGroup(this.entity);
  },
  data() {
    return {
      model: null,
      isSaving: false,
    };
  },
  computed: {
    users() {
      return this.$store.getters['auth/companyMembers'];
    },
    customers() {
      return this.$store.getters['customer/list'];
    },
    adZones() {
      return this.$store.getters['adZone/list'];
    },
    showDeleteButton() {
      return this.model.id;
    },
    submitButtonLabel() {
      return this.model.isNew ? 'Zónaszett hozzáadása' : 'Zónaszett módosítása';
    },
  },
  methods: {
    showDeleteConfirmAdZoneGroup() {
      let content = 'Valóban törölni szeretnéd a zónaszettot?';
      this.$confirmation.open({
        content,
        labelConfirm: 'Törlés',
        confirm: this.deleteAdZoneGroup,
      });
    },
    submit() {
      if (this.isSaving) {
        return;
      }

      let errorMessage = null;

      this.$toast.clear();
      if (!this.model.name || this.model.name.trim().length === 0) {
        errorMessage = 'Kampány név mező kitöltése kötelező';
      }
      else if (this.model.ad_zones.length === 0) {
        errorMessage = 'A Zónák mező kitöltése kötelező!';
      }

      if (errorMessage !== null) {
        this.$toast.error(errorMessage);
        return;
      }

      this.isSaving = true;
      this.save(this.model)
        .then(() => {
          this.$router.replace('/ad-zone-groups');
          this.$toast.success('Zónaszett mentése sikeresen megtörtént');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        })
        .finally(() => (this.isSaving = false));
    },
    save(model) {
      return this.$store.dispatch('adZoneGroup/save', model)
        .then((resp) => {
          const responsedEntity = new AdZoneGroup(resp);
          return responsedEntity;
        });
    },
    deleteAdZoneGroup() {
      return this.$store
        .dispatch('adZoneGroup/delete', this.entity)
        .then(() => {
          this.$toast.success('Zónaszett törlése sikeresen megtörtént');
          this.$router.replace('/ad-zone-groups');
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
</script>
